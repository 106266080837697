import React from 'react';
import {
  ComboBox,
  DropdownInput,
  DatePicker,
  Input,
  MultiSelectBox,
  Toggle
} from 'core';
import Company from '../../../../models/Company';
import UserProfile from '../../../../models/UserProfile';
import { FilterOperatorEnum } from '../../../../utilities/Enumerables';

const CompanyStage = ({ bookingData, handleInputChange, options }) => {
  return (
    <div className='space-y-6'>
      <ComboBox
        loadOptions={async (searchQuery) => {
          try {
            const { data: companies } = await Company.getAll({
              fts: {
                operator: FilterOperatorEnum.TEXTSEARCH,
                value: searchQuery
              }
            });
            return companies.map(company => ({
              value: company.id,
              label: company.name
              //   secondaryLabel: company.code
            }));
          } catch (error) {
            console.error('Error loading companies:', error);
            return [];
          }
        }}
        label='Company'
        placeholder='Select a company'
        onChange={selectedCompany =>
          handleInputChange('company', selectedCompany)
        }
        value={bookingData.company}
        className='w-full'
        colour='primary'
        required
      />
      {bookingData.company && (
        <>
          <MultiSelectBox
            loadOptions={async searchQuery => {
              try {
                const { data: userProfiles } = await UserProfile.getAll({
                  company_id: {
                    operator: FilterOperatorEnum.EQUALS,
                    value: bookingData.company.value
                  },
                  fts: {
                    operator: FilterOperatorEnum.TEXTSEARCH,
                    value: searchQuery
                  }
                });
                return userProfiles.map(profile => ({
                  value: profile.id,
                  label: profile.fullName,
                  secondaryLabel: profile.email
                }));
              } catch (error) {
                console.error('Error loading user profiles:', error);
                return [];
              }
            }}
            label='Primary Bookers'
            placeholder='Select bookers'
            onChange={selectedBookers =>
              handleInputChange('primaryBookers', selectedBookers)
            }
            selectedOptions={bookingData.primaryBookers}
            className='w-full'
            size='medium'
            allowCreate={false}
          />
          <Toggle
            label={'Levy Required'}
            description={'Does this booking require a levy?'}
            checked={bookingData.levyRequired}
            onChange={checked => {
              handleInputChange('levyRequired', checked);
              if (checked && options.levy.length > 0) {
                handleInputChange('selectedLevy', options.levy[0]);
              } else {
                handleInputChange('selectedLevy', null);
              }
            }}
          />
          {bookingData.levyRequired && (
            <DropdownInput
              label='Levy'
              placeholder='Select levy'
              options={options.levy}
              onChange={e =>
                handleInputChange('selectedLevy', e.target.value)
              }
              value={bookingData.selectedLevy ?? options.levy[0]}
              className='w-full'
              colour='primary'
              required
            />
          )}
        </>
      )}
    </div>
  );
};

export default CompanyStage;
