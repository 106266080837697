import React from 'react';
import { ComboBox, DropdownInput, DatePicker, Input } from 'core';
import Course from '../../../../models/Course';
import { FilterOperatorEnum } from '../../../../utilities/Enumerables';

const CourseStage = ({ bookingData, handleInputChange, options }) => {
  return (
    <div className='space-y-6'>
      <ComboBox
        loadOptions={async courseQuery => {
          try {
            const { data: courses } = await Course.getAll({
              fts: {
                operator: FilterOperatorEnum.TEXTSEARCH,
                value: courseQuery
              }
            });
            return courses.map(course => ({
              value: course.id,
              label: course.name,
              secondaryLabel: course.accreditation?.type
            }));
          } catch (error) {
            console.error('Error loading courses:', error);
            return [];
          }
        }}
        label='Course'
        placeholder='Select a course'
        onChange={selectedCourse => handleInputChange('course', selectedCourse)}
        value={bookingData.course}
        className='w-full'
        colour='primary'
        required
      />
      <DropdownInput
        label='Delivery Type'
        placeholder='Select delivery type'
        options={options.deliveryType}
        onChange={e => handleInputChange('deliveryType', e.target.value)}
        value={bookingData.deliveryType ?? options.deliveryType[0]}
        className='w-full'
        colour='primary'
        required
      />
      <DatePicker
        label='Start Date'
        placeholder='Select start date'
        onChange={date => handleInputChange('startDate', date)}
        value={bookingData.startDate}
        className='w-full'
        colour='primary'
        required
      />
      <DatePicker
        label='End Date'
        placeholder='Select end date'
        onChange={date => handleInputChange('endDate', date)}
        value={bookingData.endDate}
        className='w-full'
        colour='primary'
        required
      />
    </div>
  );
};

export default CourseStage;
