import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const getColorClasses = (color, isActive, type) => {
  const colorMap = {
    primary: {
      underlined: {
        active: 'border-primary-500 text-primary-600',
        inactive:
          'border-transparent text-primary-500 hover:border-primary-300 hover:text-primary-700'
      },
      pill: {
        active: 'bg-primary-100 text-primary-700',
        inactive: 'text-primary-500 hover:text-primary-700'
      },
      select: 'focus:ring-primary-600'
    },
    brand: {
      underlined: {
        active: 'border-brand-500 text-brand-600',
        inactive:
          'border-transparent text-primary-600 hover:border-brand-300 hover:text-brand-700'
      },
      pill: {
        active: 'bg-brand-100 text-brand-700',
        inactive: 'text-brand-500 hover:text-brand-700'
      },
      select: 'focus:ring-brand-600'
    }
  };

  if (type === 'select') {
    return colorMap[color]?.select || colorMap.primary.select;
  }

  const colorClasses = colorMap[color]?.[type] || colorMap.primary[type];
  return isActive ? colorClasses.active : colorClasses.inactive;
};

const BaseTabs = ({
  tabs,
  className = '',
  color = 'primary',
  getTabClasses,
  containerClasses = '',
  fullWidth = false
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState('');

  useEffect(() => {
    const currentTab = tabs.find(tab => location.pathname.includes(tab.path));
    if (currentTab) {
      setSelectedTab(currentTab.label);
    }
  }, [location, tabs]);

  const handleSelectChange = event => {
    const selectedLabel = event.target.value;
    const selectedTab = tabs.find(tab => tab.label === selectedLabel);
    if (selectedTab) {
      navigate(selectedTab.path);
    }
  };

  return (
    <div className={`w-full ${className}`}>
      <div className='sm:hidden'>
        <label htmlFor='tabs' className='sr-only'>
          Select a tab
        </label>
        <select
          id='tabs'
          name='tabs'
          value={selectedTab}
          onChange={handleSelectChange}
          className={`block w-full min-w-0 border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${getColorClasses(
            color,
            false,
            'select'
          )} sm:text-sm sm:leading-6 rounded-md`}
        >
          {tabs.map(tab => (
            <option key={tab.label}>{tab.label}</option>
          ))}
        </select>
      </div>
      <div className='hidden sm:block'>
        <nav
          aria-label='Tabs'
          className={`${containerClasses} ${fullWidth ? 'flex' : ''}`}
        >
          {tabs.map(tab => (
            <Link
              key={tab.label}
              to={tab.path}
              className={`${getTabClasses(tab, color, location)} ${
                fullWidth ? 'flex-1 text-center' : ''
              }`}
            >
              {tab.label}
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
};

const UnderlinedTabs = props => {
  const getTabClasses = (tab, color, location) => {
    const isActive = location.pathname.includes(tab.path);
    return `text-sm font-medium whitespace-nowrap py-4 px-1 border-b-2 ${getColorClasses(
      color,
      isActive,
      'underlined'
    )}`;
  };

  return (
    <BaseTabs
      {...props}
      getTabClasses={getTabClasses}
      containerClasses='-mb-px flex space-x-2 sm:space-x-4 lg:space-x-8 border-b'
    />
  );
};

const PillTabs = props => {
  const getTabClasses = (tab, color, location) => {
    const isActive = location.pathname.split('/').includes(tab.path);
    return `text-sm font-medium rounded-md px-3 py-2 ${getColorClasses(
      color,
      isActive,
      'pill'
    )}`;
  };

  return (
    <BaseTabs
      {...props}
      getTabClasses={getTabClasses}
      containerClasses='flex space-x-4'
    />
  );
};

export { UnderlinedTabs, PillTabs };
