import React, { useState, useEffect, useRef } from 'react';
import { Clock, ChevronUp, ChevronDown } from 'lucide-react';
import { Input } from '../forms/Input';

const TimePicker = ({
  label,
  value,
  onChange,
  placeholder = 'HH:MM AM/PM',
  className,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const formatTime = (input) => {
    let digits = input.replace(/\D/g, '');
    digits = digits.padStart(4, '0');
    let hours = parseInt(digits.substring(0, 2));
    const minutes = digits.substring(2, 4);
    let period = 'AM';
    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) hours -= 12;
    }
    if (hours === 0) hours = 12;
    return `${hours.toString().padStart(2, '0')}:${minutes} ${period}`;
  };

  const handleInputChange = (e) => {
    const rawInput = e.target.value;
    const formattedTime = formatTime(rawInput);
    setInputValue(formattedTime);
    onChange(formattedTime);
  };

  const handleTimeSelect = (hours, minutes, period) => {
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
    setInputValue(formattedTime);
    onChange(formattedTime);
    setIsOpen(false);
  };

  const renderTimeOptions = () => {
    const options = [];
    for (let h = 1; h <= 12; h++) {
      for (let m = 0; m < 60; m += 15) {
        options.push(
          <button
            key={`${h}:${m}`}
            className="block w-full text-left px-4 py-2 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
            onClick={() => handleTimeSelect(h, m, h < 12 ? 'AM' : 'PM')}
          >
            {`${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')} ${h < 12 ? 'AM' : 'PM'}`}
          </button>
        );
      }
    }
    return options;
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <Input
        label={label}
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
        className={className}
        rightIcon={Clock}
        onClick={() => setIsOpen(!isOpen)}
        {...props}
      />
      {isOpen && (
        <div className="absolute z-10 mt-1 w-full bg-white rounded-md shadow-lg max-h-60 overflow-auto">
          <div className="py-1">
            {renderTimeOptions()}
          </div>
        </div>
      )}
    </div>
  );
};

export { TimePicker };
