import BookingSpace from '../../../models/BookingSpace';
import EntityTable from '../../../components/tables/EntityTable';
import { supabase } from '../../../utilities/supabase';
import { formatDate } from '../../../utilities/Formatting';
import { Link } from 'react-router-dom';
import { EntityTypeEnum } from '../../../utilities/Enumerables';

const BookingSpaces = () => {
  document.title = 'Booking Spaces | CRM | BOHSA LTD';
  return (
    <div className='p-4'>
      <EntityTable
        name='Booking Spaces'
        filter={[
          { field: 'id', label: 'ID', type: 'text' },
          { field: 'booking_id', label: 'Booking ID', type: 'text' },
          { field: 'delegate_id', label: 'Delegate ID', type: 'text' },
          { field: 'status', label: 'Status', type: 'text' },
          { field: 'levy_status', label: 'Levy Status', type: 'text' },
          { field: 'created_date', label: 'Created From', type: 'date' },
          { field: 'created_date', label: 'Created To', type: 'date' },
          {
            field: 'completion_date',
            label: 'Completion Date From',
            type: 'date'
          },
          {
            field: 'completion_date',
            label: 'Completion Date To',
            type: 'date'
          },
          { field: 'expiry_date', label: 'Expiry Date From', type: 'date' },
          { field: 'expiry_date', label: 'Expiry Date To', type: 'date' }
        ]}
        sort={[
          { value: 'created_date', label: 'Date Created' },
          { value: 'completion_date', label: 'Completion Date' },
          { value: 'expiry_date', label: 'Expiry Date' },
          { value: 'status', label: 'Status' },
          { value: 'levy_status', label: 'Levy Status' }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/booking-spaces/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          { label: 'Booking ID', field: 'bookingId' },
          { label: 'Delegate ID', field: 'delegateId' },
          { label: 'Status', field: 'status.name' },
          { label: 'Levy Status', field: 'levyStatus.name' },
          {
            label: 'Completion Date',
            field: 'completionDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Expiry Date',
            field: 'expiryDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          }
        ]}
        model={BookingSpace}
        entityType={EntityTypeEnum.BookingSpace}
      />
    </div>
  );
};

export default BookingSpaces;
