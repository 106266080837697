import BaseModel from './BaseModel';

/**
 * Represents an InvoiceLine in the system.
 * @extends BaseModel
 */
class InvoiceLine extends BaseModel {
  /** @type {string} The name of the database table for InvoiceLines */
  static table = 'invoice_lines';

  /** @type {string} The SQL query to select InvoiceLine data with related information */
  static selectQuery = `
    *,
    invoices!inner (id)
  `;

  /**
   * Creates an instance of InvoiceLine.
   * @param {Object} data - The invoice line data.
   */
  constructor(data = {}) {
    super(data);
    this.invoiceId = data.invoice_id || null;
    this.description = data.description || null;
    this.createdDate = data.created_date || null;
    this.updatedDate = data.updated_date || null;
    this.bookingId = data.booking_id || null;
    this.additionalId = data.additional_id || null;
    this.tax = {
      rate: data.tax_rate || null,
      amount: data.tax_amount || null
    };
    this.quantity = data.quantity || null;
    this.price = data.price || null;
    this.amount = data.amount || null;
  }

  /**
   * Converts the InvoiceLine instance to a database-friendly format.
   * @returns {Object} The invoice line data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      invoice_id: this.invoiceId,
      description: this.description,
      created_date: this.createdDate,
      updated_date: this.updatedDate,
      booking_id: this.bookingId,
      additional_id: this.additionalId,
      tax_rate: this.tax.rate,
      quantity: this.quantity,
      price: this.price,
    };
  }
}

export default InvoiceLine;
