import React, { useState, useRef, useEffect } from 'react';
import {
  Calendar as CalendarIcon,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';
import { Input } from '../forms/Input';
import { TextButton } from '../buttons/Button';

const DatePicker = ({
  label,
  value,
  onChange,
  placeholder = 'Select date',
  size = 'md',
  className,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    value ? new Date(value) : new Date()
  );
  const [currentDate, setCurrentDate] = useState(new Date());
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDateChange = date => {
    setSelectedDate(date);
    onChange(date);
  };

  const formatDate = date => {
    if (!date) return '';
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const daysInMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  ).getDate();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  ).getDay();

  const prevMonthDays = Array.from(
    { length: firstDayOfMonth },
    (_, i) =>
      new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate() -
      i
  ).reverse();

  const currentMonthDays = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  const nextMonthDays = Array.from(
    { length: 42 - (prevMonthDays.length + currentMonthDays.length) },
    (_, i) => i + 1
  );

  const prevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const nextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };

  const goToToday = () => {
    setCurrentDate(new Date());
  };

  return (
    <div className='relative' ref={dropdownRef}>
      <Input
        label={label}
        value={formatDate(value)}
        onClick={() => setIsOpen(!isOpen)}
        readOnly
        placeholder={placeholder}
        className={className}
        rightIcon={CalendarIcon}
        {...props}
      />
      {isOpen && (
        <div className='absolute z-10 mt-1 min-w-72 w-full bg-white rounded-md shadow-lg'>
          <div className='p-4'>
            <div className='flex flex-col items-center justify-center text-base-900 w-full max-w-sm mx-auto'>
              <div className='flex justify-between rounded-md md:items-stretch w-full'>
                <TextButton onClick={prevMonth} colour='base'>
                  <ChevronLeft className='w-5 h-5' />
                </TextButton>
                <TextButton onClick={goToToday} colour='base'>
                  {currentDate.toLocaleString('default', { month: 'long' })}
                </TextButton>
                <TextButton onClick={nextMonth} colour='base'>
                  <ChevronRight className='w-5 h-5' />
                </TextButton>
              </div>
              <div className='mt-6 grid grid-cols-7 text-xs leading-6 text-base-500 w-full'>
                {weekdays.map(day => (
                  <div key={day} className='text-center font-semibold'>
                    {day.charAt(0).toUpperCase()}
                  </div>
                ))}
              </div>
              <div className='isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-base-200 text-sm shadow ring-1 ring-base-200 w-full overflow-hidden'>
                {[
                  ...prevMonthDays,
                  ...currentMonthDays,
                  ...nextMonthDays
                ].map((day, index) => {
                  const date = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth() +
                      (index < firstDayOfMonth
                        ? -1
                        : index >= firstDayOfMonth + daysInMonth
                        ? 1
                        : 0),
                    day
                  );
                  const isCurrentMonth =
                    index >= firstDayOfMonth &&
                    index < firstDayOfMonth + daysInMonth;
                  const isToday =
                    date.toDateString() === new Date().toDateString();
                  const isSelected =
                    date.toDateString() === selectedDate.toDateString();

                  return (
                    <button
                      key={index}
                      type='button'
                      onClick={() => handleDateChange(date)}
                      className={`py-1.5 hover:bg-base-100 focus:z-10 ${
                        isCurrentMonth
                          ? 'bg-white'
                          : 'bg-base-50 text-base-400'
                      } ${isToday ? 'font-semibold text-primary-600' : ''} ${
                        isSelected ? 'bg-info-100' : ''
                      }`}
                    >
                      <time
                        dateTime={`${date.getFullYear()}-${String(
                          date.getMonth() + 1
                        ).padStart(2, '0')}-${String(day).padStart(2, '0')}`}
                        className={`mx-auto flex h-7 w-7 items-center justify-center rounded-full ${
                          isToday
                            ? 'bg-primary-600 font-semibold text-white'
                            : ''
                        } ${
                          isSelected && !isToday
                            ? 'bg-info-300 font-semibold text-info-700'
                            : ''
                        }`}
                      >
                        {day}
                      </time>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { DatePicker };
