import React, { useState, useEffect } from 'react';
import { Modal, TextButton, FilledButton, Input, ColourPicker, Checkbox } from 'core';

const EditCategoryModal = ({ isOpen, onClose, onEdit, category }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [color, setColor] = useState('zinc');
  const [showOnPipelines, setShowOnPipelines] = useState(false);

  useEffect(() => {
    if (category) {
      setName(category.type);
      setColor(category.metadata?.colour || 'zinc');
      setDescription(category.description || '');
      setShowOnPipelines(category.metadata?.pipelines || false);
    }
  }, [category]);

  const handleSubmit = () => {
    onEdit(name, color, description, showOnPipelines);
    setName('');
    setDescription('');
    setColor('zinc');
    setShowOnPipelines(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Edit Category'>
      <div className='space-y-4'>
        <Input
          label='Category Name'
          placeholder='Enter category name'
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <Input
          label='Description'
          placeholder='Enter category description'
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
        <div>
          <label className='block text-sm font-medium text-gray-700 mb-1'>
            Category Color
          </label>
          <ColourPicker
            showShades={false}
            colour={color}
            onChange={colour => setColor(colour)}
          />
        </div>
        <div>
          <Checkbox
            label='Show on pipelines'
            checked={showOnPipelines}
            onChange={e => setShowOnPipelines(e.target.checked)}
          />
        </div>
        <div className='flex justify-end space-x-2'>
          <TextButton onClick={onClose}>Cancel</TextButton>
          <FilledButton onClick={handleSubmit} disabled={!name}>
            Save Changes
          </FilledButton>
        </div>
      </div>
    </Modal>
  );
};

export default EditCategoryModal;
