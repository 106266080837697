import React from 'react';
import { Popup, TextButton, SoftButton, FilledButton } from 'core';
import { CheckCircle, XCircle, CircleHelp } from 'lucide-react';

const VerifyBooking = ({
  showInvoicePopup,
  setShowInvoicePopup,
  bookingData,
  onCreateInvoice
}) => {
  const renderStatus = (value, prefix, required, reversed) => {
    let statusIcon;

    if (reversed) {
      if (value && required) {
        statusIcon = <XCircle size={16} className='ml-2 text-danger-600' />;
      } else if (value && !required) {
        statusIcon = <CircleHelp size={16} className='ml-2 text-warning-600' />;
      } else {
        statusIcon = <CheckCircle size={16} className='ml-2 text-green-600' />;
      }
    } else {
      if (value) {
        statusIcon = <CheckCircle size={16} className='ml-2 text-green-600' />;
      } else if (required) {
        statusIcon = <XCircle size={16} className='ml-2 text-danger-600' />;
      } else {
        statusIcon = <CircleHelp size={16} className='ml-2 text-warning-600' />;
      }
    }

    return (
      <>
        {prefix ? `${prefix}${value || '-'}` : value || '-'}
        {statusIcon}
      </>
    );
  };

  return (
    <Popup
      isOpen={showInvoicePopup}
      onClose={() => setShowInvoicePopup(false)}
      title='Create Invoice'
      size='md'
    >
      <div className='space-y-6'>
        <h3 className='text-base font-medium text-base-800'>
          Verify Booking Details
        </h3>
        {bookingData && (
          <>
            <div className='rounded-lg'>
              <ul className='space-y-2'>
                {[
                  {
                    label: 'Course Name',
                    value: bookingData.course?.name,
                    required: true
                  },
                  {
                    label: 'Start Date',
                    value: new Date(bookingData.startDate).toLocaleDateString(
                      'en-GB'
                    ),
                    required: true
                  },
                  {
                    label: 'End Date',
                    value: new Date(bookingData.endDate).toLocaleDateString(
                      'en-GB'
                    ),
                    required: true
                  },
                  {
                    label: 'Invoice Amount',
                    value: bookingData.invoiceAmount,
                    prefix: '£',
                    required: true
                  },
                  { label: 'CPO Number', value: bookingData.cpoNumber },
                  { label: 'TPO Number', value: bookingData.tpoNumber },
                  { label: 'Levy', value: bookingData.levy },
                  {
                    label: 'Trainer',
                    value: bookingData.trainer.name,
                    required: true
                  },
                  {
                    label: 'Delivery Method',
                    value: bookingData.deliveryType.name,
                    required: true
                  },
                  {
                    label: 'No Incomplete Items',
                    value: bookingData.metrics.items.count,
                    required: false,
                    reversed: true
                  },
                  {
                    label: 'No Empty Spaces',
                    value:
                      bookingData.spaces - bookingData.metrics.fulfilledSpaces,
                    required: false,
                    reversed: true
                  }
                ].map(
                  ({
                    label,
                    value,
                    prefix,
                    required = false,
                    reversed = false
                  }) => (
                    <li
                      key={label}
                      className='flex justify-between items-center'
                    >
                      <span className='text-base-950 font-medium text-sm leading-6'>
                        {label}
                      </span>
                      <span className='font-regular text-sm text-base-800 flex items-center'>
                        {renderStatus(value, prefix, required, reversed)}
                      </span>
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className='flex justify-end space-x-4 mt-6'>
              <SoftButton
                onClick={() => setShowInvoicePopup(false)}
                colour='base'
              >
                Cancel
              </SoftButton>
              <FilledButton
                onClick={() => {
                  onCreateInvoice(bookingData);
                  setShowInvoicePopup(false);
                }}
                colour='primary'
                disabled={
                  !bookingData.course?.name ||
                  !bookingData.startDate ||
                  !bookingData.endDate ||
                  !bookingData.invoiceAmount ||
                  !bookingData.trainer ||
                  !bookingData.deliveryType
                }
              >
                Create Invoice
              </FilledButton>
            </div>
          </>
        )}
      </div>
    </Popup>
  );
};

export default VerifyBooking;
