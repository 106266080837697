import React, { useState, useRef, useEffect } from 'react';
import { ChevronRight, User, LogOut } from 'lucide-react';
import { Modal } from 'core';
import { getRelativeTimeString } from '../../utilities/Formatting';

const ProfileSwitcher = ({
  profiles,
  currentProfile,
  onProfileChange,
  onLogout,
  isOpen,
  onClose
}) => {
  const [orderedProfiles, setOrderedProfiles] = useState([]);

  useEffect(() => {
    if (profiles && profiles.length > 0) {
      const sortedProfiles = [...profiles].sort((a, b) => {
        if (a.active && !b.active) return -1;
        if (!a.active && b.active) return 1;

        const aLastSeen = a.lastSeen ? new Date(a.lastSeen) : new Date(0);
        const bLastSeen = b.lastSeen ? new Date(b.lastSeen) : new Date(0);

        return bLastSeen - aLastSeen;
      });

      setOrderedProfiles(sortedProfiles);
    }
  }, [profiles]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title='Switch Profile'
      actions={[
        {
          label: 'Cancel',
          onClick: onClose,
          style: 'base'
        }
      ]}
    >
      <ul role='list' className='bg-white dark:bg-base-800'>
        {orderedProfiles && orderedProfiles.length > 0 ? (
          orderedProfiles.map(profile => (
            <li
              key={profile.id}
              className='relative flex justify-between gap-x-4 px-4 py-2 hover:bg-base-50 rounded-md sm:px-6 cursor-pointer'
              onClick={() => onProfileChange(profile)}
            >
              <div className='flex min-w-0 gap-x-4'>
                {profile.photo ? (
                  <img
                    alt=''
                    src={profile.photo}
                    className='h-10 w-10 flex-none rounded-full bg-base-50'
                  />
                ) : (
                  <User className='h-10 w-10 flex-none rounded-full bg-base-50 p-2' />
                )}
                <div className='min-w-0 flex-auto'>
                  <p className='text-sm font-semibold leading-6 text-base-900 text-left'>
                    {profile.firstName} {profile.lastName}
                  </p>
                  <p className='mt-1 flex text-xs leading-5 text-base-500 text-left'>
                    {profile.email}
                  </p>
                </div>
              </div>
              <div className='flex shrink-0 items-center gap-x-4'>
                <div className='hidden sm:flex sm:flex-col sm:items-end'>
                  {/* <p className='text-sm leading-6 text-base-900'>
                    {profile.role || 'Role Not Set'}
                  </p> */}
                  {profile.active && (
                    <div className='mt-1 flex items-center gap-x-1.5'>
                      <div className='flex-none rounded-full bg-emerald-500/20 p-1'>
                        <div className='h-1.5 w-1.5 rounded-full bg-emerald-500' />
                      </div>
                      <p className='text-xs leading-5 text-gray-500'>Current</p>
                    </div>
                  )}
                  {!profile.active && profile.metrics.last_seen && (
                    <p className='mt-1 text-xs leading-5 text-base-500'>
                      Last seen{' '}
                      <time dateTime={profile.metrics.last_seen}>
                        {getRelativeTimeString(profile.metrics.last_seen)}
                      </time>
                    </p>
                  )}
                </div>
                <ChevronRight
                  aria-hidden='true'
                  className='h-5 w-5 flex-none text-base-400'
                />
              </div>
            </li>
          ))
        ) : (
          <div className='px-4 py-5 text-center text-base-500'>
            No profiles available
          </div>
        )}
      </ul>
    </Modal>
  );
};

export default ProfileSwitcher;
