import React, { useState, useEffect, useContext } from 'react';
import { X, Search, Plus, Eye, EyeOff, Send, Trash2 } from 'lucide-react';
import { FilledButton, TextButton, Badge, TextEditor } from 'core';
import { PrimaryInput } from '../../inputs/Forms';
import CreateTemplate from './CreateTemplate';
import ActivityTemplate from '../../../models/ActivityTemplate';
import ActivityLog from '../../../models/ActivityLog';
import { supabase } from '../../../utilities/supabase';
import Popup from '../Popup';
import {
  Clock,
  AlertTriangle,
  Star,
  PinIcon,
  FileText,
  Phone,
  MessageCircle,
  Mail,
  ThumbsUp,
  Ampersand
} from 'lucide-react';
import { UserProfileContext } from '../../../App';

const CreateNote = ({ entityType, entityId, onClose, onSubmit }) => {
  const [activityType, setActivityType] = useState(119); // Default to 'Note'
  const [content, setContent] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreateTemplateOpen, setIsCreateTemplateOpen] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [isPrivate, setIsPrivate] = useState(true);
  const [hoveredTemplateId, setHoveredTemplateId] = useState(null);

  const userProfile = useContext(UserProfileContext);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const { data } = await ActivityTemplate.getAll({ type: activityType });
        setTemplates(data);
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };

    fetchTemplates();

    const templatesChannel = supabase
      .channel('custom-all-channel')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'activity_templates' },
        payload => {
          console.log('Change received!', payload);
          fetchTemplates();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(templatesChannel);
    };
  }, [activityType]);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const newActivity = new ActivityLog({
        audit_type: { id: activityType },
        content: content,
        entity_type: { id: entityType },
        entity_id: entityId,
        internal: isPrivate,
        owner: { id: userProfile.userProfile.id }
      });
      const insertedActivity = await newActivity.insert();
      onSubmit(insertedActivity);
      onClose();
    } catch (error) {
      console.error('Error creating activity:', error);
    }
  };

  const handleCommentChange = newContent => {
    setContent(newContent);
  };

  const activityTypes = [
    { type: 119, label: 'Note' },
    { type: 120, label: 'Email' },
    { type: 121, label: 'Text' },
    { type: 122, label: 'Call' }
  ];

  const handleCreateTemplateSubmit = async templateData => {
    try {
      const newTemplate = new ActivityTemplate(templateData);
      await newTemplate.insert();
      setIsCreateTemplateOpen(false);
    } catch (error) {
      console.error('Error creating template:', error);
    }
  };

  const filteredTemplates = templates.filter(template =>
    template.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleTemplateClick = templateContent => {
    setContent(templateContent);
  };

  const togglePrivate = () => {
    setIsPrivate(!isPrivate);
  };

  const handleDeleteTemplate = async (e, templateId) => {
    e.stopPropagation();
    try {
      const template = new ActivityTemplate({ id: templateId });
      await template.delete();
      setTemplates(templates.filter(t => t.id !== templateId));
    } catch (error) {
      console.error('Error deleting template:', error);
    }
  };

  const icons = {
    clock: Clock,
    'alert-triangle': AlertTriangle,
    star: Star,
    pin: PinIcon,
    'file-text': FileText,
    phone: Phone,
    'message-circle': MessageCircle,
    mail: Mail,
    'thumbs-up': ThumbsUp,
    ampersand: Ampersand
  };

  return (
    <Popup onClose={onClose} title='Create Activity'>
      <div className='flex'>
        <div className='w-2/3 pr-4'>
          <div className='flex space-x-2 mb-4'>
            {activityTypes.map(({ type, label }) => (
              <Badge
                key={type}
                onClick={() => setActivityType(type)}
                colour={activityType === type ? 'primary' : 'info'}
                size='md'
              >
                {label}
              </Badge>
            ))}
          </div>
          <TextEditor
            value={content}
            onChange={newContent => handleCommentChange(newContent)}
            className='mb-4'
          />
          <div className='flex justify-end gap-x-4'>
            <TextButton
              onClick={togglePrivate}
              colour={isPrivate ? 'base' : 'danger'}
              size='sm'
              leftIcon={isPrivate ? <EyeOff size={18} /> : <Eye size={18} />}
              className={`p-1 rounded transition-colors ${
                isPrivate
                  ? 'hover:bg-neutral-200 text-neutral-700'
                  : 'bg-red-100 text-red-700 hover:bg-red-200'
              }`}
            >
              {isPrivate ? 'Private' : 'Public'}
            </TextButton>
            <FilledButton onClick={handleSubmit} colour='brand' size='sm'>
              <Send size={18} />
            </FilledButton>
          </div>
        </div>
        <div className='w-1/3 pl-4 border-l border-neutral-200'>
          <div className='flex justify-between items-center mb-4'>
            <h3 className='text-lg font-semibold'>Templates</h3>
            <TextButton
              onClick={() => setIsCreateTemplateOpen(true)}
              colour='brand'
              size='sm'
              leftIcon={<Plus size={20} />}
            />
          </div>
          <PrimaryInput
            type='text'
            placeholder='Search templates...'
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            icon={<Search size={18} />}
            className='mb-4'
          />
          <div className='overflow-y-auto max-h-64'>
            {filteredTemplates.map(template => {
              const IconComponent = icons[template.icon] || FileText;
              return (
                <div
                  key={template.id}
                  className='mb-2 p-2 border rounded cursor-pointer hover:bg-neutral-100 flex items-center justify-between relative'
                  onClick={() => handleTemplateClick(template.content)}
                  onMouseEnter={() => setHoveredTemplateId(template.id)}
                  onMouseLeave={() => setHoveredTemplateId(null)}
                >
                  <div className='flex items-center'>
                    <div
                      className='w-8 h-8 rounded-full flex items-center justify-center mr-2'
                      style={{ backgroundColor: template.colour || '#000000' }}
                    >
                      <IconComponent size={16} color='white' />
                    </div>
                    <div>
                      <h4 className='font-semibold'>{template.title}</h4>
                      <p className='text-sm text-neutral-600'>
                        {template.type}
                      </p>
                    </div>
                  </div>
                  {hoveredTemplateId === template.id && (
                    <TextButton
                      onClick={e => handleDeleteTemplate(e, template.id)}
                      colour='danger'
                      size='sm'
                      leftIcon={<Trash2 size={18} />}
                      className='absolute right-2 top-1/2 transform -translate-y-1/2'
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {isCreateTemplateOpen && (
        <CreateTemplate
          onClose={() => setIsCreateTemplateOpen(false)}
          onSubmit={handleCreateTemplateSubmit}
        />
      )}
    </Popup>
  );
};

export default CreateNote;
