import React, { useState, useEffect } from 'react';
import { Pencil, SquareArrowOutUpRight, Trash, UserPlus } from 'lucide-react';
import BookingSpace from '../../../../models/BookingSpace';
import EntityTable from '../../../../components/tables/EntityTable';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../../utilities/Enumerables';
import { formatDate } from '../../../../utilities/Formatting';
import CoreEntityType from '../../../../models/CoreEntityType';
import AssignUser from '../../../../components/popups/booking-space/AssignUser';
import EditSpace from '../../../../components/popups/booking-space/EditSpace';
import { Alert } from 'core';

const Tracker = ({ companyId }) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [missingSpaces, setMissingSpaces] = useState(0);
  const [selectedBookingSpace, setSelectedBookingSpace] = useState(null);
  const [showAssignUser, setShowAssignUser] = useState(false);
  const [showSpaceTracker, setShowSpaceTracker] = useState(false);

  useEffect(() => {
    const fetchStatusOptions = async () => {
      try {
        const { data: statuses } = await CoreEntityType.getAll({
          entity_id: {
            operator: 'eq',
            value: EntityTypeEnum.BookingSpaceStatus
          }
        });
        setStatusOptions(
          statuses.map(status => ({ key: status.id, value: status.type }))
        );
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    const countMissingDelegates = async () => {
      const { count: spaces } = await BookingSpace.getAll(
        {
          'booking.company_id': { value: companyId },
          delegate_id: { operator: 'is', value: 'null' }
        },
        1,
        10,
        '',
        'asc',
        false
      );

      setMissingSpaces(spaces);
    };

    fetchStatusOptions();
    countMissingDelegates();
  }, []);

  const menuItems = [
    {
      label: 'Edit delegate',
      icon: <UserPlus size={14} />,
      onClick: data => {
        setSelectedBookingSpace(data.id);
        setShowAssignUser(true);
      }
    },
    {
      label: 'Edit space',
      icon: <Pencil size={14} />,
      onClick: data => {
        setSelectedBookingSpace(data.id);
        setShowSpaceTracker(true);
      }
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const columns = [
    { label: 'ID', field: 'id' },
    { label: 'Booking', field: 'booking.id' },
    { label: 'Delegate', field: 'delegate.fullName' },
    {
      label: 'Completion Date',
      field: 'completionDate',
      cell: value => formatDate(value)
    },
    {
      label: 'Expiry Date',
      field: 'expiryDate',
      cell: value => formatDate(value)
    },
    { label: 'Status', field: 'status.name' }
  ];

  const filters = [
    { field: 'id', label: 'ID', type: 'number' },
    {
      field: 'booking.start_date',
      label: 'Start Date',
      type: 'date',
      operator: FilterOperatorEnum.GREATER_THAN_OR_EQUAL
    },
    {
      field: 'booking.end_date',
      label: 'End Date',
      type: 'date',
      operator: FilterOperatorEnum.LESS_THAN_OR_EQUAL
    },
    {
      field: 'status',
      label: 'Status',
      type: 'select',
      options: statusOptions,
      operator: FilterOperatorEnum.EQUALS
    }
  ];

  return (
    <div className='space-y-2'>
      {missingSpaces > 0 && (
        <Alert
          title='Empty Booking Spaces'
          description='Unassigned booking spaces detected for this company. While this may be normal for pending onsite training sessions, 
          please review and assign delegates to all applicable booking spaces to maintain accurate training records.'
          style='warning'
        />
      )}
      <EntityTable
        name='All Spaces'
        filter={filters}
        columns={columns}
        menuItems={menuItems}
        model={BookingSpace}
        entityType={EntityTypeEnum.BookingSpace}
        defaultFilters={{
          'booking.company_id': { value: companyId }
        }}
      />

      {showAssignUser && (
        <AssignUser
          bookingSpaceId={selectedBookingSpace}
          onClose={() => {
            setSelectedBookingSpace(null);
            setShowAssignUser(false);
          }}
          onAssign={() => {
            setSelectedBookingSpace(null);
            setShowAssignUser(false);
          }}
        />
      )}

      {showSpaceTracker && (
        <EditSpace
          isOpen={showSpaceTracker}
          bookingSpaceId={selectedBookingSpace}
          onClose={() => {
            setSelectedBookingSpace(null);
            setShowSpaceTracker(false);
          }}
        />
      )}
    </div>
  );
};

export default Tracker;
