import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';

const DropdownMenu = ({
  options,
  value,
  onChange,
  placeholder = 'Select an option',
  size = 'md',
  className = '',
  direction = 'down'
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const selectedOption = options.find(option => option.value === value);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = option => {
    onChange(option.value);
    setIsOpen(false);
  };

  const sizeClasses = {
    xs: 'px-2 py-1 text-xs',
    sm: 'px-2 py-1 text-sm',
    md: 'px-2.5 py-1.5 text-sm',
    lg: 'px-3 py-2 text-sm',
    xl: 'px-3.5py-2.5 text-sm'
  };

  const iconSizes = {
    xs: 14,
    sm: 16,
    md: 18,
    lg: 20,
    xl: 22
  };

  const dropdownPosition =
    direction === 'up' ? 'bottom-full mb-2' : 'top-full mt-2';

  return (
    <div
      className={`relative inline-block text-left ${className}`}
      ref={dropdownRef}
    >
      <div>
        <button
          ref={buttonRef}
          type='button'
          className={`inline-flex justify-between w-full rounded-md border border-base-300 shadow-sm ${sizeClasses[size]} bg-white font-medium text-base-700 hover:bg-base-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-base-100 focus:ring-primary-500`}
          onClick={toggleDropdown}
        >
          {selectedOption ? selectedOption.label : placeholder}
          <ChevronDown
            className='-mr-1 ml-2'
            width={iconSizes[size]}
            height={iconSizes[size]}
            aria-hidden='true'
          />
        </button>
      </div>

      {isOpen && (
        <div
          className={`origin-top-right absolute right-0 ${dropdownPosition} z-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
          style={{
            width: buttonRef.current
              ? `max(${buttonRef.current.offsetWidth}px, 200px)`
              : '200px'
          }}
        >
          <div
            className='py-1'
            role='menu'
            aria-orientation='vertical'
            aria-labelledby='options-menu'
          >
            {options.map(option => (
              <button
                key={option.value}
                className={`${
                  option.value === value
                    ? 'bg-base-100 text-base-900'
                    : 'text-base-700'
                } group flex items-center w-full ${
                  sizeClasses[size]
                } hover:bg-base-100 hover:text-base-900`}
                role='menuitem'
                onClick={() => handleOptionClick(option)}
              >
                {option.icon && (
                  <option.icon
                    className={`mr-3 text-base-400 group-hover:text-base-500`}
                    width={iconSizes[size]}
                    height={iconSizes[size]}
                    aria-hidden='true'
                  />
                )}
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export { DropdownMenu };
