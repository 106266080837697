import React from 'react';
import { DatePicker, Toggle, Input, SoftButton, DropdownInput } from 'core';
import { calculateDate } from '../../../../utilities/DateHelper';

const DetailsStage = ({
  bookingData,
  handleInputChange,
  handleAdditionalItemChange,
  removeAdditionalItem,
  addAdditionalItem,
  options,
  companyData
}) => {
  return (
    <div className='space-y-6'>
      <Input
        label='Number of Spaces'
        placeholder='Enter number of spaces'
        type='number'
        onChange={e => handleInputChange('spaces', e.target.value)}
        value={bookingData.spaces}
        className='w-full'
        colour='primary'
        required
      />
      {bookingData.deliveryType == 62 ? (
        <Input
          label='Space Rate'
          placeholder='Enter space rate'
          type='number'
          onChange={e => handleInputChange('spaceRate', e.target.value)}
          value={bookingData.spaceRate ?? 0}
          className='w-full'
          colour='primary'
          required
        />
      ) : (
        <Input
          label='Invoice Amount'
          placeholder='Enter invoice amount'
          type='number'
          onChange={e => handleInputChange('invoiceAmount', e.target.value)}
          value={bookingData.invoiceAmount ?? 0}
          className='w-full'
          colour='primary'
          required
        />
      )}

      <DatePicker
        label='Payment Due Date'
        placeholder='Select payment due date'
        onChange={selectedDate =>
          handleInputChange('paymentDueDate', selectedDate)
        }
        value={
          companyData.terms
            ? calculateDate(companyData.terms.days, companyData.terms.type?.id, {
                invoiceDate: new Date(),
                courseStartDate: bookingData.startDate,
                courseEndDate: bookingData.endDate
              })
            : bookingData.paymentDueDate
        }
        className='w-full'
        colour='primary'
        hint={
          companyData.terms
            ? `Default date calculated based on company terms: ${companyData.terms.days} days after ${companyData.terms.type?.type}`
            : 'Select a payment due date'
        }
        required
      />
      <Toggle
        label='TradeX Company'
        checked={companyData.tradex}
        className='w-full'
        description={`TradeX task for ${companyData.name} will be due in ${
          companyData.tradexOption ?? 0
        } hours.`}
        disabled
      />
      <div>
        <h3 className='text-sm text-primary-900 font-semibold mb-2'>
          Additional Items
        </h3>
        <div className='overflow-x-auto bg-white rounded-md overflow-hidden'>
          <table className='min-w-full divide-y divide-neutral-200'>
            <thead>
              <tr>
                <th className='py-3 px-3 text-left text-sm font-semibold text-neutral-700'>
                  Description
                </th>
                <th className='py-3 px-3 text-left text-sm font-semibold text-neutral-700'>
                  Type
                </th>
                <th className='py-3 px-3 text-left text-sm font-semibold text-neutral-700'>
                  Amount
                </th>
                <th className='py-3 px-3 text-left text-sm font-semibold text-neutral-700'>
                  Vat Rate
                </th>
                <th className='py-3 px-3 text-left text-sm font-semibold text-neutral-700'>
                  Status
                </th>
                <th className='py-3 px-3 text-left text-sm font-semibold text-neutral-700'>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {bookingData.additionalItems.map((item, index) => (
                <tr key={index}>
                  <td className='border p-2'>
                    <Input
                      value={item.description}
                      onChange={e =>
                        handleAdditionalItemChange(
                          index,
                          'description',
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td className='border p-2'>
                    <DropdownInput
                      options={options.itemTypes}
                      value={item.type}
                      onChange={e =>
                        handleAdditionalItemChange(
                          index,
                          'type',
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td className='border p-2'>
                    <Input
                      type='number'
                      value={item.amount}
                      onChange={e =>
                        handleAdditionalItemChange(
                          index,
                          'amount',
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td className='border p-2'>
                    <Input
                      type='number'
                      value={item.vatRate}
                      onChange={e =>
                        handleAdditionalItemChange(
                          index,
                          'vatRate',
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td className='border p-2'>
                    <DropdownInput
                      options={options.itemStatuses}
                      value={item.status}
                      onChange={e =>
                        handleAdditionalItemChange(
                          index,
                          'status',
                          e.target.value
                        )
                      }
                    />
                  </td>
                  <td className='border p-2'>
                    <SoftButton
                      onClick={() => removeAdditionalItem(index)}
                      colour='danger'
                    >
                      Remove
                    </SoftButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <SoftButton
          onClick={addAdditionalItem}
          colour='primary'
          className='mt-2'
        >
          Add Item
        </SoftButton>
      </div>
    </div>
  );
};

export default DetailsStage;
