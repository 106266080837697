import BaseModel from './BaseModel';

/**
 * Represents an Additional Item in the system.
 * @extends BaseModel
 */
class AdditionalItem extends BaseModel {
  /** @type {string} The name of the database table for Additional Items */
  static table = 'additional_items';

  /** @type {string} The SQL query to select Additional Item data with related information */
  static selectQuery = `
    *,
    item_type:core_entity_types!item_type (id, type),
    owner:user_profiles (id, auth, first_name, last_name, email, profile_photo)
  `;

  /**
   * Creates an instance of AdditionalItem.
   * @param {Object} data - The additional item data.
   */
  constructor(data = {}) {
    super(data);
    this.booking = data.booking || null;
    this.invoiceId = data.invoice_id || null;
    this.owner = data.owner
      ? {
          id: data.owner.id,
          auth: data.owner?.auth,
          firstName: data.owner?.first_name,
          lastName: data.owner?.last_name,
          fullName: `${data.owner?.first_name} ${data.owner?.last_name}`,
          email: data.owner?.email,
          profilePhoto: data.owner?.profile_photo
        }
      : null;
    this.description = data.description || null;
    this.itemType = data.item_type
      ? { id: data.item_type.id, name: data.item_type.type }
      : null;
    this.vatRate = data.vat_rate || null;
    this.amount = data.amount || null;
  }

  /**
   * Converts the AdditionalItem instance to a database-friendly format.
   * @returns {Object} The additional item data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      booking: this.booking,
      invoice_id: this.invoiceId,
      owner: this.owner ? this.owner.id : null,
      description: this.description,
      item_type: this.itemType ? this.itemType.id : null,
      vat_rate: this.vatRate,
      amount: this.amount
    };
  }
}

export default AdditionalItem;
