import React from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/react';
import { ChevronsUpDown, Check } from 'lucide-react';
import { UserProfilePicture } from '../../media/UserProfilePicture';

const UserSelector = ({ users, selectedUser, onSelect, label = '' }) => {
  return (
    <Listbox value={selectedUser} onChange={onSelect}>
      <label className='block text-sm font-medium leading-6 text-gray-900'>
        {label}
      </label>
      <div className='relative mt-2'>
        <ListboxButton className='relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6'>
          <span className='flex items-center'>
            <UserProfilePicture
              firstName={selectedUser?.firstName}
              lastName={selectedUser?.lastName}
              profilePicture={selectedUser?.profilePicture}
              size='sm'
            />
            <span className='ml-3 block truncate'>
              {selectedUser
                ? `${selectedUser.firstName} ${selectedUser.lastName}`
                : 'Select a user'}
            </span>
          </span>
          <span className='pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2'>
            <ChevronsUpDown
              aria-hidden='true'
              className='h-5 w-5 text-gray-400'
            />
          </span>
        </ListboxButton>

        <ListboxOptions
          transition
          className='absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
        >
          {users.map(user => (
            <ListboxOption
              key={user.id}
              value={user}
              className={`group relative cursor-default select-none py-2 pl-3 pr-9 text-base-900 data-[focus]:bg-primary-100 data-[focus]:text-primary-900`}
            >
              <div className='flex flex-col sm:flex-row sm:items-center'>
                <div className='flex items-center'>
                  <UserProfilePicture
                    firstName={user.firstName}
                    lastName={user.lastName}
                    profilePicture={user.profilePicture}
                    size='sm'
                  />
                  <span
                    className={`ml-3 truncate font-normal group-data-[selected]:font-semibold`}
                  >
                    {`${user.firstName} ${user.lastName}`}
                  </span>
                </div>
                <span className='mt-1 ml-8 sm:mt-0 sm:ml-2 truncate text-base-400 group-data-[focus]:text-primary-500 text-sm'>
                  {user.email}
                </span>
              </div>

              <span
                className={`absolute inset-y-0 right-0 flex items-center pr-4 text-primary-900 group-data-[focus]:text-primary-900 [.group:not([data-selected])_&]:hidden`}
              >
                <Check className='h-5 w-5' aria-hidden='true' />
              </span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
};

export { UserSelector };
