import React, { useState } from 'react';
import { formatDate, getRelativeTimeString } from '../../utilities/Formatting';
import {
  TextSkeleton,
  FilledButton,
  SoftButton,
  OutlineButton,
  TextButton
} from 'core';
import { isEqual } from 'lodash';
import { Pin, PinOff } from 'lucide-react';

const FeedItem = ({ item, isLoading, logTypes, onPin, onUnpin }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isPinHovered, setIsPinHovered] = useState(false);

  if (isLoading) {
    return (
      <>
        <p className='flex-auto py-0.5 text-xs leading-5 text-neutral-500'>
          <TextSkeleton className='h-4 w-1/2' />
        </p>
        <TextSkeleton className='flex-none py-0.5 h-4 w-20' />
      </>
    );
  }

  if (item.auditType?.id === 119) {
    return (
      <div
        className='rounded-md border border-neutral-100 p-2 w-full'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className='flex items-center mb-2'>
          <span className='font-medium text-xs text-neutral-800'>
            {item.owner.firstName} {item.owner.lastName}{' '}
            <span className='text-xs font-normal text-neutral-500'>
              added a note.
            </span>
          </span>

          <div className='ml-auto flex items-center'>
            {item.pinned ? (
              <OutlineButton
                onClick={() => onUnpin(item.id)}
                className='mr-2'
                size='xs'
                colour='primary'
                onMouseEnter={() => setIsPinHovered(true)}
                onMouseLeave={() => setIsPinHovered(false)}
              >
                {isPinHovered ? <PinOff size={16} /> : <Pin size={16} />}
              </OutlineButton>
            ) : (
              isHovered && (
                <OutlineButton
                  onClick={() => onPin(item.id)}
                  className='mr-2'
                  size='xs'
                  colour='primary'
                >
                  <Pin size={16} />
                </OutlineButton>
              )
            )}
            <time
              dateTime={item.createdDate}
              className='text-xs text-neutral-500 mr-1 text-end py-1'
            >
              {getRelativeTimeString(item.createdDate)}
            </time>
          </div>
        </div>
        <div
          className='text-sm text-neutral-600'
          dangerouslySetInnerHTML={{ __html: item.content }}
        />
      </div>
    );
  }

  const renderChangePopup = () => {
    if (item.auditType?.id === 118 && showPopup) {
      return (
        <div className='absolute z-10 bg-white border border-neutral-200 rounded-md p-3 shadow-lg mt-5 left-1.5 w-64'>
          <h4 className='text-sm font-medium mb-2'>Changes:</h4>

          {Object.keys(item.newData || {}).length === 0 &&
          Object.keys(item.oldData || {}).length === 0 ? (
            <p className='text-xs mb-1'>{item.content}</p>
          ) : (
            Object.keys(item.newData || {}).map((key, index) => {
              const oldValue = item.oldData?.[key];
              const newValue = item.newData[key];
              if (key !== 'fts' && !isEqual(oldValue, newValue)) {
                return (
                  <p key={index} className='text-xs mb-1'>
                    <strong className='text-neutral-700'>{key}:</strong>{' '}
                    <span className='text-red-500'>
                      "{JSON.stringify(oldValue)}"
                    </span>{' '}
                    →{' '}
                    <span className='text-green-500'>
                      "{JSON.stringify(newValue)}"
                    </span>
                  </p>
                );
              }
              return null;
            })
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className='relative flex-auto py-0.5 flex justify-between items-start'
      onMouseEnter={() => {
        setShowPopup(true);
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setShowPopup(false);
        setIsHovered(false);
      }}
    >
      <p className='text-xs leading-5 text-neutral-500 ml-2'>
        <span className='font-medium text-neutral-800'>
          {item.owner.firstName} {item.owner.lastName}
        </span>{' '}
        {logTypes[item.auditType?.id]?.title || 'Updated'} the{' '}
        {item.entityType?.displayName}.
      </p>
      <div className='flex items-center'>
        {item.pinned ? (
          <OutlineButton
            onClick={() => onUnpin(item.id)}
            className='mr-2'
            size='xs'
            colour='primary'
            onMouseEnter={() => setIsPinHovered(true)}
            onMouseLeave={() => setIsPinHovered(false)}
          >
            {isPinHovered ? <PinOff size={16} /> : <Pin size={16} />}
          </OutlineButton>
        ) : (
          isHovered && (
            <OutlineButton
              onClick={() => onPin(item.id)}
              className='mr-2'
              size='xs'
              colour='primary'
            >
              <Pin size={16} />
            </OutlineButton>
          )
        )}
        <time
          dateTime={item.createdDate}
          className='text-xs leading-5 text-neutral-500 mr-3 py-1'
        >
          {getRelativeTimeString(item.createdDate)}
        </time>
      </div>
      {renderChangePopup()}
    </div>
  );
};

export default FeedItem;
