import Company from './Company';

/**
 * Represents an Imported Company in the system.
 * @extends Company
 */
class ImportedCompany extends Company {
  /** @type {string} The name of the database table for Imported Companies */
  static table = 'imported_companies';

  /**
   * Creates an instance of ImportedCompany.
   * @param {Object} data - The imported company data.
   */
  constructor(data = {}) {
    super(data);
    this.readOnly = data.read_only ?? null;
    // Add any additional properties specific to imported companies here
  }

  /**
   * Converts the ImportedCompany instance to a database-friendly format.
   * @returns {Object} The imported company data ready for database operations.
   */
  toDatabase() {
    const baseData = super.toDatabase();
    // Add any additional properties specific to imported companies here
    return {
      ...baseData
      // Add or override properties as needed
    };
  }
}

export default ImportedCompany;
