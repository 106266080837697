import BaseModel from './BaseModel';

/**
 * Represents a Document in the system.
 * @extends BaseModel
 */
class Document extends BaseModel {
  /** @type {string} The name of the database table for Documents */
  static table = 'documents';

  /** @type {string} The SQL query to select Document data with related information */
  static selectQuery = `
    *,
    user_profiles:owner!inner (
      first_name,
      last_name,
      full_name,
      profile_photo
    )
  `;

  /**
   * Creates an instance of Document.
   * @param {Object} data - The document data.
   */
  constructor(data = {}) {
    super(data);
    this.owner = data.owner
      ? {
          id: data.owner,
          firstName: data.user_profiles?.first_name,
          lastName: data.user_profiles?.last_name,
          profilePhoto: data.user_profiles?.profile_photo
        }
      : null;
    this.fileName = data.file_name || null;
    this.filePath = data.file_path || null;
    this.fileType = data.file_type || null;
    this.connections = data.connections || [];
  }

  /**
   * Converts the Document instance to a database-friendly format.
   * @returns {Object} The document data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      owner: this.owner ? this.owner.id : null,
      file_name: this.fileName,
      file_path: this.filePath,
      file_type: this.fileType,
      connections: this.connections
    };
  }
}

export default Document;
