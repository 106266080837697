import BaseModel from './BaseModel';

/**
 * Represents a Course in the system.
 * @extends BaseModel
 */
class Course extends BaseModel {
  /** @type {string} The name of the database table for Courses */
  static table = 'courses';

  /** @type {string} The SQL query to select Course data with related information */
  static selectQuery = `
    *,
    status:core_entity_types!status (id, type),
    accreditation:core_entity_types!accreditation (id, type)
  `;

  /**
   * Creates an instance of Course.
   * @param {Object} data - The course data.
   */
  constructor(data = {}) {
    super(data);
    this.name = data.name || null;
    this.attendance = data.attendance || null;
    this.objectives = data.objectives || null;
    this.content = data.content || null;
    this.preReqs = data.pre_reqs || null;
    this.entryReqs = data.entry_reqs || null;
    this.passReqs = data.pass_reqs || null;
    this.furtherDev = data.further_dev || null;
    this.grant = data.grant || false;
    this.accreditation = data.accreditation
      ? { id: data.accreditation.id, type: data.accreditation.type }
      : null;
    this.industries = data.industries || [];
    this.websiteDetails = data.website_details || {
      visible: false,
      header_image: ''
    };
    this.status = data.status
      ? { id: data.status.id, name: data.status.type }
      : null;
  }

  /**
   * Converts the Course instance to a database-friendly format.
   * @returns {Object} The course data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      name: this.name,
      attendance: this.attendance,
      objectives: this.objectives,
      content: this.content,
      pre_reqs: this.preReqs,
      entry_reqs: this.entryReqs,
      pass_reqs: this.passReqs,
      further_dev: this.furtherDev,
      grant: this.grant,
      accreditation: this.accreditation,
      industries: this.industries,
      website_details: this.websiteDetails,
      status: this.status ? this.status.id : null
    };
  }
}

export default Course;
