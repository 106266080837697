import React from 'react';
import UserProfile from '../../../models/UserProfile';
import EntityTable from '../../../components/tables/EntityTable';
import { formatDate } from '../../../utilities/Formatting';
import { Link, useNavigate } from 'react-router-dom';
import { Pencil, Trash, SquareArrowOutUpRight } from 'lucide-react';
import { EntityTypeEnum } from '../../../utilities/Enumerables';

const Users = () => {
  //Set the page title
  document.title = 'Users | CRM | BOHSA LTD';
  const navigate = useNavigate();

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/user-profiles/${data.displayId}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => navigate(`/user-profiles/${data.displayId}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const handleRowClick = data => {
    navigate(`/user-profiles/${data.displayId}`);
  };

  return (
    <div className='p-4'>
      <EntityTable
        name='Users'
        filter={[
          { field: 'display_id', label: 'ID', type: 'text', operator: 'eq' },
          {
            field: 'first_name',
            label: 'First Name',
            type: 'text',
            operator: 'ilike'
          },
          {
            field: 'last_name',
            label: 'Last Name',
            type: 'text',
            operator: 'ilike'
          },
          { field: 'email', label: 'Email', type: 'text', operator: 'ilike' },
          { field: 'created_date_from', label: 'Created From', type: 'date' },
          { field: 'created_date_to', label: 'Created To', type: 'date' }
        ]}
        sortDefault={{ field: 'display_id', order: 'asc' }}
        columns={[
          {
            label: 'ID',
            field: 'displayId',
            cell: value => (
              <Link
                to={`/user-profiles/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          { label: 'First Name', field: 'firstName' },
          { label: 'Last Name', field: 'lastName' },
          { label: 'Email', field: 'email' },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={UserProfile}
        entityType={EntityTypeEnum.User}
        onRowClick={handleRowClick}
        canCreate={true}
      />
    </div>
  );
};

export default Users;
