import React from 'react';
import { UserRound } from 'lucide-react';
/**
 * UserProfilePicture Component
 *
 * @param {string} [props.firstName] - The user's first name
 * @param {string} [props.lastName] - The user's last name
 * @param {string} [props.profilePicture] - URL of the user's profile picture
 * @param {string} [props.size='md'] - The size of the profile picture ('xs', 'sm', 'md', 'lg', 'xl')
 * @returns {React.ReactElement} An image or icon representing the user's profile picture
 */
const UserProfilePicture = ({
  firstName,
  lastName,
  profilePicture,
  size = 'md'
}) => {
  const sizeClasses = {
    xs: 'h-4 w-4',
    sm: 'h-5 w-5',
    md: 'h-6 w-6',
    lg: 'h-8 w-8',
    xl: 'h-10 w-10'
  };

  const iconSizes = {
    xs: 12,
    sm: 14,
    md: 16,
    lg: 20,
    xl: 24
  };

  const sizeClass = sizeClasses[size] || sizeClasses.md;
  const iconSize = iconSizes[size] || iconSizes.md;

  if (profilePicture) {
    return (
      <img
        src={profilePicture}
        alt={`${firstName} ${lastName}`}
        className={`${sizeClass} rounded-full flex-none bg-neutral-100`}
      />
    );
  }

  return (
    <div
      className={`${sizeClass} rounded-full bg-neutral-100 flex items-center justify-center`}
    >
      <UserRound size={iconSize} className='text-neutral-600' />
    </div>
  );
};

export { UserProfilePicture };
