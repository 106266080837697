import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../../../utilities/supabase';
import UserProfile from '../../../models/UserProfile';
import Cropper from 'react-easy-crop';
import { useNotification, OutlineButton, FilledButton, Popup } from 'core';

const ProfilePicturePopup = ({ uploadedImage, onClose, isOpen }) => {
  const [profile, setProfile] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const { addNotification } = useNotification();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const {
          data: { user }
        } = await supabase.auth.getUser();
        if (user) {
          const { data, error } = await UserProfile.getByAuthId(user.id, true);
          if (error) throw error;

          setProfile(data[0]);
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        addNotification({
          type: 'error',
          title: 'Error',
          description: 'Failed to fetch profile'
        });
      }
    };

    fetchUserProfile();
  }, [addNotification]);

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const saveProfilePhoto = async () => {
    try {
      if (!croppedAreaPixels || !uploadedImage) return;

      const canvas = document.createElement('canvas');
      const image = new Image();
      image.src = uploadedImage;
      image.onload = () => {
        const ctx = canvas.getContext('2d');
        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );

        const croppedImageDataUrl = canvas.toDataURL('image/jpeg');
        profile
          .update({ profilePhoto: croppedImageDataUrl })
          .then(({ error }) => {
            if (error) throw error;

            onClose();

            addNotification({
              type: 'success',
              title: 'Success',
              description: 'Profile photo updated'
            });
          });
      };
    } catch (error) {
      console.error('Error updating profile photo:', error);
      addNotification({
        type: 'error',
        title: 'Error',
        description: 'Failed to update profile photo'
      });
    }
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose} title='Crop Profile Photo'>
      <div className='space-y-4'>
        {uploadedImage && (
          <div
            className='crop-container'
            style={{ height: '400px', position: 'relative' }}
          >
            <Cropper
              image={uploadedImage}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              style={{ containerStyle: { width: '100%', height: '100%' } }}
            />
          </div>
        )}

        <div className='flex justify-end gap-x-4'>
          <OutlineButton colour='base' onClick={onClose}>
            Cancel
          </OutlineButton>
          <FilledButton colour='primary' onClick={saveProfilePhoto}>
            Save Photo
          </FilledButton>
        </div>
      </div>
    </Popup>
  );
};

export default ProfilePicturePopup;
