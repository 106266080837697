import React, { useState, useEffect } from 'react';
import { DropdownInput, Modal } from 'core';
import Invoice from '../../../models/Invoice';
import { FilterOperatorEnum } from '../../../utilities/Enumerables';
import AdditionalItem from '../../../models/AdditionalItem';

const LinkInvoice = ({ isOpen, onClose, additionalItemId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [existingInvoices, setExistingInvoices] = useState([]);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        // Fetch existing invoices
        const { data: invoices } = await Invoice.getAll({
          status: { operator: FilterOperatorEnum.NOT_IN, value: '(219, 220)' }
        });
        setExistingInvoices(
          invoices.map(invoice => ({
            value: invoice.id,
            label: `ID:${invoice.id} - Ref:${invoice?.reference ?? ''}`
          }))
        );
      } catch (error) {
        console.error('Error fetching invoices:', error);
        setError('Failed to load invoices. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  const handleInvoiceSelect = e => {
    setSelectedInvoiceId(e.target.value);
  };

  const handleSubmit = async () => {
    //selectedInvoiceId
    const item = await AdditionalItem.getById(additionalItemId);
    item.invoiceId = selectedInvoiceId;
    await item.update();
    onClose();
  };

  const renderContent = () => {
    if (isLoading) {
      return <p>Loading invoices...</p>;
    }

    if (error) {
      return <p>{error}</p>;
    }

    return (
      <div className='space-y-4'>
        <DropdownInput
          label='Select Invoice'
          name='invoiceId'
          value={selectedInvoiceId}
          onChange={handleInvoiceSelect}
          options={existingInvoices}
          required
        />
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title='Link Invoice'
      actions={[
        {
          label: 'Link Invoice',
          onClick: handleSubmit,
          style: 'primary'
        },
        {
          label: 'Cancel',
          onClick: onClose,
          style: 'base'
        }
      ]}
    >
      {renderContent()}
    </Modal>
  );
};

export default LinkInvoice;
