import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Pencil, Download, Trash } from 'lucide-react';
import EntityTable from '../../tables/EntityTable';
import { formatDate, formatProfile } from '../../../utilities/Formatting';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import Document from '../../../models/Document';
import FileUploader from '../../popups/files/FileUploader';
import { supabase } from '../../../utilities/supabase';

const FileOverview = ({ entityType, id, currentUser }) => {
  const [isUploadOpen, setIsUploadOpen] = useState(false);

  const handleNewFileClick = () => {
    setIsUploadOpen(true);
  };

  const handleUploadSuccess = () => {
    // Logic to handle successful upload
    setIsUploadOpen(false);
  };

  return (
    <div>
      <EntityTable
        name='Documents'
        filter={[
          { field: 'id', label: 'ID', type: 'text' },
          {
            field: 'file_name',
            label: 'File Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'owner.full_name',
            label: 'Owner',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'created_date',
            label: 'Created Date',
            type: 'date'
          },
          {
            field: 'updated_date',
            label: 'Updated Date',
            type: 'date'
          }
        ]}
        sort={[
          { value: 'id', label: 'Document ID' },
          { value: 'file_name', label: 'File Name' },
          { value: 'created_date', label: 'Date Created' },
          { value: 'updated_date', label: 'Last Modified' }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/documents/${value}`}
                className='text-primary-700 hover:text-primary-800'
              >
                {value}
              </Link>
            )
          },
          { label: 'File Name', field: 'fileName' },
          {
            label: 'Owner',
            field: 'owner',
            cell: value => formatProfile(value)
          },
          {
            label: 'File Path',
            field: 'filePath'
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        model={Document}
        entityType={EntityTypeEnum.Document}
        onNewClick={handleNewFileClick}
        menuItems={[
          {
            label: 'Edit',
            icon: <Pencil size={14} />,
            onClick: data => (window.location.href = `/documents/${data.id}`)
          },
          {
            label: 'Download',
            icon: <Download size={14} />,
            onClick: async document => {
              try {
                const { data: response, error } =
                  await supabase.functions.invoke(
                    'storage/getSigned/download',
                    {
                      body: {
                        key: `${document.filePath}/${document.id}.${document.fileType}`,
                        downloadAs: `${document.fileName}.${document.fileType}`
                      }
                    }
                  );

                if (error) throw error;

                const link = window.document.createElement('a');
                link.href = response.url;
                link.download =
                  `${document.fileName}.${document.fileType}` ||
                  `document.${document.fileType}`;
                window.document.body.appendChild(link);
                link.click();
                window.document.body.removeChild(link);
              } catch (error) {
                console.error('Error downloading document:', error);
              }
            }
          },
          {
            label: 'Delete',
            icon: <Trash size={14} className='text-danger-700' />,
            onClick: data => {
              console.log('Delete clicked', data.owner.id);
              console.log('Current user:', currentUser.id);
            },
            condition: data => data.owner?.id === currentUser?.id
          }
        ]}
        defaultFilters={{
          connections: { operator: 'contains', value: { [entityType]: [id] } }
        }}
      />
      {isUploadOpen && (
        <FileUploader
          onClose={() => setIsUploadOpen(false)}
          onUploadSuccess={handleUploadSuccess}
          destinationFolder={'general-docs'}
          connections={{
            [entityType]: [id]
          }}
        />
      )}
    </div>
  );
};

export default FileOverview;
