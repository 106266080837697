import React, { useState } from 'react';
import { Modal, TextButton, FilledButton, Input, ColourPicker, Checkbox } from 'core';

const AddTagModal = ({ isOpen, onClose, onAdd }) => {
  const [name, setName] = useState('');
  const [color, setColor] = useState('zinc');
  const [showOnPipelines, setShowOnPipelines] = useState(false);

  const handleSubmit = () => {
    onAdd(name, color, showOnPipelines);
    setName('');
    setColor('zinc');
    setShowOnPipelines(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Add New Tag'>
      <div className='space-y-4'>
        <Input
          label='Tag Name'
          placeholder='Enter tag name'
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <div>
          <label className='block text-sm font-medium text-gray-700 mb-1'>
            Tag Color
          </label>
          <ColourPicker
            showShades={false}
            colour={color}
            onChange={colour => setColor(colour)}
          />
        </div>
        <div>
          <Checkbox
            label='Show on pipelines'
            checked={showOnPipelines}
            onChange={e => setShowOnPipelines(e.target.checked)}
          />
        </div>
        <div className='flex justify-end space-x-2'>
          <TextButton onClick={onClose}>Cancel</TextButton>
          <FilledButton onClick={handleSubmit} disabled={!name}>
            Add Tag
          </FilledButton>
        </div>
      </div>
    </Modal>
  );
};

export default AddTagModal;
