import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ArrowLeft,
  ArrowRight,
  Download,
  Upload,
  FileText,
  Globe,
  Phone
} from 'lucide-react';
import { FilledButton, SoftButton, Alert } from 'core';
import { supabase } from '../../../../../utilities/supabase';
import {
  determineLinkDisplay,
  determineLinkType
} from '../../../../../utilities/Formatting';
import {
  SiFacebook,
  SiGoogle,
  SiLinkedin,
  SiX
} from '@icons-pack/react-simple-icons';

const UploadUsers = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [file, setFile] = useState(null);
  const [previewData, setPreviewData] = useState([]);
  const [fileName, setFileName] = useState('');

  const steps = [{ title: 'Upload File' }, { title: 'Preview and Confirm' }];

  const handleFileUpload = event => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    setFileName(uploadedFile.name);

    // Read and parse CSV file
    const reader = new FileReader();
    reader.onload = e => {
      const csv = e.target.result;
      const lines = csv.split('\n');
      const headers = lines[0].split(',').map(header => header.trim());

      // Find all "phones" and "links" columns
      const phoneColumns = headers.filter(header =>
        header.startsWith('phones_')
      );
      const linkColumns = headers.filter(header => header.startsWith('links_'));

      const data = lines.slice(1, 6).map(line => {
        const values = line.split(',');
        const row = headers.reduce((obj, header, index) => {
          if (!header.startsWith('phones_') && !header.startsWith('links_')) {
            obj[header] = values[index];
          }
          return obj;
        }, {});

        // Combine all "phones" columns into a single array of objects
        const phones = phoneColumns
          .map((column, index) => {
            const value = values[headers.indexOf(column)];
            if (value) {
              const [, , type] = column.split('_');
              return { type, number: value };
            }
            return null;
          })
          .filter(Boolean);
        if (phones.length > 0) {
          row['phones'] = phones;
        }

        // Combine all "links" columns into a single array
        const links = linkColumns
          .map(column => values[headers.indexOf(column)])
          .filter(Boolean);
        if (links.length > 0) {
          row['links'] = links;
        }

        return row;
      });
      setPreviewData(data);
    };
    reader.readAsText(uploadedFile);
  };

  const handleFileNameChange = event => {
    setFileName(event.target.value);
  };

  const formatFileSize = bytes => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleSubmit = async () => {
    try {
      const reader = new FileReader();
      reader.onload = async e => {
        const csv = e.target.result;
        const lines = csv.split('\n');
        const headers = lines[0].split(',').map(header => header.trim());
        const phoneColumns = headers.filter(header =>
          header.startsWith('phones_')
        );
        const linkColumns = headers.filter(header =>
          header.startsWith('links_')
        );

        const users = lines.slice(1).map(line => {
          const values = line.split(',');
          const user = headers.reduce((obj, header, index) => {
            if (!header.startsWith('phones_') && !header.startsWith('links_')) {
              obj[header] = values[index];
            }
            return obj;
          }, {});

          const phones = phoneColumns
            .map((column, index) => {
              const value = values[headers.indexOf(column)];
              if (value) {
                const [, , type] = column.split('_');
                return { type, number: value };
              }
              return null;
            })
            .filter(Boolean);
          if (phones.length > 0) {
            user['phones'] = phones;
          }

          const links = linkColumns
            .map(column => values[headers.indexOf(column)])
            .filter(Boolean);
          if (links.length > 0) {
            user['links'] = links.map(link => ({
              url: link,
              display: determineLinkDisplay(link),
              type: determineLinkType(link)
            }));
          }

          return user;
        });

        const { data, error } = await supabase
          .from('imported_user_profiles')
          .insert(users);

        if (error) throw error;

        console.log('Import successful:', data);
        navigate('/imports/user-profiles');
      };

      reader.readAsText(file);
    } catch (error) {
      console.error('Error importing users:', error);
      addNotification({
        type: 'error',
        title: 'Import Failed',
        description:
          'There was an error importing the users. Please try again.',
        duration: 5000
      });
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className='space-y-4'>
            <Alert
              title='Import Instructions'
              description='Please upload a CSV file containing user data. Ensure your file matches the template format for successful import.'
              style='info'
              actions={[
                {
                  text: 'Download template.csv',
                  onClick: () => {
                    // Trigger download for the CSV template from Google Cloud Storage
                    const templateUrl =
                      'https://storage.googleapis.com/bohsa-docs/SYSTEM/users_import_template.csv'; // URL will be inserted manually
                    const link = document.createElement('a');
                    link.href = templateUrl;
                    link.download = 'users_import_template.csv';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }
                },
                {
                  text: 'View CSV Guide',
                  onClick: () => {
                    // Open the CSV guide in a new tab
                    window.open(
                      'https://storage.googleapis.com/bohsa-docs/SYSTEM/users_import_guide.pdf',
                      '_blank'
                    );
                  }
                }
              ]}
            />
            <div className='flex flex-col items-center space-y-4'>
              <input
                type='file'
                accept='.csv'
                onChange={handleFileUpload}
                className='hidden'
                id='fileInput'
              />
              <label
                htmlFor='fileInput'
                className='cursor-pointer flex items-center justify-center w-full h-32 border-2 border-dashed border-neutral-300 rounded-lg hover:border-brand-500 transition-colors duration-300'
              >
                <div className='text-center'>
                  <Upload size={24} className='mx-auto text-neutral-400' />
                  <p className='mt-2 text-sm text-neutral-600'>
                    {file
                      ? file.name
                      : 'Click to select a file or drag and drop'}
                  </p>
                </div>
              </label>
              {file && (
                <div className='w-full'>
                  <div className='flex items-center space-x-2'>
                    <FileText size={20} className='text-neutral-400' />
                    <input
                      type='text'
                      value={fileName}
                      onChange={handleFileNameChange}
                      className='flex-grow border rounded px-2 py-1'
                    />
                  </div>
                  <p className='text-sm text-neutral-600 mt-2'>
                    Size: {formatFileSize(file.size)} | Type: {file.type}
                  </p>
                </div>
              )}
            </div>
          </div>
        );
      case 1:
        return (
          <div className='space-y-4'>
            <h3 className='text-lg font-semibold'>Preview</h3>
            <div className='overflow-x-auto'>
              <table className='min-w-full divide-y divide-gray-200'>
                <thead className='bg-gray-50'>
                  <tr>
                    {Object.keys(previewData[0] || {}).map(header => (
                      <th
                        key={header}
                        className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {previewData.map((row, index) => (
                    <tr key={index}>
                      {Object.entries(row).map(([key, value], cellIndex) => (
                        <td
                          key={cellIndex}
                          className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'
                        >
                          {key === 'links' ? (
                            <div className='space-y-2'>
                              {value.map((link, linkIndex) => {
                                const linkType = determineLinkType(link);
                                const linkDisplay = determineLinkDisplay(link);
                                return (
                                  <div
                                    key={linkIndex}
                                    className='flex items-center'
                                  >
                                    {linkType === 'Website' && (
                                      <Globe className='mr-2' size={18} />
                                    )}
                                    {linkType === 'X' && (
                                      <SiX className='mr-2 text-x' size={18} />
                                    )}
                                    {linkType === 'LinkedIn' && (
                                      <SiLinkedin
                                        className='mr-2 text-linkedin'
                                        size={18}
                                      />
                                    )}
                                    {linkType === 'Facebook' && (
                                      <SiFacebook
                                        className='mr-2 text-facebook'
                                        size={18}
                                      />
                                    )}
                                    {linkType === 'Google' && (
                                      <SiGoogle
                                        className='mr-2 text-google'
                                        size={18}
                                      />
                                    )}
                                    <a
                                      href={link}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      className='text-primary-600 hover:text-primary-700 hover:underline transition-colors duration-200'
                                    >
                                      {linkDisplay}
                                    </a>
                                  </div>
                                );
                              })}
                            </div>
                          ) : key === 'phones' ? (
                            <div className='space-y-2'>
                              {value.map((phone, phoneIndex) => (
                                <div
                                  key={phoneIndex}
                                  className='flex items-center'
                                >
                                  <Phone className='mr-2' size={18} />
                                  <span>{phone.type}: {phone.number}</span>
                                </div>
                              ))}
                            </div>
                          ) : (
                            value
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className='bg-base-100 h-full'>
      <div className='max-w-4xl mx-auto px-4 py-8'>
        <SoftButton
          colour='primary'
          size='md'
          leftIcon={<ArrowLeft size={18} />}
          onClick={() => navigate('/imports/user-profiles')}
          className='mb-8'
        >
          Back to Imports
        </SoftButton>

        <div className='bg-white shadow-md rounded-lg overflow-hidden'>
          <div className='px-6 py-4'>
            <h2 className='text-2xl font-bold'>Import Users</h2>
          </div>

          <div className='p-6'>
            <div className='mb-8'>
              <div className='flex justify-between'>
                {steps.map((step, index) => (
                  <div
                    key={index}
                    className={`flex items-center ${
                      index <= currentStep ? 'text-brand-700' : 'text-base-400'
                    }`}
                  >
                    <div
                      className={`w-8 h-8 rounded-full flex items-center justify-center ${
                        index <= currentStep
                          ? 'bg-brand-700 text-white'
                          : 'bg-base-200'
                      }`}
                    >
                      {index + 1}
                    </div>
                    <span className='ml-2 text-sm font-medium'>
                      {step.title}
                    </span>
                  </div>
                ))}
              </div>
              <div className='mt-4 h-2 bg-base-200 rounded-full'>
                <div
                  className='h-full bg-brand-700 rounded-full transition-all duration-300 ease-in-out'
                  style={{
                    width: `${((currentStep + 1) / steps.length) * 100}%`
                  }}
                ></div>
              </div>
            </div>

            {renderStep()}

            <div className='mt-8 flex justify-between'>
              <SoftButton
                colour='primary'
                size='lg'
                leftIcon={<ArrowLeft size={18} />}
                onClick={() => setCurrentStep(prev => Math.max(0, prev - 1))}
                disabled={currentStep === 0}
              >
                Previous
              </SoftButton>
              {currentStep < steps.length - 1 ? (
                <FilledButton
                  colour='primary'
                  size='lg'
                  rightIcon={<ArrowRight size={18} />}
                  onClick={() =>
                    setCurrentStep(prev => Math.min(steps.length - 1, prev + 1))
                  }
                  disabled={!file}
                >
                  Next
                </FilledButton>
              ) : (
                <FilledButton
                  colour='primary'
                  size='lg'
                  onClick={handleSubmit}
                  disabled={!file}
                >
                  Import Users
                </FilledButton>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadUsers;
