import BaseModel from './BaseModel';

/**
 * Represents an Automation in the system.
 * @extends BaseModel
 */
class Automation extends BaseModel {
  /** @type {string} The name of the database table for Automations */
  static table = 'automations';

  /** @type {string} The SQL query to select Automation data with related information */
  static selectQuery = `
    *,
    owner:user_profiles!inner (id, auth, first_name, last_name, email, profile_photo),
    trigger:core_entity_types!trigger (id, type),
    entity:core_entities!entity (id, display_name, table_name)
  `;

  /**
   * Creates an instance of Automation.
   * @param {Object} data - The automation data.
   */
  constructor(data = {}) {
    super(data);
    this.name = data.name || null;
    this.description = data.description || null;
    this.createdDate = data.created_date || null;
    this.updatedDate = data.updated_date || null;
    this.trigger = data.trigger
      ? { id: data.trigger.id, type: data.trigger.type }
      : null;
    this.send = data.send || {};
    this.exit = data.exit || {};
    this.metadata = data.metadata || {};
    this.entity = data.entity || null;
    this.filters = data.filters || {};
    this.owner =
      typeof data.owner == 'object'
        ? {
            id: data.owner.id,
            auth: data.owner?.auth,
            firstName: data.owner?.first_name,
            lastName: data.owner?.last_name,
            fullName: `${data.owner?.first_name} ${data.owner?.last_name}`,
            email: data.owner?.email,
            profilePhoto: data.owner?.profile_photo
          }
        : data.owner
        ? data.owner
        : null; // Added owner field
  }

  /**
   * Converts the Automation instance to a database-friendly format.
   * @returns {Object} The automation data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      name: this.name,
      description: this.description,
      created_date: this.createdDate,
      updated_date: this.updatedDate,
      trigger: this.trigger ? this.trigger.id : null,
      send: this.send,
      exit: this.exit,
      metadata: this.metadata,
      entity: this.entity,
      filters: this.filters,
      owner: this.owner // Added owner field
    };
  }
}

export default Automation;
