import React, { useState, useEffect } from 'react';
import { Filter, Plus } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilledButton, OutlineButton, SoftButton, TextButton } from 'core';
import { DropdownInput } from '../inputs/Forms';
import Pagination from '../navigation/Pagination';
import Table from './Table';
import Filters from '../filters/FiltersMenu';
import ActiveFilters from '../filters/ActiveFilters';
import { realtime } from '../../utilities/supabase';

const EntityTable = ({
  name,
  filter,
  columns,
  model,
  entityType,
  menuItems,
  onNewClick,
  defaultFilters = {},
  flags,
  onRowClick,
  canCreate,
  sortDefault = { field: 'id', order: 'asc' }
}) => {
  const [loading, setLoading] = useState(true);
  const [entities, setEntities] = useState([]);
  const [filters, setFilters] = useState({});
  const [sortBy, setSortBy] = useState(sortDefault.field);
  const [sortOrder, setSortOrder] = useState(sortDefault.order);
  const [isError, setIsError] = useState(null);
  const [totalEntities, setTotalEntities] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const entitiesPerPage = 10;
  const location = useLocation();
  const navigate = useNavigate();

  const fetchData = async (
    currentFilters,
    page = 1,
    currentSortBy = sortBy,
    currentSortOrder = sortOrder,
    loading = true
  ) => {
    try {
      if (loading) setLoading(true);

      const filterParams = { ...defaultFilters, ...currentFilters };

      const { data, count, total } = await model.getAll(
        filterParams,
        page,
        entitiesPerPage,
        currentSortBy,
        currentSortOrder
      );
      setEntities(data);
      setTotalEntities(count);
      setCurrentPage(page);
      setTotalPages(total);

      setIsError(false);
    } catch (error) {
      setIsError(true);
    } finally {
      if (loading) setLoading(false);
    }
  };

  useEffect(() => {
    const channel = realtime('*', model.table, () =>
      fetchData(filters, currentPage, sortBy, sortOrder, false)
    );

    return () => channel.unsubscribe();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const initialFilters = {};

    filter.forEach(filter => {
      const urlValue = searchParams.get(filter.field);
      if (urlValue) {
        const [operator, value] = urlValue.split('.');
        initialFilters[filter.field] = {
          value: value || urlValue,
          label: filter.label,
          operator: operator || 'eq'
        };
      }
    });

    setFilters(initialFilters);

    const initialSortBy = searchParams.get('sortBy') || sortDefault.field;
    const initialSortOrder = searchParams.get('sortOrder') || sortDefault.order;
    setSortBy(initialSortBy);
    setSortOrder(initialSortOrder);

    fetchData(initialFilters, 1, initialSortBy, initialSortOrder);
  }, [location.search]);

  const applyFilters = newFilters => {
    setFilters(newFilters);
    setCurrentPage(1);
    updateUrlParams(newFilters, sortBy, sortOrder);
    fetchData(newFilters, 1, sortBy, sortOrder);
  };

  const removeFilter = key => {
    const newFilters = { ...filters };
    delete newFilters[key];

    setFilters(newFilters);
    updateUrlParams(newFilters, sortBy, sortOrder);
    fetchData(newFilters, 1, sortBy, sortOrder);
  };

  const removeAllFilters = () => {
    setFilters({});
    updateUrlParams({}, sortBy, sortOrder);
    fetchData({}, 1, sortBy, sortOrder);
  };

  const updateUrlParams = (newFilters, newSortBy, newSortOrder) => {
    const searchParams = new URLSearchParams();

    if (newSortBy) {
      searchParams.set('sortBy', newSortBy);
    }

    if (newSortOrder && newSortOrder !== 'asc') {
      searchParams.set('sortOrder', newSortOrder);
    }

    const queryString = searchParams.toString();

    navigate(
      queryString ? `${location.pathname}?${queryString}` : location.pathname,
      { replace: true }
    );
  };

  const handleSort = field => {
    const column = columns.find(col => col.field === field);
    const sortField = column.foreignKey
      ? `${column.foreignKey.table}.${column.foreignKey.column}`
      : field;
    const newSortOrder =
      sortField === sortBy && sortOrder === 'asc' ? 'desc' : 'asc';

    setSortBy(sortField);
    setSortOrder(newSortOrder);
    updateUrlParams(filters, sortField, newSortOrder);
    fetchData(filters, currentPage, sortField, newSortOrder);
  };

  return (
    <>
      <div className='flex flex-col mb-3 w-full'>
        <div className='flex justify-between items-center'>
          <div className='flex space-x-4 items-center'>
            {canCreate && (
              <FilledButton
                colour='primary'
                leftIcon={<Plus size={18} />}
                onClick={onNewClick}
              >
                New
              </FilledButton>
            )}
            <SoftButton
              colour='primary'
              leftIcon={<Filter size={18} />}
              onClick={() => setIsFiltersOpen(!isFiltersOpen)}
            >
              Filter
            </SoftButton>
          </div>
          <div className='ml-auto'>
            <p className='text-neutral-600 text-sm px-2'>
              Total {name}: <span className='font-medium'>{totalEntities}</span>
            </p>
          </div>
        </div>
        <ActiveFilters
          filters={filters}
          removeFilter={removeFilter}
          removeAllFilters={removeAllFilters}
        />
      </div>
      <div className=''>
        <Table
          columns={columns.map(column => ({
            ...column,
            sortable: true,
            sortField: column.foreignKey
              ? `${column.foreignKey.table}.${column.foreignKey.column}`
              : column.field
          }))}
          data={entities}
          isLoading={loading}
          isError={isError}
          menuItems={menuItems}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onSort={handleSort}
          onRowClick={onRowClick}
        />
      </div>
      <div className='mt-6'>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={newPage => {
            setCurrentPage(newPage);
            fetchData(filters, newPage, sortBy, sortOrder);
          }}
        />
      </div>
      <Filters
        isOpen={isFiltersOpen}
        onClose={() => setIsFiltersOpen(!isFiltersOpen)}
        onApply={applyFilters}
        filter={filter}
        filters={filters}
        setFilters={setFilters}
        entityType={entityType}
      />
    </>
  );
};

export default EntityTable;
