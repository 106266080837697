import { User as UserIcon, Mail, Phone, Briefcase } from 'lucide-react';
import Entity from '../../../components/entity/Entity';
import UserProfile from '../../../models/UserProfile';
import Field from '../../../components/entity/Field';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import DateCard from '../../../components/entity/DateCard';

const UserDetails = ({ currentUser }) => {
  const infoItems = [
    {
      icon: UserIcon,
      label: 'First Name',
      field: 'firstName',
      isEditable: true
    },
    {
      icon: UserIcon,
      label: 'Last Name',
      field: 'lastName',
      isEditable: true
    },
    { icon: Mail, label: 'Email', field: 'email', isEditable: true }
    // {
    //   icon: Briefcase,
    //   label: 'Job Title',
    //   field: 'jobTitle',
    //   isEditable: true
    // }
  ];

  const accordionItems = [
    {
      title: 'Key Dates',
      content: ({ data, onUpdate }) => (
        <div className='grid grid-cols-2 md:grid-cols-3 gap-2'>
          <DateCard
            date={data.metrics.last_chase}
            label='Last Chase'
            isEditable={true}
            editField='metrics.last_chase'
            onUpdate={(field, value) =>
              onUpdate('metrics', { ...data.metrics, last_chase: value })
            }
          />
          <DateCard
            date={data.metrics.next_chase}
            label='Next Chase'
            isEditable={true}
            editField='metrics.next_chase'
            onUpdate={(field, value) =>
              onUpdate('metrics', { ...data.metrics, next_chase: value })
            }
          />
        </div>
      )
    },
    {
      title: 'Contact Details',
      content: ({ data }) => (
        <div>
          {data.phones &&
            data.phones.map((phone, index) => (
              <Field
                key={index}
                Icon={Phone}
                label={phone.type.charAt(0).toUpperCase() + phone.type.slice(1)}
                value={phone.number}
                isEditable={false}
              />
            ))}
        </div>
      )
    },
    { title: 'Other', content: <div>Other content</div> }
  ];

  const additionalTabs = [
    //{ label: 'Test 2', path: 'test2', content: <div>Test content</div> }
  ];

  return (
    <Entity
      currentUser={currentUser}
      entityType={EntityTypeEnum.User}
      model={UserProfile}
      infoItems={infoItems}
      accordionItems={accordionItems}
      additionalTabs={additionalTabs}
    />
  );
};

export default UserDetails;
