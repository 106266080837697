import {
  Zap,
  LogOut,
  Home,
  User,
  Settings,
  Bell,
  Heart,
  Star,
  Cloud,
  Sun,
  Moon,
  Coffee,
  Book,
  Camera,
  Car,
  Clock,
  Code,
  Compass,
  DollarSign,
  Feather,
  Film,
  Gift,
  Shield,
  Anchor
} from 'lucide-react';

const textColours = {
  red: 'text-red-800',
  green: 'text-green-800',
  blue: 'text-blue-800',
  yellow: 'text-yellow-800',
  purple: 'text-purple-800',
  pink: 'text-pink-800',
  indigo: 'text-indigo-800',
  teal: 'text-teal-800',
  orange: 'text-orange-800',
  cyan: 'text-cyan-800',
  lime: 'text-lime-800',
  amber: 'text-amber-800',
  violet: 'text-violet-800',
  fuchsia: 'text-fuchsia-800',
  rose: 'text-rose-800',
  emerald: 'text-emerald-800',
  slate: 'text-slate-800',
  stone: 'text-stone-800',
  zinc: 'text-zinc-800',
  neutral: 'text-neutral-800',
  brown: 'text-[#8b4513]', // Darker custom color
  olive: 'text-[#6b8e23]', // Darker custom color
  maroon: 'text-[#800000]', // Darker custom color
  gold: 'text-[#b8860b]' // Darker custom color
};

const bgColours = {
  red: 'bg-red-400',
  green: 'bg-green-400',
  blue: 'bg-blue-400',
  yellow: 'bg-yellow-400',
  purple: 'bg-purple-400',
  pink: 'bg-pink-400',
  indigo: 'bg-indigo-400',
  teal: 'bg-teal-400',
  orange: 'bg-orange-400',
  cyan: 'bg-cyan-400',
  lime: 'bg-lime-400',
  amber: 'bg-amber-400',
  violet: 'bg-violet-400',
  fuchsia: 'bg-fuchsia-400',
  rose: 'bg-rose-400',
  emerald: 'bg-emerald-400',
  slate: 'bg-slate-400',
  stone: 'bg-stone-400',
  zinc: 'bg-zinc-400',
  neutral: 'bg-neutral-400',
  brown: 'bg-[#cd853f]', // Lighter custom color
  olive: 'bg-[#9acd32]', // Lighter custom color
  maroon: 'bg-[#e75480]', // Even lighter custom color
  gold: 'bg-[#ffec8b]' // Lighter custom color
};

const renderIcon = (colour, iconName, useColour = true) => {
  const textColourClass = useColour
    ? textColours[colour] || 'text-gray-800'
    : '';
  const strokeWidth = useColour ? 2.5 : 2;
  switch (iconName) {
    case 'Zap':
      return (
        <Zap
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'LogOut':
      return (
        <LogOut
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Home':
      return (
        <Home
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'User':
      return (
        <User
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Settings':
      return (
        <Settings
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Bell':
      return (
        <Bell
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Heart':
      return (
        <Heart
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Star':
      return (
        <Star
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Cloud':
      return (
        <Cloud
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Sun':
      return (
        <Sun
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Moon':
      return (
        <Moon
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Coffee':
      return (
        <Coffee
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Book':
      return (
        <Book
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Camera':
      return (
        <Camera
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Car':
      return (
        <Car
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Clock':
      return (
        <Clock
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Code':
      return (
        <Code
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Compass':
      return (
        <Compass
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'DollarSign':
      return (
        <DollarSign
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Feather':
      return (
        <Feather
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Film':
      return (
        <Film
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Gift':
      return (
        <Gift
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Shield':
      return (
        <Shield
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    case 'Anchor':
      return (
        <Anchor
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
    default:
      return (
        <Zap
          strokeWidth={strokeWidth}
          className={`w-6 h-6 ${textColourClass}`}
        />
      );
  }
};

const iconOptions = [
  'Zap',
  'LogOut',
  'Home',
  'User',
  'Settings',
  'Bell',
  'Heart',
  'Star',
  'Cloud',
  'Sun',
  'Moon',
  'Coffee',
  'Book',
  'Camera',
  'Car',
  'Clock',
  'Code',
  'Compass',
  'DollarSign',
  'Feather',
  'Film',
  'Gift',
  'Shield',
  'Anchor'
];

const colourOptions = [
  'red',
  'green',
  'blue',
  'yellow',
  'purple',
  'pink',
  'indigo',
  'teal',
  'orange',
  'cyan',
  'lime',
  'amber',
  'violet',
  'fuchsia',
  'rose',
  'emerald',
  'slate',
  'stone',
  'zinc',
  'neutral',
  'brown',
  'olive',
  'maroon',
  'gold'
];

export { textColours, bgColours, renderIcon, iconOptions, colourOptions };
