import React, { createContext, useContext, useState, useCallback } from 'react';
import { NotificationItem } from 'core';

const NotificationContext = createContext();

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationProvider'
    );
  }
  return context;
};

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback(notification => {
    setNotifications(prevNotifications => [
      ...prevNotifications,
      { ...notification, id: Date.now() }
    ]);
  }, []);

  const removeNotification = useCallback(id => {
    setNotifications(prevNotifications =>
      prevNotifications.filter(notification => notification.id !== id)
    );
  }, []);

  return (
    <NotificationContext.Provider
      value={{ addNotification, removeNotification }}
    >
      {children}
      <div
        aria-live='assertive'
        className='pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50'
      >
        <div className='flex w-full flex-col items-center space-y-4 sm:items-end'>
          {notifications.map((notification, index) => (
            <NotificationItem
              key={notification.id}
              type={notification.type || 'success'}
              duration={notification.duration || 4000}
              onClose={() => removeNotification(notification.id)}
              title={notification.title}
              description={notification.description}
            />
          ))}
        </div>
      </div>
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
