import BaseModel from './BaseModel';

/**
 * Represents a Tag in the system.
 * @extends BaseModel
 */
class Tag extends BaseModel {
  /** @type {string} The name of the database table for Tags */
  static table = 'tags';

  /** @type {string} The SQL query to select Tag data */
  static selectQuery = '*,category:core_entity_types(*)';

  /**
   * Creates an instance of Tag.
   * @param {Object} data - The tag data.
   */
  constructor(data = {}) {
    super(data);
    this.categoryId = data.category_id || null;
    this.name = data.name || null;
    this.category = data.category || null;
    this.colour = data.metadata?.colour || data.colour || null;
    this.pipelines = data.metadata?.pipelines || data.pipelines || null;
  }

  /**
   * Converts the Tag instance to a database-friendly format.
   * @returns {Object} The tag data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      category_id: this.categoryId,
      name: this.name,
      metadata: { colour: this.colour, pipelines: this.pipelines }
    };
  }
}

export default Tag;
