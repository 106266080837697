import React from 'react';
import { AlertTriangle, AlertCircle, CheckCircle, Info } from 'lucide-react';
/**
 * Alert Component
 *
 * @param {string} props.title - The title of the alert
 * @param {string|React.ReactNode} props.description - The description or content of the alert
 * @param {string} [props.style='info'] - The style of the alert ('warning', 'danger', 'success', 'info')
 * @param {React.ReactNode} [props.actions] - Additional actions or buttons to be rendered in the alert
 * @param {string} [props.className] - Optional additional CSS classes
 * @param {boolean} [props.inline=false] - Whether to display actions inline with the title and description
 * @returns {React.ReactElement} An alert element with the specified properties
 */
const Alert = ({
  title,
  description,
  style = 'info',
  actions,
  className = '',
  inline = false
}) => {
  const styles = {
    warning: {
      bg: 'bg-warning-50',
      icon: 'text-warning-400',
      title: 'text-warning-800',
      description: 'text-warning-700',
      Icon: AlertTriangle
    },
    danger: {
      bg: 'bg-danger-50',
      icon: 'text-danger-400',
      title: 'text-danger-800',
      description: 'text-danger-700',
      Icon: AlertCircle
    },
    success: {
      bg: 'bg-success-50',
      icon: 'text-success-400',
      title: 'text-success-800',
      description: 'text-success-700',
      Icon: CheckCircle
    },
    info: {
      bg: 'bg-info-50',
      icon: 'text-info-400',
      title: 'text-info-800',
      description: 'text-info-700',
      Icon: Info
    }
  };

  const currentStyle = styles[style] || styles.warning;
  const IconComponent = currentStyle.Icon;

  return (
    <div className={`rounded-md ${currentStyle.bg} p-4 ${className}`}>
      <div className={`flex ${inline ? 'items-top justify-between' : ''}`}>
        <div className='flex-shrink-0'>
          <IconComponent
            aria-hidden='true'
            className={`h-5 w-5  ${currentStyle.icon}`}
          />
        </div>
        <div className={`ml-3 ${inline ? 'flex-grow' : ''}`}>
          <div
            className={`${inline ? 'flex items-center justify-between' : ''}`}
          >
            <div>
              <h3 className={`text-sm font-medium ${currentStyle.title}`}>
                {title}
              </h3>
              <div
                className={`${inline ? 'mt-1' : 'mt-2'} text-sm ${
                  currentStyle.description
                }`}
              >
                {typeof description === 'string' ? (
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                ) : (
                  <p>{description}</p>
                )}
              </div>
            </div>
            {inline && actions && (
              <div className='ml-4 flex-shrink-0'>
                {actions.map((action, index) => (
                  <button
                    key={index}
                    onClick={action.onClick}
                    className={`ml-3 text-sm font-medium ${currentStyle.title} hover:${currentStyle.description}`}
                  >
                    {action.text}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {!inline && actions && (
        <div className='ml-8 mt-4'>
          {actions.map((action, index) => (
            <button
              key={index}
              onClick={action.onClick}
              className={`mr-3 text-sm font-medium ${currentStyle.title} hover:${currentStyle.description}`}
            >
              {action.text}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export { Alert };
