/**
 * Enumeration of entity types used throughout the application.
 * Each entity type is assigned a unique numeric identifier.
 *
 **/
export const EntityTypeEnum = {
  AccreditationBody: 1,
  Booking: 2,
  BookingSpace: 3,
  Company: 4,
  Course: 5,
  CourseDelivery: 6,
  CourseOption: 7,
  Industry: 8,
  CourseStatus: 9,
  CompanyStatus: 10,
  CompanyTerms: 11,
  Document: 12,
  DocumentStatus: 13,
  Compliance: 14,
  ComplianceType: 15,
  ComplianceStatus: 16,
  Trainer: 17,
  TrainerStatus: 18,
  Location: 19,
  LocationType: 20,
  LocationStatus: 21,
  ActivityLog: 22,
  ActivityType: 23,
  Filter: 24,
  User: 25,
  AdditionalItem: 26,
  AdditionalItemStatus: 27,
  AdditionalItemType: 28,
  BookingStatus: 29,
  BookingSubStatus: 30,
  BookingSpaceLevyType: 31,
  Task: 32,
  TaskCategory: 33,
  TaskStatus: 34,
  Meeting: 35,
  MeetingCategory: 36,
  MeetingStatus: 37,
  HigherRate: 38,
  AgreedRateStatus: 39,
  Quote: 40,
  QuoteStatus: 41,
  QuoteOption: 42,
  QuoteOptionStatus: 43,
  CourseOptionStatus: 44,
  ContactTypes: 45,
  BookingSpaceStatus: 46,
  AgreedRates: 47,
  Pipelines: 48,
  ImportedCompany: 49,
  ImportedUser: 50,
  Tags: 51,
  TagCategory: 52,
  Invoice: 53,
  InvoiceStatus: 54,
  Automations: 55,
  AutomationRun: 56,
  Settings: 57,
  SettingCategories: 58
};

/**
 * Enum for filter operators used in query building.
 * These operators define how filter conditions are applied to fields.
 * @enum {string}
 */
export const FilterOperatorEnum = {
  /** Equality comparison */
  EQUALS: 'eq',
  /** Not equal comparison */
  NOT_EQUALS: 'neq',
  /** Greater than comparison */
  GREATER_THAN: 'gt',
  /** Greater than or equal to comparison */
  GREATER_THAN_OR_EQUAL: 'gte',
  /** Less than comparison */
  LESS_THAN: 'lt',
  /** Less than or equal to comparison */
  LESS_THAN_OR_EQUAL: 'lte',
  /** Case-insensitive pattern matching */
  ILIKE: 'ilike',
  /** Exact pattern matching */
  LIKE: 'like',
  /** Check if value is in a list */
  IN: 'in',
  /** Check if value is not in a list */
  NOT_IN: 'not.in',
  /** Check if value is  */
  IS: 'is',
  /**Check if a value is not */
  IS_NOT: 'not',
  /**Text search */
  TEXTSEARCH: 'textSearch',
  /**Allows convenient date filtering between values */
  BETWEEN: 'between'
};
