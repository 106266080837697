import React, { useState } from 'react';

import {
  useNotification,
  OutlineButton,
  FilledButton,
  Input,
  Popup
} from 'core';
import { supabase } from '../../../utilities/supabase';

const ChangePasswordPopup = ({ isOpen, onClose }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { addNotification } = useNotification();

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setErrorMessage('New passwords do not match');
      return;
    }

    try {
      const { error } = await supabase.auth.updateUser({
        password: newPassword
      });

      if (error) throw error;

      addNotification({
        type: 'success',
        title: 'Success',
        description: 'Password changed successfully'
      });
      handleClose();
    } catch (error) {
      console.error('Error changing password:', error);
      setErrorMessage(error.message);
    }
  };

  const handleClose = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setErrorMessage('');
    onClose();
  };

  return (
    <Popup isOpen={isOpen} onClose={handleClose} title='Change Password'>
      <div className='space-y-4'>
        <Input
          type='password'
          label='New Password'
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
        />
        <Input
          type='password'
          label='Confirm New Password'
          value={confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
        />
        {errorMessage && <p className='text-red-500 text-sm'>{errorMessage}</p>}
        <div className='flex justify-end gap-x-4'>
          <OutlineButton colour='base' onClick={handleClose}>
            Cancel
          </OutlineButton>
          <FilledButton colour='primary' onClick={handlePasswordChange}>
            Change Password
          </FilledButton>
        </div>
      </div>
    </Popup>
  );
};

export default ChangePasswordPopup;
