import React from 'react';

const ContextMenu = ({ x, y, onClose, menuItems }) => {
  return (
    <div
      className='absolute bg-white shadow-md rounded-md py-2 z-50'
      style={{ top: `${y}px`, left: `${x}px` }}
    >
      {menuItems
        .filter(item => item.visible)
        .map((item, index) => (
          <button
            key={index}
            className='w-full text-left px-4 py-2 hover:bg-gray-100 text-sm flex items-center'
            onClick={() => {
              item.onClick();
              onClose();
            }}
          >
            {item.icon && <span className='mr-2'>{item.icon}</span>}
            {item.label}
          </button>
        ))}
    </div>
  );
};

import { useState, useEffect, useCallback } from 'react';

const useContextMenu = () => {
  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = useCallback((event, data) => {
    event.preventDefault();
    setContextMenu({
      x: event.clientX,
      y: event.clientY,
      data
    });
  }, []);

  const closeContextMenu = useCallback(() => setContextMenu(null), []);

  useEffect(() => {
    const handleClickOutside = event => {
      if (contextMenu && !event.target.closest('.context-menu')) {
        closeContextMenu();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [contextMenu, closeContextMenu]);

  return { contextMenu, handleContextMenu, closeContextMenu };
};

export { ContextMenu, useContextMenu };
