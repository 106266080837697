import React, { useState } from 'react';

const SubTabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <div className='flex border-b border-neutral-200'>
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`pb-2 px-4 font-medium text-sm focus:outline-none ${
              index === activeTab
                ? 'text-brand-700 border-b-2 border-brand-700'
                : 'text-neutral-500 hover:text-brand-700'
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className='mt-4'>{tabs[activeTab].content}</div>
    </>
  );
};

export default SubTabs;
