import Document from '../../../models/Document';
import EntityTable from '../../../components/tables/EntityTable';
import { supabase } from '../../../utilities/supabase';
import { formatDate } from '../../../utilities/Formatting'; // Removed unused import formatProfile
import { Link } from 'react-router-dom';
import { Pencil, Trash, Download, UserRound } from 'lucide-react';
import { useState, useEffect, useContext } from 'react'; // Added useContext import
import FileUploader from '../../../components/popups/files/FileUploader';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';
import { UserProfileContext } from '../../../App';

const Documents = () => {
  //Set the page title
  document.title = 'Documents | CRM | BOHSA LTD';
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const userProfileContext = useContext(UserProfileContext);
  const currentUser = userProfileContext.userProfile;

  const menuItems = [
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => (window.location.href = `/documents/${data.id}`)
    },
    {
      label: 'Download',
      icon: <Download size={14} />,
      onClick: async document => {
        console.log(document);
        try {
          const baseUrl = 'https://storage.googleapis.com/bohsa-docs';
          const filePath = document.filePath; // Assuming filePath contains the path to the file
          const link = window.document.createElement('a');
          link.href = `${baseUrl}/${filePath}`;
          link.download =
            `${document.fileName}` || `document.${document.fileType}`;
          console.log(link);
          window.document.body.appendChild(link);

          // Trigger the download
          link.click();

          // Clean up
          window.document.body.removeChild(link);
        } catch (error) {
          console.error('Error downloading document:', error);
          // Handle error (e.g., show an error message to the user)
        }
      }
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => {
        console.log('Delete clicked', data.owner.id);
        console.log('Current user:', currentUser.id);
      },
      condition: data =>
        data.owner.id === currentUser.id /* || (currentUser.isAdmin)*/
    }
  ];

  const handleNewClick = () => {
    setIsUploadOpen(true);
  };

  const handleUploadSuccess = () => {
    setIsUploadOpen(false);
  };

  return (
    <div className='p-4'>
      <EntityTable
        name='Documents'
        filter={[
          { field: 'id', label: 'ID', type: 'text' },
          {
            field: 'file_name',
            label: 'File Name',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          {
            field: 'owner',
            label: 'Owner',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          { field: 'created_date', label: 'Created Date', type: 'date' },
          { field: 'updated_date', label: 'Updated Date', type: 'date' }
        ]}
        sort={[
          { value: 'id', label: 'Document ID' },
          { value: 'file_name', label: 'File Name' },
          { value: 'created_date', label: 'Date Created' },
          { value: 'updated_date', label: 'Last Modified' }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/documents/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          { label: 'File Name', field: 'fileName' },
          {
            label: 'Owner',
            field: 'owner',
            cell: value => (
              <div className='flex items-center'>
                {value?.profilePhoto ? (
                  <img
                    src={value.profilePhoto}
                    alt={`${value.firstName} ${value.lastName}'s profile`}
                    className='w-8 h-8 rounded-full mr-2'
                  />
                ) : value ? (
                  <div className='w-6 h-6 rounded-full mr-2 bg-neutral-200 flex items-center justify-center'>
                    <UserRound size={16} className='text-white' />
                  </div>
                ) : null}
                <span>
                  {value?.firstName} {value?.lastName}
                </span>
              </div>
            )
          },
          {
            label: 'File Path',
            field: 'filePath'
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        onNewClick={handleNewClick}
        model={Document}
        entityType={EntityTypeEnum.Document}
        canCreate={true}
      />
      {isUploadOpen && (
        <FileUploader
          onClose={() => setIsUploadOpen(false)}
          onUploadSuccess={handleUploadSuccess}
          destinationFolder={'general'}
        />
      )}
    </div>
  );
};

export default Documents;
