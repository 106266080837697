import React, { useEffect, useState } from 'react';
import { Checkbox, FilledButton, Popup, SoftButton } from 'core';
import { Alert } from 'core';
import ImportedUserProfile from '../../../models/ImportedUserProfile';
import { supabase } from '../../../utilities/supabase';
import ImportedCompany from '../../../models/ImportedCompany';

const ConvertCompanyPopup = ({ isOpen, onClose, companyId }) => {
  const [importedUsers, setImportedUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [copyActivityLogs, setCopyActivityLogs] = useState(true);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      const { data: users } = await ImportedUserProfile.getAll({
        company_id: { value: companyId }
      });
      setImportedUsers(users); // Assuming users.data contains the array of users
    };

    const fetchCompanyData = async () => {
      const company = await ImportedCompany.getById(companyId);
      setCompany(company);
    };
    fetchUsers();
    fetchCompanyData();
  }, [companyId]);

  const handleSelectAll = e => {
    if (e.target.checked) {
      setSelectedUsers(importedUsers.map(user => user.id));
    } else {
      setSelectedUsers([]);
    }
  };

  const handleUserSelect = userId => {
    setSelectedUsers(prevSelected =>
      prevSelected.includes(userId)
        ? prevSelected.filter(id => id !== userId)
        : [...prevSelected, userId]
    );
  };

  const handleConvert = async () => {
    const tempCompany = new ImportedCompany(company);
    const tempPayload = tempCompany.toDatabase();
    tempCompany.readOnly = true;
    tempCompany.update();
    try {
      const payload = {
        company: tempPayload,
        users: selectedUsers,
        transfer_logs: copyActivityLogs
      };

      const { data, error } = await supabase.functions.invoke(
        'conversions/company',
        {
          body: payload
        }
      );

      if (error) {
        throw new Error('Conversion failed: ' + error.message);
      }

      if (!response.ok) {
        throw new Error('Conversion failed');
      }

      // Handle successful conversion
      console.log('Conversion successful');
      // You might want to add some user feedback here, like a success message
    } catch (error) {
      console.error('Error during conversion:', error);
      // You might want to add some user feedback here, like an error message
    }
    onClose();
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose} title='Convert Data' size='md'>
      <div>
        <Alert
          title='Conversion Notice'
          description='You are about to convert a record from imported to active. Please verify what information you wish to retain. This process will mark the imported record as read-only. This cannot be undone.'
          style='warning'
        />
        <h3>Conversion Options</h3>
        <Checkbox
          className={'pl-1 ml-1'}
          checked={copyActivityLogs}
          label={'Copy activity logs'}
          description={
            'This will retain a copy of the activity logs in the new live entity.'
          }
          onChange={() => setCopyActivityLogs(!copyActivityLogs)}
        />
        <h3 className='mt-8'>Convert Users</h3>
        <p className='text-sm mb-4'>
          Please select which users you want to import. These will be
          automatically assigned to the newly created company. <i>(optional)</i>
        </p>
        {importedUsers.length > 0 ? (
          <>
            <Checkbox
              className={'ml-1'}
              checked={selectedUsers.length === importedUsers.length}
              label={'Select all'}
              onChange={handleSelectAll}
            />

            <div className='h-full overflow-y-auto pl-1 mt-4'>
              {importedUsers.map(user => (
                <Checkbox
                  key={user.id}
                  checked={selectedUsers.includes(user.id)}
                  label={user.fullName}
                  description={user.email}
                  onChange={() => handleUserSelect(user.id)}
                />
              ))}
            </div>
          </>
        ) : (
          <p>There are no imported users matching this record.</p>
        )}
        <div className='flex space-x-4 justify-end'>
          <SoftButton onClick={onClose}>Cancel</SoftButton>
          <FilledButton onClick={handleConvert}>Convert</FilledButton>
        </div>
      </div>
    </Popup>
  );
};

export default ConvertCompanyPopup;
