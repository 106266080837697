import React, { useState, useEffect } from 'react';
import Booking from '../../../../models/Booking';
import EntityTable from '../../../../components/tables/EntityTable';
import { formatDate } from '../../../../utilities/Formatting';
import { Pencil, SquareArrowOutUpRight, Trash, Plus } from 'lucide-react';
import { EntityTypeEnum } from '../../../../utilities/Enumerables';
import { Link } from 'react-router-dom';
import { FilledButton } from 'core';
import CreateBooking from '../../../../components/popups/invoices/Booking';
import CoreEntityType from '../../../../models/CoreEntityType';

const Bookings = ({ invoiceId }) => {
  const [isCreateBookingOpen, setIsCreateBookingOpen] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    const loadStatusOptions = async () => {
      try {
        const { data: options } = await CoreEntityType.getByEntityId(
          EntityTypeEnum.Invoice
        );
        setStatusOptions(options);
      } catch (error) {
        console.error('Error loading status options:', error);
      }
    };

    loadStatusOptions();
  }, []);

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/bookings/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => (window.location.href = `/bookings/${data.id}`)
    },
    {
      label: 'Remove from invoice',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: async data => {
        try {
          const booking = await Booking.getById(data.id);
          await booking.removeBookingFromInvoice();
        } catch (error) {
          console.error('Error removing booking from invoice:', error);
        }
      }
    }
  ];

  const filterConfig = [
    { field: 'id', label: 'ID', type: 'number' },
    { field: 'company.name', label: 'Company', type: 'text' },
    { field: 'course.name', label: 'Course', type: 'text' },
    { field: 'trainer.name', label: 'Trainer', type: 'text' },
    { field: 'booking_date', label: 'Booking Date', type: 'date' },
    {
      field: 'status',
      label: 'Status',
      type: 'select',
      options: statusOptions ?? []
    },
    { field: 'spaces', label: 'Spaces', type: 'number' }
  ];

  return (
    <>
      <EntityTable
        name='Bookings'
        filter={filterConfig}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/bookings/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          {
            label: 'Company',
            field: 'company.name',
            foreignKey: { table: 'companies', column: 'name' }
          },
          {
            label: 'Course',
            field: 'course.name',
            foreignKey: { table: 'courses', column: 'name' }
          },
          {
            label: 'Trainer',
            field: 'trainer.name',
            foreignKey: { table: 'trainers', column: 'name' }
          },
          {
            label: 'Delivery',
            field: 'deliveryType.name',
            foreignKey: { table: 'delivery_type', column: 'type' }
          },
          {
            label: 'Booking Date',
            field: 'bookingDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Status',
            field: 'status.name',
            foreignKey: { table: 'status', column: 'type' }
          },
          { label: 'Spaces', field: 'spaces' }
        ]}
        menuItems={menuItems}
        model={Booking}
        entityType={EntityTypeEnum.Booking}
        defaultFilters={{ invoice_id: { value: invoiceId } }}
        canCreate={false}
      />
    </>
  );
};

export default Bookings;
