import React, { useState, useEffect } from 'react';
import { FilledButton, SoftButton, Badge, Input, DropdownInput } from 'core';
import {
  Plus,
  X,
  Zap,
  LogOut,
  RotateCw,
  Home,
  User,
  Settings,
  Bell,
  Heart,
  Star,
  Cloud,
  Sun,
  Moon,
  Coffee,
  Book,
  Camera,
  Car,
  Clock,
  Code,
  Compass,
  DollarSign,
  Feather,
  Film,
  Gift,
  ChevronRight
} from 'lucide-react';
import { Disclosure } from '@headlessui/react';
import Automation from '../../../models/Automations';
import { supabase } from '../../../utilities/supabase';

const Create = ({
  onClose,
  entityId,
  entityType,
  edit,
  setEditAuto,
  setIsCreating,
  automations,
  setAutomations
}) => {
  const createEmptyRequest = (index, type) => ({
    name: `${type} Request ${index + 1}`,
    method: 'GET',
    url: '',
    headers: [{ key: 'Content-Type', value: 'application/json' }],
    body: ''
  });

  useEffect(() => {
    if (edit) handleEditAutomation(edit);
  }, [edit]);

  const [id, setId] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [icon, setIcon] = useState('Zap');
  const [colour, setColour] = useState('blue');
  const [sendRequests, setSendRequests] = useState([
    createEmptyRequest(0, 'Send')
  ]);
  const [exitRequests, setExitRequests] = useState([
    createEmptyRequest(0, 'Exit')
  ]);
  const [sendResponses, setSendResponses] = useState([]);
  const [exitResponses, setExitResponses] = useState([]);
  const [exitVariables, setExitVariables] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const handleEditAutomation = automation => {
    setId(automation.id);
    setName(automation.name);
    setDescription(automation.description || '');
    setIcon(automation.metadata?.icon || 'Zap');
    setColour(automation.metadata?.colour || 'brand-600');
    setSendRequests(
      automation.send
        ? automation.send.map((request, index) => ({
            ...request,
            name: request.name || `Send Request ${index + 1}`,
            headers: Array.isArray(request.headers)
              ? request.headers
              : Object.entries(request.headers).map(([key, value]) => ({
                  key,
                  value
                })),
            body: request.body || ''
          }))
        : [createEmptyRequest(0, 'Send')]
    );
    setExitRequests(
      automation.exit
        ? automation.exit.map((request, index) => ({
            ...request,
            name: request.name || `Exit Request ${index + 1}`,
            headers: Array.isArray(request.headers)
              ? request.headers
              : Object.entries(request.headers).map(([key, value]) => ({
                  key,
                  value
                })),
            body: request.body || ''
          }))
        : [createEmptyRequest(0, 'Exit')]
    );
    setEditAuto(automation);
    setIsCreating(true);
  };

  const handleNameChange = e => setName(e.target.value);
  const handleDescriptionChange = e => setDescription(e.target.value);
  const handleIconChange = icon => setIcon(icon);
  const handleColourChange = colour => setColour(colour);

  const handleRequestChange = (type, index, field, value) => {
    const requests = type === 'send' ? [...sendRequests] : [...exitRequests];
    requests[index][field] = value;
    type === 'send' ? setSendRequests(requests) : setExitRequests(requests);
  };

  const handleHeaderChange = (
    type,
    requestIndex,
    headerIndex,
    field,
    value
  ) => {
    const requests = type === 'send' ? [...sendRequests] : [...exitRequests];
    requests[requestIndex].headers[headerIndex][field] = value;
    type === 'send' ? setSendRequests(requests) : setExitRequests(requests);
  };

  const addHeader = (type, requestIndex) => {
    const requests = type === 'send' ? [...sendRequests] : [...exitRequests];
    requests[requestIndex].headers.push({ key: '', value: '' });
    type === 'send' ? setSendRequests(requests) : setExitRequests(requests);
  };

  const removeHeader = (type, requestIndex, headerIndex) => {
    const requests = type === 'send' ? [...sendRequests] : [...exitRequests];
    requests[requestIndex].headers = requests[requestIndex].headers.filter(
      (_, i) => i !== headerIndex
    );
    type === 'send' ? setSendRequests(requests) : setExitRequests(requests);
  };

  const addRequest = type => {
    const newRequest = createEmptyRequest(
      type === 'send' ? sendRequests.length : exitRequests.length,
      type === 'send' ? 'Send' : 'Exit'
    );
    type === 'send'
      ? setSendRequests([...sendRequests, newRequest])
      : setExitRequests([...exitRequests, newRequest]);
  };

  const removeRequest = (type, index) => {
    const requests = type === 'send' ? [...sendRequests] : [...exitRequests];
    const updatedRequests = requests.filter((_, i) => i !== index);
    type === 'send'
      ? setSendRequests(updatedRequests)
      : setExitRequests(updatedRequests);
  };

  const handleCreateAutomation = async () => {
    try {
      const automationData = {
        name,
        description,
        entity: entityType,
        metadata: {
          icon,
          colour
        },
        trigger: { id: 221 },
        send: sendRequests.map(request => ({
          name: request.name,
          method: request.method,
          url: request.url,
          headers: request.headers.reduce((acc, header) => {
            if (header.key && header.value) {
              acc[header.key] = header.value;
            }
            return acc;
          }, {}),
          body: request.body
        })),
        exit: exitRequests.map(request => ({
          name: request.name,
          method: request.method,
          url: request.url,
          headers: request.headers.reduce((acc, header) => {
            if (header.key && header.value) {
              acc[header.key] = header.value;
            }
            return acc;
          }, {}),
          body: request.body
        })),
        updated_date: new Date(),
        created_date: new Date()
      };

      let savedAutomation;
      if (edit) {
        const automation = new Automation({
          ...edit,
          ...automationData
        });
        savedAutomation = await automation.update();
        setAutomations(
          automations.map(a =>
            a.id === savedAutomation.id ? savedAutomation : a
          )
        );
      } else {
        const automation = new Automation(automationData);
        savedAutomation = await automation.insert();
        setAutomations([...automations, savedAutomation]);
      }

      setIsCreating(false);
      setEditAuto(null);
    } catch (error) {
      console.error('Error creating or updating automation:', error);
    }
  };

  const handleTestConnection = async (type, index) => {
    try {
      const requestData = {
        entityType,
        entityId,
        method:
          type === 'send'
            ? sendRequests[index].method
            : exitRequests[index].method,
        url:
          type === 'send' ? sendRequests[index].url : exitRequests[index].url,
        headers: (type === 'send'
          ? sendRequests[index].headers
          : exitRequests[index].headers
        ).reduce((acc, header) => {
          if (header.key && header.value) {
            acc[header.key] = header.value;
          }
          return acc;
        }, {}),
        body:
          type === 'send' ? sendRequests[index].body : exitRequests[index].body
      };

      const { data, error } = await supabase.functions.invoke('connection', {
        body: JSON.stringify(requestData)
      });

      if (error) {
        throw new Error('Connection test failed');
      }

      if (type === 'send') {
        setSendResponses(prevResponses => {
          const newResponses = [...prevResponses];
          newResponses[index] = data;
          return newResponses;
        });

        generateExitVariables(data, index);
      } else {
        setExitResponses(prevResponses => {
          const newResponses = [...prevResponses];
          newResponses[index] = data;
          return newResponses;
        });
      }
    } catch (error) {
      console.error('Error testing connection:', error);
      if (type === 'send') {
        setSendResponses(prevResponses => {
          const newResponses = [...prevResponses];
          newResponses[index] = null;
          return newResponses;
        });
      } else {
        setExitResponses(prevResponses => {
          const newResponses = [...prevResponses];
          newResponses[index] = null;
          return newResponses;
        });
      }
    }
  };

  const generateExitVariables = (response, requestIndex) => {
    const variables = [];

    for (const header in response.headers) {
      variables.push({
        variable: `{{${requestIndex}.headers.${header}}}`,
        value: response.headers[header]
      });
    }

    variables.push({
      variable: `{{${requestIndex}.status}}`,
      value: response.status
    });

    variables.push({
      variable: `{{${requestIndex}.body.text}}`,
      value: response.body
    });
    if (response.headers['content-type']?.includes('application/json')) {
      try {
        const parsedBody = JSON.parse(response.body);
        const processObject = (obj, prefix = `${requestIndex}.body.json.`) => {
          for (const [key, value] of Object.entries(obj)) {
            if (Array.isArray(value)) {
              value.forEach((item, index) => {
                if (typeof item === 'object' && item !== null) {
                  processObject(item, `${prefix}${key}[${index}].`);
                } else {
                  variables.push({
                    variable: `{{${prefix}${key}[${index}]}}`,
                    value: item
                  });
                }
              });
            } else if (typeof value === 'object' && value !== null) {
              processObject(value, `${prefix}${key}.`);
            } else {
              variables.push({
                variable: `{{${prefix}${key}}}`,
                value: value
              });
            }
          }
        };
        processObject(parsedBody);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }

    setExitVariables(prevVariables => {
      const updatedVariables = [...prevVariables];
      updatedVariables[requestIndex] = variables;
      return updatedVariables;
    });
  };

  const textColours = {
    red: 'text-red-800',
    green: 'text-green-800',
    blue: 'text-blue-800',
    yellow: 'text-yellow-800',
    purple: 'text-purple-800',
    pink: 'text-pink-800',
    indigo: 'text-indigo-800',
    teal: 'text-teal-800',
    orange: 'text-orange-800',
    cyan: 'text-cyan-800',
    lime: 'text-lime-800',
    amber: 'text-amber-800',
    violet: 'text-violet-800',
    fuchsia: 'text-fuchsia-800',
    rose: 'text-rose-800',
    emerald: 'text-emerald-800',
    slate: 'text-slate-800',
    stone: 'text-stone-800',
    zinc: 'text-zinc-800',
    neutral: 'text-neutral-800'
  };

  const bgColours = {
    red: 'bg-red-400',
    green: 'bg-green-400',
    blue: 'bg-blue-400',
    yellow: 'bg-yellow-400',
    purple: 'bg-purple-400',
    pink: 'bg-pink-400',
    indigo: 'bg-indigo-400',
    teal: 'bg-teal-400',
    orange: 'bg-orange-400',
    cyan: 'bg-cyan-400',
    lime: 'bg-lime-400',
    amber: 'bg-amber-400',
    violet: 'bg-violet-400',
    fuchsia: 'bg-fuchsia-400',
    rose: 'bg-rose-400',
    emerald: 'bg-emerald-400',
    slate: 'bg-slate-400',
    stone: 'bg-stone-400',
    zinc: 'bg-zinc-400',
    neutral: 'bg-neutral-400'
  };

  const renderIcon = (iconName, useColour = true) => {
    const textColourClass = useColour
      ? textColours[colour] || 'text-gray-800'
      : '';
    const strokeWidth = useColour ? 2.5 : 2;
    switch (iconName) {
      case 'Zap':
        return (
          <Zap
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'LogOut':
        return (
          <LogOut
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Home':
        return (
          <Home
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'User':
        return (
          <User
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Settings':
        return (
          <Settings
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Bell':
        return (
          <Bell
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Heart':
        return (
          <Heart
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Star':
        return (
          <Star
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Cloud':
        return (
          <Cloud
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Sun':
        return (
          <Sun
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Moon':
        return (
          <Moon
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Coffee':
        return (
          <Coffee
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Book':
        return (
          <Book
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Camera':
        return (
          <Camera
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Car':
        return (
          <Car
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Clock':
        return (
          <Clock
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Code':
        return (
          <Code
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Compass':
        return (
          <Compass
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'DollarSign':
        return (
          <DollarSign
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Feather':
        return (
          <Feather
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Film':
        return (
          <Film
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      case 'Gift':
        return (
          <Gift
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
      default:
        return (
          <Zap
            strokeWidth={strokeWidth}
            className={`w-6 h-6 ${textColourClass}`}
          />
        );
    }
  };

  const iconOptions = [
    'Zap',
    'LogOut',
    'Home',
    'User',
    'Settings',
    'Bell',
    'Heart',
    'Star',
    'Cloud',
    'Sun',
    'Moon',
    'Coffee',
    'Book',
    'Camera',
    'Car',
    'Clock',
    'Code',
    'Compass',
    'DollarSign',
    'Feather',
    'Film',
    'Gift'
  ];

  const colourOptions = [
    'red',
    'green',
    'blue',
    'yellow',
    'purple',
    'pink',
    'indigo',
    'teal',
    'orange',
    'cyan',
    'lime',
    'amber',
    'violet',
    'fuchsia',
    'rose',
    'emerald',
    'slate',
    'stone',
    'zinc',
    'neutral'
  ];

  const renderPageOne = () => (
    <div className='overflow-y-auto pr-4'>
      <div className='flex items-center justify-between mb-5'>
        <div className='flex items-center'>
          <div
            className={`w-12 h-12 flex items-center justify-center rounded-lg transition-colors duration-300 ${bgColours[colour]} ${textColours[colour]}`}
          >
            {renderIcon(icon)}
          </div>
          <div>
            <span className='text-lg font-semibold ml-3'>{name}</span>
            <p className='text-sm ml-3 text-gray-600'>{description}</p>
          </div>
        </div>
        <div className='mt-1'>
          <Badge colour='brand' size='lg'>
            On Select
          </Badge>
        </div>
      </div>
      <div className='mb-4'>
        <Input
          label='Trigger Name'
          type='text'
          value={name}
          onChange={handleNameChange}
          placeholder='Trigger Name'
        />
      </div>
      <div className='mb-4'>
        <label className='block text-sm font-medium leading-6 text-gray-900'>
          Description
        </label>
        <textarea
          className='w-full p-2 border border-gray-300 rounded-md'
          value={description}
          onChange={handleDescriptionChange}
          placeholder='Enter a description for the automation...'
          rows={4}
        />
      </div>
      <div className='mb-4'>
        <label className='block text-sm font-medium leading-6 text-gray-900'>
          Icon
        </label>
        <div className='flex gap-2'>
          {iconOptions.map(option => (
            <div
              key={option}
              className={`w-10 h-10 flex items-center justify-center rounded-lg cursor-pointer transition-colors duration-300 ${
                icon === option ? 'bg-brand-700 text-white' : 'bg-gray-200'
              }`}
              onClick={() => handleIconChange(option)}
            >
              {renderIcon(option, false)}
            </div>
          ))}
        </div>
      </div>
      <div className='mb-4'>
        <label className='block text-sm font-medium leading-6 text-gray-900'>
          Colour
        </label>
        <div className='flex gap-2'>
          {colourOptions.map(option => (
            <div
              key={option}
              className={`w-10 h-10 rounded-lg cursor-pointer transition-colors duration-300 ${
                colour === option ? 'border-4 border-brand-700' : ''
              } ${bgColours[option]}`}
              onClick={() => handleColourChange(option)}
            />
          ))}
        </div>
      </div>
      <div
        className={`flex ${
          edit ? 'justify-between' : 'justify-end'
        } items-center space-x-4 mt-10`}
      >
        {edit && (
          <SoftButton
            colour='danger'
            onClick={async () => {
              try {
                const { error } = await supabase
                  .from('automations')
                  .delete()
                  .eq('id', id);

                if (error) {
                  throw error;
                }

                setAutomations(prevAutomations =>
                  prevAutomations.filter(
                    automation => automation.id !== edit.id
                  )
                );

                console.log('Delete action successful');
              } catch (error) {
                console.error('Error deleting automation:', error);
              } finally {
                setIsCreating(false);
              }
            }}
          >
            Delete
          </SoftButton>
        )}
        <div className='flex space-x-4'>
          <SoftButton
            onClick={() => {
              setEditAuto(null);
              setIsCreating(false);
              setId(null);
              setName('');
              setDescription('');
              setIcon('Zap');
              setColour('blue');
              setSendRequests([createEmptyRequest(0, 'Send')]);
              setExitRequests([createEmptyRequest(0, 'Exit')]);
              setSendResponses([]);
              setExitResponses([]);
              setExitVariables([]);
            }}
          >
            Cancel
          </SoftButton>
          <FilledButton onClick={() => setCurrentPage(2)}>Next</FilledButton>
        </div>
      </div>
    </div>
  );

  const renderPageTwo = () => (
    <div>
      <div className='grid grid-cols-2 gap-4 h-[56vh]'>
        <div className='overflow-y-auto pr-4'>
          <div className='flex items-center justify-between mb-5'>
            <div className='flex items-center'>
              <div
                className={`w-12 h-12 flex items-center justify-center rounded-lg transition-colors duration-300 ${bgColours[colour]} ${textColours[colour]}`}
              >
                {renderIcon(icon)}
              </div>
              <div>
                <span className='text-lg font-semibold ml-3'>Send</span>
                <p className='text-sm ml-3 text-gray-600'>
                  Triggered when the entity is updated
                </p>
              </div>
            </div>
            <div className='mt-1'>
              <Badge colour='brand' size='lg'>
                On Select
              </Badge>
            </div>
          </div>
          <h3 className='text-lg font-semibold'>Requests</h3>
          {sendRequests.map((request, index) => (
            <Disclosure key={index}>
              {({ open }) => (
                <>
                  <div className='flex items-center mb-2'>
                    <Disclosure.Button
                      className='cursor-pointer text-left mr-2'
                      onClick={() =>
                        handleRequestChange('send', index, 'open', !open)
                      }
                    >
                      <ChevronRight
                        className={open ? 'rotate-90' : ''}
                      ></ChevronRight>
                    </Disclosure.Button>
                    <Input
                      type='text'
                      value={request.name}
                      onChange={e =>
                        handleRequestChange(
                          'send',
                          index,
                          'name',
                          e.target.value
                        )
                      }
                      className='flex-grow'
                    />
                  </div>
                  <Disclosure.Panel>
                    <div className='mb-4'>
                      <DropdownInput
                        label='Method'
                        value={request.method}
                        onChange={e =>
                          handleRequestChange(
                            'send',
                            index,
                            'method',
                            e.target.value
                          )
                        }
                        options={[
                          { value: 'GET', label: 'GET' },
                          { value: 'POST', label: 'POST' },
                          { value: 'PATCH', label: 'PATCH' },
                          { value: 'DELETE', label: 'DELETE' },
                          { value: 'PUT', label: 'PUT' }
                        ]}
                      />
                    </div>
                    <div className='mb-4'>
                      <Input
                        label='URL'
                        type='text'
                        value={request.url}
                        onChange={e =>
                          handleRequestChange(
                            'send',
                            index,
                            'url',
                            e.target.value
                          )
                        }
                        placeholder='https://api.example.com/endpoint'
                      />
                    </div>
                    <div className='mb-4'>
                      <label className='block text-sm font-medium leading-6 text-gray-900 mb-2'>
                        Headers
                      </label>
                      {request.headers.map((header, headerIndex) => (
                        <div key={headerIndex} className='flex gap-2 mb-2'>
                          <Input
                            type='text'
                            value={header.key}
                            onChange={e =>
                              handleHeaderChange(
                                'send',
                                index,
                                headerIndex,
                                'key',
                                e.target.value
                              )
                            }
                            placeholder='Key'
                          />
                          <Input
                            type='text'
                            value={header.value}
                            onChange={e =>
                              handleHeaderChange(
                                'send',
                                index,
                                headerIndex,
                                'value',
                                e.target.value
                              )
                            }
                            placeholder='Value'
                            className='mr-2'
                          />
                          <SoftButton
                            colour='primary'
                            onClick={() =>
                              removeHeader('send', index, headerIndex)
                            }
                          >
                            <X size={16} />
                          </SoftButton>
                        </div>
                      ))}
                      <SoftButton
                        leftIcon={<Plus size={16} />}
                        onClick={() => addHeader('send', index)}
                        className='mt-2'
                      >
                        Add Header
                      </SoftButton>
                    </div>
                    {request.method !== 'GET' && (
                      <div className='mb-4'>
                        <label className='block text-sm font-medium leading-6 text-gray-900 mb-2'>
                          Body
                        </label>
                        <textarea
                          className='w-full p-2 border border-gray-300 rounded-md'
                          value={request.body}
                          onChange={e =>
                            handleRequestChange(
                              'send',
                              index,
                              'body',
                              e.target.value
                            )
                          }
                          placeholder='Enter request body...'
                          rows={4}
                        />
                      </div>
                    )}
                    <div className='mb-4'>
                      <div className='flex flex-col'>
                        <div className='flex items-center justify-between mb-2'>
                          <SoftButton
                            onClick={() => handleTestConnection('send', index)}
                            leftIcon={<RotateCw></RotateCw>}
                            className='mr-2'
                          >
                            Test Request
                          </SoftButton>
                          {sendResponses[index] ? (
                            <div className='flex items-center'>
                              <span className='mr-2 text-sm'>Status:</span>
                              <div className='flex items-center'>
                                {sendResponses[index].status >= 200 &&
                                sendResponses[index].status < 300 ? (
                                  <div className='w-4 h-4 rounded-full bg-green-500 mr-2'></div>
                                ) : (
                                  <div className='w-4 h-4 rounded-full bg-red-500 mr-2'></div>
                                )}
                                <span className='mr-1'>
                                  {sendResponses[index].status}
                                </span>
                                <span>({sendResponses[index].statusText})</span>
                              </div>
                            </div>
                          ) : (
                            <span className='text-sm'>
                              Click to test your request
                            </span>
                          )}
                        </div>
                        {sendResponses[index] && (
                          <Disclosure>
                            {({ open }) => (
                              <>
                                <div className='flex justify-end'>
                                  <Disclosure.Button className='cursor-pointer text-sm flex items-center'>
                                    <span className='mr-1'>
                                      <ChevronRight
                                        className={open ? 'rotate-90' : ''}
                                      />
                                    </span>
                                    Response
                                  </Disclosure.Button>
                                </div>

                                <Disclosure.Panel>
                                  <div className='mt-2'>
                                    <h5 className='text-sm mb-2'>Headers</h5>
                                    <div className='bg-gray-100 p-4 rounded-md overflow-auto max-h-40'>
                                      <pre className='text-sm mb-4'>
                                        {JSON.stringify(
                                          sendResponses[index].headers,
                                          null,
                                          2
                                        )}
                                      </pre>
                                    </div>
                                    <h5 className='text-sm mt-4 mb-2'>Body</h5>
                                    <div className='bg-gray-100 p-4 rounded-md overflow-auto max-h-40'>
                                      <pre className='text-sm'>
                                        {sendResponses[index].headers[
                                          'content-type'
                                        ] === 'application/json'
                                          ? (() => {
                                              try {
                                                return JSON.stringify(
                                                  JSON.parse(
                                                    sendResponses[index].body ||
                                                      '{}'
                                                  ),
                                                  null,
                                                  2
                                                );
                                              } catch (error) {
                                                console.error(
                                                  'JSON parse error:',
                                                  error
                                                );
                                                return sendResponses[index]
                                                  .body;
                                              }
                                            })()
                                          : sendResponses[index].body}
                                      </pre>
                                    </div>
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        )}
                      </div>
                    </div>
                    <SoftButton
                      colour='danger'
                      onClick={() => removeRequest('send', index)}
                      className='mt-2'
                    >
                      Remove Request
                    </SoftButton>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
          <SoftButton
            leftIcon={<Plus size={16} />}
            onClick={() => addRequest('send')}
            className='mt-4'
          >
            Add Send Request
          </SoftButton>
        </div>
        <div className='overflow-y-auto pl-2'>
          <div className='flex items-center justify-between mb-5'>
            <div className='flex items-center'>
              <div
                className={`w-12 h-12 flex items-center justify-center rounded-lg transition-colors duration-300 ${bgColours[colour]} ${textColours[colour]}`}
              >
                <LogOut
                  strokeWidth={2.5}
                  className={`w-6 h-6 ${textColours[colour]}`}
                />
              </div>
              <div>
                <span className='text-lg font-semibold ml-3'>Exit</span>
                <p className='text-sm ml-3 text-gray-600'>
                  Triggered when the flow is removed
                </p>
              </div>
            </div>
            <div className='mt-1'>
              <Badge colour='brand' size='lg'>
                On Remove
              </Badge>
            </div>
          </div>
          <div className='mb-8'>
            {exitVariables[0] && (
              <>
                <h3 className='text-lg font-semibold'>Variables</h3>
                {exitVariables.map((variables, index) => (
                  <Disclosure key={index}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className='cursor-pointer mb-2 flex items-center'>
                          <span className='mr-1'>
                            <ChevronRight className={open ? 'rotate-90' : ''} />
                          </span>
                          {sendRequests[index]?.name ||
                            `Send Request ${index + 1}`}{' '}
                          Variables
                        </Disclosure.Button>
                        <Disclosure.Panel>
                          <div className='bg-gray-100 p-4 rounded-md space-y-2 cursor-pointer'>
                            {variables.map(variable => (
                              <div className='space-x-2'>
                                <Badge
                                  key={variable.variable}
                                  colour='brand'
                                  size='sm'
                                  onClick={() =>
                                    navigator.clipboard.writeText(
                                      variable.variable
                                    )
                                  }
                                >
                                  {variable.variable}
                                </Badge>
                                <span className='text-sm'>
                                  ({variable.value})
                                </span>
                              </div>
                            ))}
                            {variables.length === 0 && (
                              <span className='text-sm'>
                                No variables available
                              </span>
                            )}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </>
            )}
          </div>
          <h3 className='text-lg font-semibold'>Requests</h3>
          {exitRequests.map((request, index) => (
            <Disclosure key={index}>
              {({ open }) => (
                <>
                  <div className='flex items-center mb-2'>
                    <Disclosure.Button
                      className='cursor-pointer text-center mr-2 flex items-center'
                      onClick={() =>
                        handleRequestChange('exit', index, 'open', !open)
                      }
                    >
                      <span className='mr-1'>
                        <ChevronRight className={open ? 'rotate-90' : ''} />
                      </span>
                    </Disclosure.Button>
                    <Input
                      type='text'
                      value={request.name}
                      onChange={e =>
                        handleRequestChange(
                          'exit',
                          index,
                          'name',
                          e.target.value
                        )
                      }
                      placeholder={`Exit Request ${index + 1}`}
                      className='flex-grow'
                    />
                  </div>
                  <Disclosure.Panel>
                    <div className='mb-4'>
                      <DropdownInput
                        label='Method'
                        value={request.method}
                        onChange={e =>
                          handleRequestChange(
                            'exit',
                            index,
                            'method',
                            e.target.value
                          )
                        }
                        options={[
                          { value: 'GET', label: 'GET' },
                          { value: 'POST', label: 'POST' },
                          { value: 'PATCH', label: 'PATCH' },
                          { value: 'DELETE', label: 'DELETE' },
                          { value: 'PUT', label: 'PUT' }
                        ]}
                      />
                    </div>
                    <div className='mb-4'>
                      <Input
                        label='URL'
                        type='text'
                        value={request.url}
                        onChange={e =>
                          handleRequestChange(
                            'exit',
                            index,
                            'url',
                            e.target.value
                          )
                        }
                        placeholder='https://api.example.com/endpoint'
                      />
                    </div>
                    <div className='mb-4'>
                      <label className='block text-sm font-medium leading-6 text-gray-900 mb-2'>
                        Headers
                      </label>
                      {request.headers.map((header, headerIndex) => (
                        <div key={headerIndex} className='flex gap-2 mb-2'>
                          <Input
                            type='text'
                            value={header.key}
                            onChange={e =>
                              handleHeaderChange(
                                'exit',
                                index,
                                headerIndex,
                                'key',
                                e.target.value
                              )
                            }
                            placeholder='Key'
                          />
                          <Input
                            type='text'
                            value={header.value}
                            onChange={e =>
                              handleHeaderChange(
                                'exit',
                                index,
                                headerIndex,
                                'value',
                                e.target.value
                              )
                            }
                            placeholder='Value'
                            className='mr-2'
                          />
                          <SoftButton
                            colour='primary'
                            onClick={() =>
                              removeHeader('exit', index, headerIndex)
                            }
                          >
                            <X size={16} />
                          </SoftButton>
                        </div>
                      ))}
                      <SoftButton
                        leftIcon={<Plus size={16} />}
                        onClick={() => addHeader('exit', index)}
                        className='mt-2'
                      >
                        Add Header
                      </SoftButton>
                    </div>
                    {request.method !== 'GET' && (
                      <div className='mb-4'>
                        <label className='block text-sm font-medium leading-6 text-gray-900 mb-2'>
                          Body
                        </label>
                        <textarea
                          className='w-full p-2 border border-gray-300 rounded-md'
                          value={request.body}
                          onChange={e =>
                            handleRequestChange(
                              'exit',
                              index,
                              'body',
                              e.target.value
                            )
                          }
                          placeholder='Enter request body...'
                          rows={4}
                        />
                      </div>
                    )}
                    <div className='mb-4'>
                      <div className='flex flex-col'>
                        <div className='flex items-center justify-between mb-2'>
                          <SoftButton
                            onClick={() => handleTestConnection('exit', index)}
                            leftIcon={<RotateCw></RotateCw>}
                            className='mr-2'
                          >
                            Test Request
                          </SoftButton>
                          {exitResponses[index] ? (
                            <div className='flex items-center'>
                              <span className='mr-2 text-sm'>Status:</span>
                              <div className='flex items-center'>
                                {exitResponses[index].status >= 200 &&
                                exitResponses[index].status < 300 ? (
                                  <div className='w-4 h-4 rounded-full bg-green-500 mr-2'></div>
                                ) : (
                                  <div className='w-4 h-4 rounded-full bg-red-500 mr-2'></div>
                                )}
                                <span className='mr-1'>
                                  {exitResponses[index].status}
                                </span>
                                <span>({exitResponses[index].statusText})</span>
                              </div>
                            </div>
                          ) : (
                            <span className='text-sm'>
                              Click to test your request
                            </span>
                          )}
                        </div>
                        {exitResponses[index] && (
                          <Disclosure>
                            {({ open }) => (
                              <>
                                <div className='flex justify-end'>
                                  <Disclosure.Button className='cursor-pointer text-sm text-right flex items-center'>
                                    <span className='mr-1'>
                                      <ChevronRight
                                        className={open ? 'rotate-90' : ''}
                                      />
                                    </span>
                                    Response
                                  </Disclosure.Button>
                                </div>
                                <Disclosure.Panel>
                                  <div className='mt-2'>
                                    <h5 className='text-sm mb-2'>Headers</h5>
                                    <div className='bg-gray-100 p-4 rounded-md overflow-auto max-h-40'>
                                      <pre className='text-sm mb-4'>
                                        {JSON.stringify(
                                          exitResponses[index].headers,
                                          null,
                                          2
                                        )}
                                      </pre>
                                    </div>
                                    <h5 className='text-sm mt-4 mb-2'>Body</h5>
                                    <div className='bg-gray-100 p-4 rounded-md overflow-auto max-h-40'>
                                      <pre className='text-sm'>
                                        {exitResponses[index].headers[
                                          'content-type'
                                        ] === 'application/json'
                                          ? (() => {
                                              try {
                                                return JSON.stringify(
                                                  JSON.parse(
                                                    exitResponses[index].body ||
                                                      '{}'
                                                  ),
                                                  null,
                                                  2
                                                );
                                              } catch (error) {
                                                console.error(
                                                  'JSON parse error:',
                                                  error
                                                );
                                                return exitResponses[index]
                                                  .body;
                                              }
                                            })()
                                          : exitResponses[index].body}
                                      </pre>
                                    </div>
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        )}
                      </div>
                    </div>
                    <SoftButton
                      colour='danger'
                      onClick={() => removeRequest('exit', index)}
                      className='mt-2'
                    >
                      Remove Request
                    </SoftButton>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
          <SoftButton
            leftIcon={<Plus size={16} />}
            onClick={() => addRequest('exit')}
            className='mt-4'
          >
            Add Exit Request
          </SoftButton>
        </div>
      </div>
      <div className='flex justify-end items-center space-x-4 mt-4'>
        <SoftButton onClick={() => setCurrentPage(1)}>Back</SoftButton>
        <FilledButton onClick={handleCreateAutomation}>
          {edit ? 'Update Automation' : 'Create Automation'}
        </FilledButton>
      </div>
    </div>
  );

  return currentPage === 1 ? renderPageOne() : renderPageTwo();
};

export default Create;
