import React, { useState, useEffect } from 'react';
import { supabase } from '../../utilities/supabase';
import { Lock, Plus } from 'lucide-react';
import { FilledButton, TextButton, useNotification, Popup } from 'core';
import ChangePasswordPopup from '../../components/popups/account/ChangePassword';
import MFAPopup from '../../components/popups/account/2FA';

const Security = () => {
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showAddDeviceModal, setShowAddDeviceModal] = useState(false);
  const [showRemoveDeviceModal, setShowRemoveDeviceModal] = useState(false);
  const [tfaDevices, setTfaDevices] = useState([]);
  const [deviceToRemove, setDeviceToRemove] = useState(null);
  const { addNotification } = useNotification();

  const fetchTwoFactorStatus = async () => {
    try {
      const { data: factors, error } = await supabase.auth.mfa.listFactors();
      if (error) throw error;
      setTfaDevices(factors?.totp || []);
    } catch (error) {
      console.error('Error checking 2FA status:', error);
      addNotification({
        type: 'error',
        title: 'Error',
        description: 'Failed to check two-factor authentication status'
      });
    }
  };

  useEffect(() => {
    fetchTwoFactorStatus();
  }, [addNotification]);

  const disableTwoFactor = async deviceId => {
    try {
      const { error } = await supabase.auth.mfa.unenroll({
        factorId: deviceId
      });
      if (error) throw error;
      setTfaDevices(prevDevices =>
        prevDevices.filter(device => device.id !== deviceId)
      );
      addNotification({
        type: 'success',
        title: 'Device removed',
        description: 'Authentication device has been removed successfully.'
      });
    } catch (error) {
      console.error('Error removing 2FA device:', error);
      addNotification({
        type: 'error',
        title: 'Error',
        description: 'Failed to remove authentication device'
      });
    }
  };

  const handleRemoveDevice = deviceId => {
    setDeviceToRemove(deviceId);
    setShowRemoveDeviceModal(true);
  };

  const confirmRemoveDevice = () => {
    if (deviceToRemove) {
      disableTwoFactor(deviceToRemove);
      setDeviceToRemove(null);
    }
    setShowRemoveDeviceModal(false);
  };

  return (
    <>
      <div className='bg-white shadow-sm ring-1 ring-gray-900/5 rounded-xl sm:col-span-4'>
        <div className='px-4 py-6 sm:p-8'>
          <div className='space-y-10'>
            <div className='col-span-full'>
              <label
                htmlFor='change-password'
                className='block text-sm font-medium text-gray-900'
              >
                Password
              </label>
              <div className='mt-2 flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                <p className='text-sm text-gray-600'>
                  Your password is securely stored.
                </p>
                <FilledButton
                  id='change-password'
                  colour='base'
                  leftIcon={<Lock />}
                  onClick={() => setShowPasswordModal(true)}
                  className='mt-2 sm:mt-0 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm'
                >
                  Change Password
                </FilledButton>
              </div>
            </div>

            <div className='col-span-full'>
              <label
                htmlFor='tfa-devices'
                className='block text-sm font-medium text-gray-900'
              >
                Two-Factor Authentication (2FA)
              </label>
              <div className='mt-2'>
                <div className='mt-2 flex flex-col sm:flex-row items-start sm:items-center justify-between'>
                  <p className='text-sm text-gray-600'>
                    Add a two-factor authentication (2FA) app to enhance your
                    account's security.
                  </p>
                  <FilledButton
                    id='tfa-devices'
                    colour='success'
                    leftIcon={<Plus />}
                    onClick={() => setShowAddDeviceModal(true)}
                    className='mt-2 sm:mt-0 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm'
                  >
                    Add App
                  </FilledButton>
                </div>

                {tfaDevices.length > 0 ? (
                  <table className='min-w-full divide-y divide-gray-300 mt-4'>
                    <thead className='bg-gray-50'>
                      <tr>
                        <th className='px-6 py-3 text-left text-xs font-medium text-gray-700 tracking-wider'>
                          Name
                        </th>
                        <th className='px-6 py-3 text-left text-xs font-medium text-gray-700 tracking-wider'>
                          Created Date
                        </th>
                        <th className='px-6 py-3 text-right text-xs font-medium text-gray-700 tracking-wider'>
                          {/* Removed Actions label */}
                        </th>
                      </tr>
                    </thead>
                    <tbody className='bg-white divide-y divide-gray-200'>
                      {tfaDevices.map(device => (
                        <tr key={device.id}>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900'>
                            {device.friendly_name}
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-900'>
                            {new Date(device.created_at).toLocaleDateString()}
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm flex items-center justify-center'>
                            <TextButton
                              colour='danger'
                              onClick={() => handleRemoveDevice(device.id)}
                            >
                              Remove
                            </TextButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className='text-sm text-gray-500 mt-4'>
                    No authentication devices configured
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <MFAPopup
        isOpen={showAddDeviceModal}
        onClose={() => {
          setShowAddDeviceModal(false);
          fetchTwoFactorStatus();
        }}
      />

      <ChangePasswordPopup
        isOpen={showPasswordModal}
        onClose={() => setShowPasswordModal(false)}
      />

      <Popup
        isOpen={showRemoveDeviceModal}
        onClose={() => setShowRemoveDeviceModal(false)}
        title='Confirm Remove'
        size='sm'
      >
        <div className='space-y-4'>
          <p className='text-sm text-gray-600'>
            {tfaDevices.length === 1
              ? 'Removing this authentication will disable two-factor authentication on your account. Are you sure you want to proceed?'
              : 'Are you sure you want to remove this authentication? Two-factor authentication will still be enabled on your other devices.'}
          </p>
          <div className='flex justify-end gap-x-4'>
            <TextButton
              colour='base'
              onClick={() => setShowRemoveDeviceModal(false)}
            >
              Cancel
            </TextButton>
            <FilledButton colour='danger' onClick={confirmRemoveDevice}>
              Remove
            </FilledButton>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default Security;
