import React, { useEffect, useState } from 'react';
import { Checkbox, FilledButton, Popup, SoftButton, ComboBox } from 'core';
import { Alert } from 'core';
import ImportedUserProfile from '../../../models/ImportedUserProfile';
import { supabase } from '../../../utilities/supabase';
import Company from '../../../models/Company';

const ConvertUserPopup = ({ isOpen, onClose, userId }) => {
  const [copyActivityLogs, setCopyActivityLogs] = useState(true);
  const [user, setUser] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const user = await ImportedUserProfile.getById(userId);
      setUser(user);
    };

    fetchUserData();
  }, [userId]);

  const handleConvert = async () => {
    const tempUser = new ImportedUserProfile(user);
    const tempPayload = tempUser.toDatabase();
    tempUser.readOnly = true;
    tempUser.update();

    try {
      const payload = {
        user: tempPayload,
        company: selectedCompany ? selectedCompany.id : null,
        transfer_logs: copyActivityLogs
      };

      const { data, error } = await supabase.functions.invoke(
        'conversions/user',
        {
          body: payload
        }
      );

      if (error) {
        throw new Error('Conversion failed: ' + error.message);
      }

      console.log('Conversion successful');
      // Add user feedback for success
    } catch (error) {
      console.error('Error during conversion:', error);
      // Add user feedback for error
    }
    onClose();
  };

  const loadCompanyOptions = async (query, page) => {
    const { data: companies } = await Company.getAll({
      name: { value: query, operator: 'ilike' },
      page,
      pageSize: 10
    });
    return companies.map(company => ({
      label: company.name,
      value: company.id
    }));
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose} title='Convert User Data' size='md'>
      <div>
        <Alert
          title='Conversion Notice'
          description='You are about to convert a user record from imported to active. Please verify the information you wish to retain. This process will mark the imported record as read-only and cannot be undone.'
          style='warning'
        />
        <h3 className='mt-6'>Conversion Options</h3>
        <Checkbox
          className={'pl-1 ml-1'}
          checked={copyActivityLogs}
          label={'Copy activity logs'}
          description={
            'This will retain a copy of the activity logs in the new live user entity.'
          }
          onChange={() => setCopyActivityLogs(!copyActivityLogs)}
        />
        <h3 className='mt-8'>Assign Company</h3>
        <p className='text-sm mb-4'>
          Select an active company to assign this user to.
        </p>
        <ComboBox
          loadOptions={loadCompanyOptions}
          onChange={setSelectedCompany}
          placeholder="Search for a company..."
        />
        <div className='flex space-x-4 justify-end mt-8'>
          <SoftButton onClick={onClose}>Cancel</SoftButton>
          <FilledButton onClick={handleConvert}>Convert</FilledButton>
        </div>
      </div>
    </Popup>
  );
};

export default ConvertUserPopup;
