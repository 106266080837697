import { UserRound } from 'lucide-react';
import moment from 'moment';

const getRelativeTimeString = dateString => {
  if (!dateString) return 'N/A';
  const date = moment(dateString);
  const now = moment();
  const diffMinutes = now.diff(date, 'minutes');
  const diffHours = now.diff(date, 'hours');
  const diffDays = now.diff(date, 'days');
  const diffWeeks = now.diff(date, 'weeks');
  const diffMonths = now.diff(date, 'months');
  const diffYears = now.diff(date, 'years');

  if (diffMinutes < 60) {
    return `${diffMinutes} minute${diffMinutes !== 1 ? 's' : ''} ago`;
  } else if (diffHours < 24) {
    return `${diffHours} hour${diffHours !== 1 ? 's' : ''} ago`;
  } else if (diffDays < 7) {
    return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
  } else if (diffWeeks < 4) {
    return `${diffWeeks} week${diffWeeks !== 1 ? 's' : ''} ago`;
  } else if (diffMonths < 12) {
    return `${diffMonths} month${diffMonths !== 1 ? 's' : ''} ago`;
  } else {
    return `${diffYears} year${diffYears !== 1 ? 's' : ''} ago`;
  }
};

const formatDate = (dateString, small = false, textOnly = false) => {
  if (!dateString) return 'N/A';
  const date = moment(dateString);

  if (small) {
    return `${moment().diff(date, 'days')}d ago`;
  }

  const formattedDate = date.format('DD/MM/YYYY HH:mm');
  const relativeTime = date.fromNow();

  if (textOnly) {
    return `${formattedDate} (${relativeTime})`;
  }

  return (
    <>
      {formattedDate}{' '}
      <span className='text-neutral-500 text-xs'>({relativeTime})</span>
    </>
  );
};

const formatProfile = (value, size = 'md') => {
  const sizeClasses = {
    xs: 'mr-1 w-4 h-4 text-xs',
    sm: 'mr-1 w-5 h-5 text-sm',
    md: 'mr-2 w-6 h-6 text-base',
    lg: 'mr-2 w-8 h-8 text-lg',
    xl: 'mr-3 w-10 h-10 text-xl'
  };

  const iconSizes = {
    xs: 10,
    sm: 12,
    md: 14,
    lg: 16,
    xl: 20
  };

  const convertBase64ToBlob = base64Image => {
    const parts = base64Image.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  };

  const getImageSrc = profilePhoto => {
    if (profilePhoto.startsWith('data:image')) {
      const blob = convertBase64ToBlob(profilePhoto);
      return URL.createObjectURL(blob);
    }
    return profilePhoto;
  };

  return (
    <div className='flex items-center'>
      {value?.profilePhoto ? (
        <img
          src={getImageSrc(value.profilePhoto)}
          alt={`${value.firstName} ${value.lastName}'s profile`}
          className={`rounded-full ${sizeClasses[size]}`}
        />
      ) : value ? (
        <div
          className={`rounded-full bg-neutral-200 flex items-center justify-center ${sizeClasses[size]}`}
        >
          <UserRound size={iconSizes[size]} className='text-white' />
        </div>
      ) : null}
      <span className={sizeClasses[size].split(' ').pop()}>
        {value?.firstName} {value?.lastName}
      </span>
    </div>
  );
};

const formatCurrency = value =>
  new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value);

const getValidUrl = url => {
  try {
    // If the URL doesn't start with a protocol, add 'https://'
    if (!/^https?:\/\//i.test(url)) {
      url = 'https://' + url;
    }

    // Create a new URL object to validate and normalize the URL
    const parsedUrl = new URL(url);

    // Ensure the protocol is either 'http:' or 'https:'
    if (!['http:', 'https:'].includes(parsedUrl.protocol)) {
      parsedUrl.protocol = 'https:';
    }

    // Return the validated and normalized URL as a string
    return parsedUrl.toString();
  } catch (error) {
    console.error('Invalid URL:', error);
    return null; // Return null for invalid URLs
  }
};

const determineLinkType = url => {
  const validUrl = getValidUrl(url);
  const domain = validUrl ? new URL(validUrl).hostname.toLowerCase() : '';
  if (domain.includes('facebook')) return 'Facebook';
  if (domain.includes('linkedin')) return 'LinkedIn';
  if (domain.includes('twitter') || domain.includes('x.com')) return 'X';
  if (domain.includes('google')) return 'Google';
  return 'Website';
};

const determineLinkDisplay = url => {
  try {
    const validUrl = getValidUrl(url);
    if (!validUrl) return url; // Return original URL if invalid
    const parsedUrl = new URL(validUrl);
    const hostname = parsedUrl.hostname.toLowerCase();
    const pathname = parsedUrl.pathname.toLowerCase();

    if (hostname.includes('twitter') || hostname.includes('x.com')) {
      const username = pathname.split('/').filter(Boolean)[0];
      return username ? `@${username}` : url;
    }

    if (hostname.includes('linkedin.com')) {
      const companyName = pathname.split('/').filter(Boolean)[1];
      return companyName ? companyName.replace(/-/g, ' ') : url;
    }

    if (hostname.includes('facebook.com')) {
      const pageName = pathname.split('/').filter(Boolean)[0];
      return pageName || hostname.replace('www.', '');
    }

    return parsedUrl.hostname;
  } catch (error) {
    console.error('Error parsing URL:', error);
    return url;
  }
};

export {
  formatDate,
  formatProfile,
  formatCurrency,
  getRelativeTimeString,
  determineLinkType,
  determineLinkDisplay
};
