import React from 'react';

const LogoLoader = ({ logo, size = 'standard', selfContained = true }) => {
  const containerClasses = selfContained
    ? 'h-screen bg-neutral-100 flex justify-center items-center'
    : 'h-full w-full flex justify-center items-center';

  const sizeClasses = {
    small: 'w-10 h-10',
    standard: 'w-20 h-20'
  };

  return (
    <div className={containerClasses}>
      <div className={`${sizeClasses[size]} animate-spin`}>
        <img
          src={logo}
          alt='Loading'
          className='w-full h-full'
          width={size === 'small' ? 10 : 20}
          height={size === 'small' ? 10 : 20}
        />
      </div>
    </div>
  );
};

export { LogoLoader };
