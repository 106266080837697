import BaseModel from './BaseModel';
import UserProfile from './UserProfile';
import CoreEntityType from './CoreEntityType';
import CoreEntity from './CoreEntity';

/**
 * Represents a Meeting in the system.
 * @extends BaseModel
 */
class Meeting extends BaseModel {
  /** @type {string} The name of the database table for Meetings */
  static table = 'meetings';

  /** @type {string} The SQL query to select Meeting data with related information */
  static selectQuery = `
    *,
    owner:user_profiles(*),
    category:core_entity_types(*),
    entity_type:core_entities(*)
  `;

  /**
   * Creates an instance of Meeting.
   * @param {Object} data - The meeting data.
   */
  constructor(data = {}) {
    super(data);
    this.owner = data.owner ? new UserProfile(data.owner) : null;
    this.title = data.title || null;
    this.description = data.description || null;
    this.category = data.category ? new CoreEntityType(data.category) : null;
    this.status = data.status || null;
    this.entityType = data.entity_type
      ? new CoreEntity(data.entity_type)
      : null;
    this.entityId = data.entity_id || null;
    this.attendees = data.attendees || [];
    this.location = data.location || null;
    this.startDate = data.start_date || null;
    this.endDate = data.end_date || null;
  }

  /**
   * Converts the Meeting instance to a database-friendly format.
   * @returns {Object} The meeting data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      owner: this.owner ? this.owner.id : null,
      title: this.title,
      description: this.description,
      category: this.category ? this.category.id : null,
      status: this.status,
      entity_type: this.entityType ? this.entityType.id : null,
      entity_id: this.entityId,
      attendees: this.attendees,
      location: this.location,
      start_date: this.startDate,
      end_date: this.endDate
    };
  }
}

export default Meeting;
