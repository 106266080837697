import { Input } from 'core';
import { useState, useEffect } from 'react';

const Page1 = ({
  name,
  description,
  icon,
  colour,
  iconOptions,
  colourOptions,
  handleNameChange,
  handleDescriptionChange,
  handleIconChange,
  handleColourChange,
  renderIcon,
  bgColours,
  textColours,
  setReady,
  showErrors
}) => {
  const [errors, setErrors] = useState({ name: '* Required' });

  const validateName = name => {
    const newErrors = {};
    if (!name) {
      newErrors.name = '* Required';
    }
    setErrors(newErrors);
    const isValid = Object.keys(newErrors).length === 0;
    setReady(isValid);
    return isValid;
  };

  const handleNameInputChange = e => {
    handleNameChange(e);
    validateName(e.target.value);
  };

  useEffect(() => {
    validateName(name);
  }, [name]);

  return (
    <div className='overflow-y-auto pr-4'>
      <div className='flex items-center justify-between mb-5'>
        <div className='flex items-center'>
          <div
            className={`w-12 h-12 flex items-center justify-center rounded-lg transition-colors duration-300 ${bgColours[colour]} ${textColours[colour]}`}
          >
            {renderIcon(colour, icon)}
          </div>
          <div>
            <span className='text-lg font-semibold ml-3'>{name}</span>
            <p className='text-sm ml-3 text-gray-600'>{description}</p>
          </div>
        </div>
      </div>
      <div className='mb-4'>
        <Input
          label='Trigger Name'
          type='text'
          value={name}
          onChange={handleNameInputChange}
          placeholder='Trigger Name'
          className={showErrors && errors.name ? 'border-red-500' : ''}
        />
        {showErrors && errors.name && (
          <div className='text-red-500 text-sm mt-1'>{errors.name}</div>
        )}
      </div>
      <div className='mb-4'>
        <label className='block text-sm font-medium leading-6 text-gray-900'>
          Description
        </label>
        <textarea
          className='w-full p-2 border border-gray-300 rounded-md'
          value={description}
          onChange={handleDescriptionChange}
          placeholder='Enter a description for the automation...'
          rows={4}
        />
      </div>
      <div className='mb-4'>
        <label className='block text-sm font-medium leading-6 text-gray-900 mb-1'>
          Icon
        </label>
        <div className='grid grid-cols-8 sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 gap-4'>
          {iconOptions.map(option => (
            <div
              key={option}
              className={`w-10 h-10 flex items-center justify-center rounded-lg cursor-pointer transition-colors duration-300 ${
                icon === option ? 'bg-brand-700 text-white' : 'bg-gray-200'
              }`}
              onClick={() => handleIconChange(option)}
            >
              {renderIcon(null, option, false)}
            </div>
          ))}
        </div>
      </div>
      <div className='mb-4'>
        <label className='block text-sm font-medium leading-6 text-gray-900 mb-1'>
          Colour
        </label>
        <div className='grid grid-cols-8 sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 gap-4'>
          {colourOptions.map(option => (
            <div
              key={option}
              className={`w-10 h-10 rounded-lg cursor-pointer transition-colors duration-300 ${
                colour === option ? 'border-4 border-brand-700' : ''
              } ${bgColours[option]}`}
              onClick={() => handleColourChange(option)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Page1;
