import BaseModel from './BaseModel';

/**
 * Represents a Task Template in the system.
 * @extends BaseModel
 */
class TaskTemplate extends BaseModel {
  /** @type {string} The name of the database table for Task Templates */
  static table = 'task_templates';

  /** @type {string} The SQL query to select Task Template data with related information */
  static selectQuery = `
    *,
    owner:user_profiles(id, first_name, last_name, profile_photo)
  `;

  /**
   * Creates an instance of TaskTemplate.
   * @param {Object} data - The task template data.
   */
  constructor(data = {}) {
    super(data);
    this.owner = data.owner
      ? {
          id: data.owner.id,
          firstName: data.owner.first_name,
          lastName: data.owner.last_name,
          profilePhoto: data.owner.profile_photo
        }
      : null;
    this.title = data.title || null;
    this.template = data.template || {};
    this.entityType = data.entity_type || null;
  }

  /**
   * Converts the TaskTemplate instance to a database-friendly format.
   * @returns {Object} The task template data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      owner: this.owner ? this.owner.id : null,
      title: this.title,
      template: this.template,
      entity_type: this.entityType
    };
  }
}

export default TaskTemplate;
