import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Popup, CircularIconButton } from 'core';
import * as LucideIcons from 'lucide-react';
import EntityPopupHandler from '../EntityPopupHandler';

const FavouriteActionsPopup = ({
  isOpen,
  onClose,
  onUpdate,
  entity,
  entityType,
  entityId
}) => {
  const [actions, setActions] = useState([]);
  const [activePopup, setActivePopup] = useState(null);

  useEffect(() => {
    const loadActions = () => {
      const userPreferences = JSON.parse(
        localStorage.getItem('user_preferences') || '{}'
      );
      const defaultActions = [
        {
          icon: 'Edit',
          label: 'Note',
          handler: 'handleNoteClick',
          favourited: true,
          order: 1
        },
        {
          icon: 'Mail',
          label: 'Email',
          handler: 'handleEmailClick',
          favourited: true,
          order: 2
        },
        {
          icon: 'CheckSquare',
          label: 'Task',
          handler: 'handleTaskClick',
          favourited: true,
          order: 3
        },
        {
          icon: 'Calendar',
          label: 'Meeting',
          handler: 'handleMeetingClick',
          favourited: true,
          order: 4
        },
        {
          icon: 'KanbanSquare',
          label: 'Pipelines',
          handler: 'handlePipelinesClick',
          favourited: true,
          order: 5
        },
        {
          icon: 'PhoneCall',
          label: 'Call',
          handler: 'handleCallClick',
          favourited: false,
          order: 6
        },
        {
          icon: 'MessageSquare',
          label: 'SMS',
          handler: 'handleSMSClick',
          favourited: false,
          order: 7
        },
        {
          icon: 'Headphones',
          label: 'Audio',
          handler: 'handleAudioClick',
          favourited: false,
          order: 8
        },
        {
          icon: 'Video',
          label: 'Video',
          handler: 'handleVideoClick',
          favourited: false,
          order: 9
        },
        {
          icon: 'MessageCircle',
          label: 'Ai Chat',
          handler: 'handleAiChatClick',
          favourited: false,
          order: 10
        },
        {
          icon: 'Phone',
          label: 'Ai Call',
          handler: 'handleAiCallClick',
          favourited: false,
          order: 11
        },
        {
          icon: 'Cpu',
          label: 'AiFlows',
          handler: 'handleAiFlowsClick',
          favourited: false,
          order: 12
        },
        {
          icon: 'FileText',
          label: 'DocFlows',
          handler: 'handleDocFlowsClick',
          favourited: false,
          order: 13
        },
        {
          icon: 'Tag',
          label: 'Tags',
          handler: 'handleTagsClick',
          favourited: false,
          order: 14
        },
        {
          icon: 'CreditCard',
          label: 'E-Card',
          handler: 'handleECardClick',
          favourited: false,
          order: 15
        },
        {
          icon: 'Users',
          label: 'Audience',
          handler: 'handleAudienceClick',
          favourited: false,
          order: 16
        },
        {
          icon: 'File',
          label: 'Page',
          handler: 'handlePageClick',
          favourited: false,
          order: 17
        },
        {
          icon: 'Link',
          label: 'Links',
          handler: 'handleLinksClick',
          favourited: false,
          order: 18
        },
        {
          icon: 'Gift',
          label: 'Gift',
          handler: 'handleGiftClick',
          favourited: false,
          order: 19
        }
      ];

      if (userPreferences.quick_actions) {
        if (
          !userPreferences.quick_actions_last_updated ||
          new Date(userPreferences.quick_actions_last_updated) <
            new Date(import.meta.env.VITE_LAST_ACTIONS_BUILD)
        ) {
          setActions(defaultActions.sort((a, b) => a.order - b.order));
          userPreferences.quick_actions = defaultActions;
          userPreferences.quick_actions_last_updated = new Date().toISOString();
          localStorage.setItem(
            'user_preferences',
            JSON.stringify(userPreferences)
          );
        } else {
          setActions(
            userPreferences.quick_actions.sort((a, b) => a.order - b.order)
          );
        }
      } else {
        setActions(defaultActions.sort((a, b) => a.order - b.order));
        userPreferences.quick_actions = defaultActions;
        userPreferences.quick_actions_last_updated = new Date().toISOString();
        localStorage.setItem(
          'user_preferences',
          JSON.stringify(userPreferences)
        );
      }
    };

    loadActions();
  }, []);

  const onDragEnd = useCallback(
    result => {
      const { source, destination } = result;

      if (!destination) return;

      const newActions = Array.from(actions);
      const quickActions = newActions.filter(action => action.favourited);
      const availableActions = newActions.filter(action => !action.favourited);

      let sourceList, destinationList;
      if (source.droppableId === 'quickActions') {
        sourceList = quickActions;
      } else {
        sourceList = availableActions;
      }

      if (destination.droppableId === 'quickActions') {
        destinationList = quickActions;
      } else {
        destinationList = availableActions;
      }

      const [movedItem] = sourceList.splice(source.index, 1);
      movedItem.favourited = destination.droppableId === 'quickActions';
      destinationList.splice(destination.index, 0, movedItem);

      // Update order based on new positions
      const updatedActions = [...quickActions, ...availableActions].map(
        (action, index) => ({
          ...action,
          order: index + 1
        })
      );

      console.log(
        `Moved item "${movedItem.label}" from ${source.droppableId} to ${
          destination.droppableId
        } at position ${destination.index + 1}`
      );

      setActions(updatedActions);

      const userPreferences = JSON.parse(
        localStorage.getItem('user_preferences') || '{}'
      );
      userPreferences.quick_actions = updatedActions;
      localStorage.setItem('user_preferences', JSON.stringify(userPreferences));

      // Call the onUpdate prop with the new actions
      if (onUpdate) {
        onUpdate(updatedActions);
      }

      console.log('Updated actions:', updatedActions);
    },
    [actions, onUpdate]
  );

  const getIconComponent = useCallback(iconName => {
    return LucideIcons[iconName] || LucideIcons.HelpCircle;
  }, []);

  const handleActionClick = useCallback(handler => {
    setActivePopup(handler);
  }, []);

  const quickActions = actions.filter(action => action.favourited);
  const availableActions = actions.filter(action => !action.favourited);

  return (
    <>
      <Popup isOpen={isOpen} onClose={onClose} title='Actions' size='sm'>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className='mb-4 overflow-hidden'>
            <h3 className='text-lg font-semibold mb-2'>
              Quick Actions{' '}
              <span className='text-sm font-normal'>
                ({quickActions.length}/11)
              </span>
            </h3>
            <Droppable droppableId='quickActions' direction='horizontal'>
              {provided => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className='grid grid-cols-6 gap-2 p-2 min-h-20 border border-base-100 rounded-xl overflow-x-auto'
                >
                  {quickActions.map((action, index) => (
                    <Draggable
                      key={action.label}
                      draggableId={action.label}
                      index={index}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <CircularIconButton
                            Icon={getIconComponent(action.icon)}
                            label={action.label}
                            onClick={() => handleActionClick(action.handler)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <div className='overflow-hidden'>
            <h3 className='text-lg font-semibold mb-2'>Available Actions</h3>
            <Droppable droppableId='availableActions' direction='horizontal'>
              {provided => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className='grid grid-cols-5 gap-2 p-2 overflow-x-auto'
                >
                  {availableActions.map((action, index) => (
                    <Draggable
                      key={action.label}
                      draggableId={action.label}
                      index={index}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className='flex border border-base-100 rounded-xl p-2 w-20 h-20 items-center justify-center'
                        >
                          <CircularIconButton
                            Icon={getIconComponent(action.icon)}
                            label={action.label}
                            onClick={() => handleActionClick(action.handler)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </Popup>
      <EntityPopupHandler
        entityType={entityType}
        entityId={entityId}
        entity={entity}
        onUpdate={onUpdate}
        handler={activePopup}
      />
    </>
  );
};

export default FavouriteActionsPopup;
