import { useState } from 'react';
import { supabase } from '../../../utilities/supabase';
import { useNavigate } from 'react-router-dom';
import { PrimaryInput } from '../../../components/inputs/Forms';
import { FilledButton } from 'core';
import PasswordInput from '../../../components/inputs/Password';
import Auth from '../Auth';

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [hasStarted, setHasStarted] = useState({
    password: false,
    confirmPassword: false
  });

  const navigate = useNavigate();

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setHasStarted(prev => ({ ...prev, [name]: true }));

    if (name === 'confirmPassword' && hasStarted.confirmPassword) {
      const passwordsMatch = formData.password === value;
      setFormErrors(prev => ({
        ...prev,
        confirmPassword: passwordsMatch ? null : "Passwords don't match"
      }));
    }
  };

  const handleResetPassword = async e => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setFormErrors({});

    const errors = {};
    if (!formData.password.trim()) errors.password = 'Password is required';
    if (formData.password !== formData.confirmPassword)
      errors.confirmPassword = "Passwords don't match";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setLoading(false);
      return;
    }

    try {
      const { error } = await supabase.auth.updateUser({
        password: formData.password
      });

      if (error) throw error;

      // Password reset successful, navigate to login page
      navigate('/login', {
        state: {
          message:
            'Password reset successful. Please log in with your new password.'
        }
      });
    } catch (error) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Auth title='Reset Password' onSubmit={handleResetPassword}>
      <PasswordInput
        id='password'
        name='password'
        type='password'
        placeholder='Enter your new password'
        value={formData.password}
        onChange={handleInputChange}
        label='New Password'
        error={formErrors.password}
      />
      <PrimaryInput
        id='confirm-password'
        name='confirmPassword'
        type='password'
        placeholder='Confirm your new password'
        value={formData.confirmPassword}
        onChange={handleInputChange}
        label='Confirm New Password'
        error={formErrors.confirmPassword}
      />
      {error && <p className='text-red-500 text-sm'>{error}</p>}
      <div>
        <FilledButton
          type='submit'
          className='w-full'
          colour='primary'
          size='md'
        >
          {loading ? 'Resetting...' : 'Reset Password'}
        </FilledButton>
      </div>
    </Auth>
  );
};

export default ResetPassword;
