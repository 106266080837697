import React, { useState, useEffect, useCallback } from 'react';
import { FilledButton, TextButton, Input, DropdownInput, ComboBox } from 'core';
import { Popup, Map } from 'core';
import { searchPlaces } from 'core';
import Location from '../../../models/Location';
import CoreEntityType from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';

const AddLocation = ({
  isOpen,
  onClose,
  entityId,
  entityType,
  onLocationAdded
}) => {
  const [searchAddress, setSearchAddress] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    address_1: '',
    address_2: '',
    city: '',
    county: '',
    postcode: '',
    phone: '',
    email: '',
    status: ''
  });
  const [mapCenter, setMapCenter] = useState({ lat: 51.5074, lng: -0.1278 }); // Default to London
  const [statusTypes, setStatusTypes] = useState([]);

  // Load status types once
  useEffect(() => {
    const fetchStatusTypes = async () => {
      try {
        const types = await CoreEntityType.getByEntityId(
          EntityTypeEnum.LocationStatus
        );
        setStatusTypes(
          types.map(type => ({
            value: type.id,
            label: type.type,
            key: type.id
          }))
        );
      } catch (error) {
        console.error('Error fetching status types:', error);
      }
    };
    fetchStatusTypes();
  }, []);

  const loadPlaces = useCallback(async query => {
    if (!query) return [];
    try {
      const results = await searchPlaces(query);
      return results.map(place => ({
        key: place.place_id,
        value: place.place_id,
        label: place.name,
        secondaryLabel: place.formatted_address,
        details: {
          place_id: place.place_id,
          name: place.name,
          formatted_address: place.formatted_address,
          location: place.location,
          address_components: place.address_components,
          phone_number: place.phone_number
        }
      }));
    } catch (error) {
      console.error('Error loading places:', error);
      return [];
    }
  }, []);

  const handlePlaceSelect = place => {
    if (!place) return;

    const addressComponents = place.details.address_components;
    const streetNumber = addressComponents.find(c => c.types.includes('street_number'))?.longText || '';
    const street = addressComponents.find(c => c.types.includes('route'))?.longText || '';
    const city = addressComponents.find(c => c.types.includes('postal_town'))?.longText || '';
    const county = addressComponents.find(c => c.types.includes('administrative_area_level_2'))?.longText || '';
    const postcode = addressComponents.find(c => c.types.includes('postal_code'))?.longText || '';

    setFormData(prev => ({
      ...prev,
      name: place.details.name,
      address_1: streetNumber ? `${streetNumber} ${street}` : street,
      city,
      county,
      postcode
    }));
  };

  const handleSubmit = async () => {
    try {
      const location = new Location({
        ...formData,
        entity_type: entityType,
        entity_id: entityId,
        location_type: entityType === 4 ? 109 : entityType === 17 ? 108 : null
      });
      const newLocation = await location.insert();

      onLocationAdded(newLocation);
      onClose();
    } catch (error) {
      console.error('Error creating location:', error);
    }
  };

  const handleClose = () => {
    setFormData({
      name: '',
      address_1: '',
      address_2: '',
      city: '',
      county: '',
      postcode: '',
      phone: '',
      email: '',
      status: ''
    });
    setMapCenter({ lat: 51.5074, lng: -0.1278 });
    onClose();
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={handleClose}
      title='Add New Location'
      footer={
        <div className='flex justify-end space-x-2 mt-1'>
          <FilledButton type='button' colour='base' onClick={handleClose}>
            Cancel
          </FilledButton>
          <FilledButton onClick={handleSubmit}>Add Location</FilledButton>
        </div>
      }
    >
      <div className='space-y-6 overflow-y-auto'>
        <div className='space-y-4'>
          <div>
            <ComboBox
              label='Search Places'
              loadOptions={loadPlaces}
              onChange={handlePlaceSelect}
              placeholder='Search for a location...'
              hint='Search for a place to automatically fill the address details'
            />
          </div>
          <div>
            <Input
              label='Location Name'
              value={formData.name}
              onChange={e =>
                setFormData(prev => ({ ...prev, name: e.target.value }))
              }
            />
          </div>
          <div>
            <DropdownInput
              label='Location Status'
              value={formData.status}
              onChange={e =>
                setFormData(prev => ({ ...prev, status: e.target.value }))
              }
              options={statusTypes}
            />
          </div>
        </div>

        <div className='h-48 w-full rounded-lg overflow-hidden shadow-sm border border-gray-200'>
          <Map
            apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
            address={`${formData.address_1} ${formData.city} ${formData.postcode}`}
            zoom={15}
            width='100%'
            height='100%'
          />
        </div>

        <div className='space-y-6'>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
            <div className='col-span-3'>
              <Input
                label='Address Line 1'
                value={formData.address_1}
                onChange={e =>
                  setFormData(prev => ({ ...prev, address_1: e.target.value }))
                }
              />
            </div>
            <div className='col-span-3'>
              <Input
                label='Address Line 2'
                value={formData.address_2}
                onChange={e =>
                  setFormData(prev => ({ ...prev, address_2: e.target.value }))
                }
              />
            </div>
            <div className='col-span-3 md:col-span-1'>
              <Input
                label='City'
                value={formData.city}
                onChange={e =>
                  setFormData(prev => ({ ...prev, city: e.target.value }))
                }
              />
            </div>
            <div className='col-span-3 md:col-span-1'>
              <Input
                label='County'
                value={formData.county}
                onChange={e =>
                  setFormData(prev => ({ ...prev, county: e.target.value }))
                }
              />
            </div>
            <div className='col-span-3 md:col-span-1'>
              <Input
                label='Postcode'
                value={formData.postcode}
                onChange={e =>
                  setFormData(prev => ({ ...prev, postcode: e.target.value }))
                }
              />
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default AddLocation;
