import React, { useState } from 'react';
import { formatProfile, formatDate } from '../../../utilities/Formatting';
import { useLocation } from 'react-router-dom';
import { Link } from 'lucide-react';

const Comment = ({ comment, isHighlighted }) => {
  const [copied, setCopied] = useState(false);
  const [showCopyLink, setShowCopyLink] = useState(false);
  const location = useLocation();

  const handleCopyLink = () => {
    const url = new URL(window.location.href);
    url.hash = `#${comment.id}`;
    navigator.clipboard.writeText(url.toString()).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div
      className={`bg-neutral-50 p-4 rounded-lg relative ${
        isHighlighted ? 'border-2 border-brand-500' : ''
      }`}
      onMouseEnter={() => setShowCopyLink(true)}
      onMouseLeave={() => setShowCopyLink(false)}
    >
      {isHighlighted && (
        <div className='text-xs text-neutral-500 mb-3'>This Comment</div>
      )}
      <div className='flex justify-between items-center mb-2'>
        <div className='flex items-center'>{formatProfile(comment.owner)}</div>
        <span className='text-sm text-neutral-500'>
          {formatDate(comment.createdDate)}
        </span>
      </div>
      <div dangerouslySetInnerHTML={{ __html: comment.comment }} />
      {showCopyLink && (
        <div
          className='absolute bottom-2 right-2 cursor-pointer text-neutral-500 hover:text-brand-500'
          onClick={handleCopyLink}
        >
          <Link />
        </div>
      )}
    </div>
  );
};

export default Comment;
