import { useState, useEffect } from 'react';
import { supabase } from '../../../utilities/supabase';
import { PrimaryInput } from '../../../components/inputs/Forms';
import { FilledButton } from 'core';
import Auth from '../Auth';
import { useNavigate } from 'react-router-dom';
import { Shield } from 'lucide-react'; // Changed icon to a more secure-looking one

const MFA = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tfaCode, setTfaCode] = useState('');
  const [selectedFactor, setSelectedFactor] = useState(null);
  const [challengeId, setChallengeId] = useState(null);
  const [factors, setFactors] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const getFactors = async () => {
      try {
        const { data, error } = await supabase.auth.mfa.listFactors();

        console.log(data);

        if (error) throw error;
        setFactors(data.all);
      } catch (error) {
        console.error('Error getting MFA factors:', error);
        setError(error.message);
      }
    };

    getFactors();
  }, []);

  const verifyTwoFactor = async () => {
    try {
      setLoading(true);

      if (!selectedFactor || !challengeId) {
        throw new Error('No factor selected');
      }

      if (!tfaCode || tfaCode.length !== 6) {
        throw new Error('Please enter a valid 6-digit code');
      }

      const { data: verifyData, error: verifyError } =
        await supabase.auth.mfa.challengeAndVerify({
          factorId: selectedFactor.id,
          challengeId: challengeId,
          code: tfaCode
        });

      if (verifyError) {
        throw verifyError;
      }

      navigate('/', { replace: true });
    } catch (error) {
      console.error('MFA verification error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFactorSelect = async factor => {
    try {
      setLoading(true);
      setSelectedFactor(factor);
      const { data: challengeData, error: challengeError } =
        await supabase.auth.mfa.challenge({
          factorId: factor.id
        });

      if (challengeError) {
        throw challengeError;
      }

      setChallengeId(challengeData.id);
    } catch (error) {
      console.error('Factor selection error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Auth
        title='Two-Factor Authentication'
        onSubmit={verifyTwoFactor}
        type='mfa'
      >
        <div className='space-y-4'>
          {!selectedFactor ? (
            <>
              <div className='text-sm text-neutral-600'>
                Select your authentication method:
              </div>
              {factors?.map(factor => (
                <div
                  key={factor.id}
                  onClick={() => handleFactorSelect(factor)}
                  className='flex items-center p-4 border border-neutral-300 rounded-lg cursor-pointer hover:bg-neutral-100'
                >
                  <div className='flex-shrink-0'>
                    <Shield className='h-6 w-6'></Shield> {/* Updated icon */}
                  </div>
                  <div className='ml-4 text-sm font-medium text-neutral-700'>
                    {factor.friendly_name || 'Authenticator App'}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              <div className='text-sm text-neutral-600'>
                Enter the verification code from your authenticator app.
              </div>
              <PrimaryInput
                type='text'
                label='Verification Code'
                value={tfaCode}
                onChange={e => setTfaCode(e.target.value.trim())}
                placeholder='Enter 6-digit code'
                maxLength={6}
              />
              {error && <p className='text-danger-700 text-sm'>{error}</p>}
              <FilledButton
                onClick={verifyTwoFactor}
                className='w-full'
                colour='primary'
                size='md'
              >
                Verify
              </FilledButton>
            </>
          )}
        </div>
      </Auth>
    </>
  );
};

export default MFA;
