/**
 * Searches for places using the Google Places API
 * @param {string} query - The search query
 * @param {Object} options - Additional search options
 * @param {Object} options.location - The location to search around {lat: number, lng: number}
 * @param {number} options.radius - The radius in meters to search within (default 5000)
 * @param {string[]} options.types - Types of places to search for (e.g. ['restaurant', 'cafe'])
 * @returns {Promise<google.maps.places.PlaceResult[]>} Array of place results
 */
export const searchPlaces = async (query, options = {}) => {
  try {
    // Load Places library if not already loaded
    const { Place } = await google.maps.importLibrary('places');

    //const service = new PlacesService(document.createElement('div'));

    const searchRequest = {
      textQuery: query,
      fields: [
        'displayName',
        'location',
        'addressComponents',
        'formattedAddress',
        'nationalPhoneNumber'
      ],
      region: 'gb'
    };

    // Add optional parameters if provided
    // if (options.location) {
    //   searchRequest.locationBias = {
    //     ...searchRequest.locationBias,
    //     center: options.location,
    //     radius: options.radius || 5000
    //   };
    // }
    if (options.types) {
      searchRequest.types = options.types;
    }

    const results = await Place.searchByText(searchRequest);

    const formattedResults = results.places.map(place => ({
      place_id: place.Eg.id,
      name: place.Eg.displayName || '',
      formatted_address: place.Eg.formattedAddress || '',
      location: place.Eg.location,
      address_components: place.Eg.addressComponents || [],
      phone_number: place.Eg.nationalPhoneNumber || ''
    }));

    console.log(formattedResults);
    return formattedResults;
  } catch (error) {
    console.error('Error searching places:', error);
    throw error;
  }
};
