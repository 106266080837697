import BaseModel from './BaseModel';
import { supabase } from '../utilities/supabase';

/**
 * Represents a CoreEntityType in the system.
 * @extends BaseModel
 */
class CoreEntityType extends BaseModel {
  /** @type {string} The name of the database table for CoreEntityTypes */
  static table = 'core_entity_types';

  /** @type {string} The SQL query to select CoreEntityType data */
  static selectQuery = '*';

  /**
   * Creates an instance of CoreEntityType.
   * @param {Object} data - The core entity type data.
   */
  constructor(data = {}) {
    super(data);
    this.entityId = data?.entity_id || data?.entityId || null;
    this.type = data.type || null;
    this.description = data.description || null;
    this.metadata = data.metadata || {};
  }

  /**
   * Converts the CoreEntityType instance to a database-friendly format.
   * @returns {Object} The core entity type data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      entity_id: this.entityId,
      type: this.type,
      description: this.description,
      metadata: this.metadata
    };
  }

  /**
   * Fetch CoreEntityTypes by entity ID.
   * @param {string|number} id - The entity ID to fetch types for.
   * @returns {Promise<CoreEntityType[]>} A promise that resolves to an array of CoreEntityType instances.
   */
  static async getByEntityId(id) {
    try {
      const { data, error } = await supabase
        .from(this.table)
        .select(this.selectQuery)
        .eq('entity_id', id);

      if (error) throw error;

      return data.map(item => new this(item));
    } catch (error) {
      console.error('[Error] Fetching Core Entity Types by entity_id: ', error);
      throw error;
    }
  }
}

export default CoreEntityType;
