import React, { useState, useEffect, useCallback } from 'react';
import { ComboBox, Popup, FilledButton, OutlineButton } from 'core';
import { supabase } from '../../../utilities/supabase';
import CoreEntity from '../../../models/CoreEntity';

const AddPipelineItem = ({
  pipelineId,
  pipelineGroup = 1,
  onClose,
  onItemAdded
}) => {
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [pipelineDetails, setPipelineDetails] = useState(null);
  const [entityDetails, setEntityDetails] = useState(null);

  useEffect(() => {
    const fetchPipelineDetails = async () => {
      const { data, error } = await supabase
        .from('pipelines')
        .select('*')
        .eq('id', pipelineId)
        .single();
      if (error) console.error('Error fetching pipeline details:', error);
      else setPipelineDetails(data);
    };

    fetchPipelineDetails();
  }, [pipelineId]);

  useEffect(() => {
    if (pipelineDetails?.entity) {
      const fetchEntityDetails = async () => {
        const entity = await CoreEntity.getById(pipelineDetails.entity);
        setEntityDetails(entity);
      };

      fetchEntityDetails();
    }
  }, [pipelineDetails]);

  const searchEntities = useCallback(async query => {
    if (entityDetails?.tableName && query.length > 2) {
      const { data, error } = await supabase
        .from(entityDetails.tableName)
        .select(pipelineDetails.columns)
        .textSearch('fts', query)
        .limit(10);
      if (error) {
        console.error('Error searching entities:', error);
        return [];
      }
      return data.map(entity => {
        let label;
        switch (entityDetails.tableName) {
          case 'companies':
            label = entity.name;
            break;
          case 'user_profiles':
            label = `${entity.full_name} (${entity.email})`;
            break;
          default:
            label = entity.name || entity.full_name || 'Unknown';
        }
        return { value: entity.id, label };
      });
    }
    return [];
  });

  const handleSubmit = async e => {
    e.preventDefault();
    if (selectedEntity) {
      try {
        // Fetch the current entity data
        const { data: currentEntity, error: fetchError } = await supabase
          .from(entityDetails.tableName)
          .select('pipelines')
          .eq('id', selectedEntity.value)
          .single();

        if (fetchError) throw fetchError;

        // Prepare the updated pipelines array
        const currentPipelines = currentEntity.pipelines || [];
        const updatedPipelines = [
          ...currentPipelines,
          {
            id: Number(pipelineId),
            group: Number(pipelineGroup),
            order: currentPipelines.length
          }
        ];

        // Update the entity with the new pipelines array
        const { data, error: updateError } = await supabase
          .from(entityDetails.tableName)
          .update({ pipelines: updatedPipelines })
          .eq('id', selectedEntity.value);

        if (updateError) throw updateError;

        onItemAdded();
        onClose();
      } catch (error) {
        console.error('Error updating entity:', error);
        // Handle the error appropriately (e.g., show an error message to the user)
      }
    }
  };

  return (
    <Popup isOpen={true} onClose={onClose} title='Add Pipeline Item' size='sm'>
      <form onSubmit={handleSubmit}>
        <ComboBox
          loadOptions={searchEntities}
          value={selectedEntity}
          onChange={setSelectedEntity}
          placeholder='Search entities...'
          label={`Select a ${entityDetails?.displayName}`}
        />
        <div className='mt-4 flex justify-end'>
          <OutlineButton type='button' onClick={onClose} className='mr-2'>
            Cancel
          </OutlineButton>
          <FilledButton type='submit' disabled={!selectedEntity}>
            Add
          </FilledButton>
        </div>
      </form>
    </Popup>
  );
};

export default AddPipelineItem;
