import React, { useState } from 'react';
import { Calendar } from 'core';
import CreateMeeting from '../../popups/meetings/CreateMeeting';
import EditMeeting from '../../popups/meetings/EditMeeting';

const MeetingOverview = ({ meetings }) => {
  const [isCreateMeetingOpen, setIsCreateMeetingOpen] = useState(false);
  const [isEditMeetingOpen, setIsEditMeetingOpen] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);

  const handleAddEvent = date => {
    setIsCreateMeetingOpen(true);
  };

  const handleEditEvent = meeting => {
    setSelectedMeeting(meeting);
    setIsEditMeetingOpen(true);
  };

  return (
    <div>
      <Calendar
        events={meetings.map(meeting => ({
          id: meeting.id,
          title: meeting.title,
          start: meeting.startDate,
          end: meeting.endDate,
          data: meeting
        }))}
        currentMonth={new Date()}
        onAddEvent={handleAddEvent}
        onEditEvent={handleEditEvent}
      />
      {isCreateMeetingOpen && (
        <CreateMeeting
          isOpen={isCreateMeetingOpen}
          onClose={() => setIsCreateMeetingOpen(false)}
          selectedDate={new Date()}
          onCreateMeeting={handleAddEvent}
        />
      )}
      {isEditMeetingOpen && (
        <EditMeeting
          isOpen={isEditMeetingOpen}
          onClose={() => setIsEditMeetingOpen(false)}
          meeting={selectedMeeting}
          onEditMeeting={handleEditEvent}
        />
      )}
    </div>
  );
};

export default MeetingOverview;
