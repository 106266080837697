import React from 'react';
import { Wrench } from 'lucide-react';

export default function MaintenancePage() {
  return (
    <div className='grid min-h-screen grid-cols-1 grid-rows-[auto,1fr,auto] bg-white lg:grid-cols-[max(50%,36rem),1fr]'>
      <header className='mx-auto w-full max-w-7xl px-6 pt-6 sm:pt-10 lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:px-8'>
        <a href='/'>
          <span className='sr-only'>BOHSA LTD</span>
          <img
            alt='BOHSA Logo'
            src='/logo-star.png'
            className='h-8 w-auto sm:h-10'
          />
        </a>
      </header>
      <main className='mx-auto w-full max-w-7xl px-6 py-24 sm:py-32 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8'>
        <div className='max-w-lg'>
          <Wrench className='h-12 w-12 text-brand-600' />
          <h1 className='mt-4 text-3xl font-bold tracking-tight text-base-900 sm:text-5xl'>
            We're under maintenance
          </h1>
          <p className='mt-6 text-base leading-7 text-base-600'>
            Our team is working hard to improve your experience. We'll be back
            soon!
          </p>
          <div className='mt-10'>
            <a
              href='/'
              className='text-sm font-semibold leading-7 text-brand-600'
            >
              <span aria-hidden='true'>&larr;</span> Back to home
            </a>
          </div>
        </div>
      </main>
      <footer className='self-end lg:col-span-2 lg:col-start-1 lg:row-start-3'>
        <div className='border-t border-base-100 bg-base-50 py-10'>
          <nav className='mx-auto flex w-full max-w-7xl items-center gap-x-4 px-6 text-sm leading-7 text-base-600 lg:px-8'>
            <a href='/support'>Contact support</a>
            <svg
              viewBox='0 0 2 2'
              aria-hidden='true'
              className='h-0.5 w-0.5 fill-base-300'
            >
              <circle r={1} cx={1} cy={1} />
            </svg>
            <iframe
              src='https://status.bohsa.co.uk/badge?theme=light'
              width='250'
              height='30'
              frameBorder='0'
              scrolling='no'
              title='System Status'
              className='ml-2'
            ></iframe>
          </nav>
        </div>
      </footer>
      <div className='hidden lg:relative lg:col-start-2 lg:row-start-1 lg:row-end-4 lg:block'>
        <div className="absolute inset-0 bg-brand-700 flex items-center justify-center">
          <img
            src="/logo-full-white.png"
            alt="BOHSA white logo"
            className="w-48 h-auto object-contain"
          />
        </div>
      </div>
    </div>
  );
}
