import React, { useEffect } from 'react';
import {
  Route,
  Routes,
  Navigate,
  NavLink,
  useLocation,
  useNavigate
} from 'react-router-dom';
import { UnderlinedTabs, PillTabs } from 'core';

const TabView = ({
  tabs,
  baseUrl,
  default: defaultTab,
  variant = 'underlined',
  fullWidth = false
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const getTabPath = tabPath => (baseUrl ? `${baseUrl}/${tabPath}` : tabPath);

  useEffect(() => {
    if (location.pathname === baseUrl && defaultTab) {
      navigate(getTabPath(defaultTab), { replace: true });
    }
  }, [location.pathname, baseUrl, defaultTab, navigate]);

  const renderTabs = () => {
    if (variant === 'underlined') {
      return <UnderlinedTabs tabs={tabs} fullWidth={fullWidth} color='brand' />;
    } else {
      return <PillTabs tabs={tabs} fullWidth={fullWidth} />;
    }
  };

  return (
    <div
      className={
        variant === 'underlined' ? 'flex flex-col h-full w-full mx-auto' : ''
      }
    >
      <div className='sticky top-0 z-10 bg-white px-2 lg:px-4'>
        {renderTabs()}
      </div>
      <div
        className={
          variant === 'underlined'
            ? 'bg-white flex-grow rounded-b-md mt-8 px-2 lg:px-4'
            : 'mt-4 px-2 lg:px-4'
        }
        role='tabpanel'
      >
        <Routes>
          {tabs.map((tab, index) => (
            <Route key={index} path={`${tab.path}/*`} element={tab.content} />
          ))}
          <Route
            path='*'
            element={<Navigate to={getTabPath(defaultTab)} replace />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default TabView;
