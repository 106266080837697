import BaseModel from './BaseModel';

/**
 * Represents a Trainer in the system.
 * @extends BaseModel
 */
class Trainer extends BaseModel {
  /** @type {string} The name of the database table for Trainers */
  static table = 'trainers';

  /** @type {string} The SQL query to select Trainer data with related information */
  static selectQuery = `
    *,
    status:core_entity_types!status (id, type)
  `;

  /**
   * Creates an instance of Trainer.
   * @param {Object} data - The trainer data.
   */
  constructor(data = {}) {
    super(data);
    this.name = data.name || null;
    this.capsuleId = data.capsule_id || null;
    this.kashflowId = data.kashflow_id || null;
    this.paymentTerms = data.payment_terms || null;
    this.termsAgreed = data.terms_agreed || false;
    this.creditLimit = data.credit_limit || null;
    this.contacts = data.contacts || [{}];
    this.status = data.status
      ? { id: data.status.id, name: data.status.type }
      : null;
    this.approved = data.approved || false;
    this.rating = data.rating || null;
    this.options = data.options || {};
  }

  /**
   * Converts the Trainer instance to a database-friendly format.
   * @returns {Object} The trainer data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      name: this.name,
      capsule_id: this.capsuleId,
      kashflow_id: this.kashflowId,
      payment_terms: this.paymentTerms,
      terms_agreed: this.termsAgreed,
      credit_limit: this.creditLimit,
      contacts: this.contacts,
      status: this.status ? this.status.id : null,
      approved: this.approved,
      rating: this.rating,
      options: this.options
    };
  }
}

export default Trainer;
