import React, { useState, useEffect } from 'react';
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  DialogBackdrop
} from '@headlessui/react';
import { ChevronRight, Search, User } from 'lucide-react';
import UserProfile from '../../../models/UserProfile';
import CoreEntityTypes from '../../../models/CoreEntityType';
import { FilledButton } from 'core';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const AddContact = ({
  isOpen,
  onClose,
  onAddContact
}) => {
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedContactType, setSelectedContactType] = useState('');
  const [contactTypes, setContactTypes] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);

  useEffect(() => {
    const fetchContactTypes = async () => {
      try {
        const types = await CoreEntityTypes.getByEntityId(45);
        setContactTypes(
          types.map(type => ({
            value: type.id,
            label: type.type
          }))
        );
      } catch (error) {
        console.error('Error fetching contact types:', error);
      }
    };

    fetchContactTypes();
  }, []);

  const handleSearch = async term => {
    if (term.length > 2) {
      try {
        const { data } = await UserProfile.getAll(
          { fts: { operator: 'textSearch', value: term } },
          1,
          10
        );
        setSearchResults(data);
      } catch (error) {
        console.error('Error searching for contacts:', error);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleAddContact = async () => {
    if (!selectedContactType) {
      alert('Please select a contact type');
      return;
    }
    try {
      await onAddContact(
        selectedProfile.id,
        selectedContactType
      );
      onClose();
    } catch (error) {
      console.error('Error adding contact:', error);
    }
  };

  return (
    <Dialog
      className='relative z-10'
      open={isOpen}
      onClose={() => {
        onClose();
        setQuery('');
      }}
    >
      <DialogBackdrop
        transition
        className='fixed inset-0 bg-base-500 bg-opacity-25 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in'
      />

      <div className='fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20'>
        <DialogPanel
          transition
          className='mx-auto max-w-3xl transform divide-y divide-base-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in'
        >
          <Combobox onChange={setSelectedProfile}>
            <div className='relative'>
              <Search
                className='pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-base-400'
                aria-hidden='true'
              />
              <ComboboxInput
                className='h-12 w-full border-0 bg-transparent pl-11 pr-4 text-base-900 placeholder:text-base-400 focus:ring-0 sm:text-sm'
                placeholder='Search contacts...'
                onChange={event => {
                  setQuery(event.target.value);
                  handleSearch(event.target.value);
                }}
              />
            </div>

            <div className='flex divide-x divide-base-100'>
              <div className='w-1/2 overflow-y-auto max-h-96'>
                <ComboboxOptions static className='py-4 px-2'>
                  {searchResults.map(profile => (
                    <ComboboxOption
                      key={profile.id}
                      value={profile}
                      className={({ active, selected }) =>
                        classNames(
                          'group flex cursor-default select-none items-center rounded-md p-2',
                          (active || selected) ? 'bg-base-100 text-base-900' : ''
                        )
                      }
                    >
                      {({ active, selected }) => (
                        <>
                          <User className='h-6 w-6 flex-none rounded-full' />
                          <span className='ml-3 flex-auto truncate'>{`${profile.firstName} ${profile.lastName}`}</span>
                          <ChevronRight
                            className={classNames(
                              'ml-3 h-5 w-5 flex-none text-base-400',
                              (active || selected) ? 'block' : 'hidden'
                            )}
                            aria-hidden='true'
                          />
                        </>
                      )}
                    </ComboboxOption>
                  ))}
                </ComboboxOptions>
                {query !== '' && searchResults.length === 0 && (
                  <div className='px-6 py-14 text-center text-sm'>
                    <User
                      className='mx-auto h-6 w-6 text-base-400'
                      aria-hidden='true'
                    />
                    <p className='mt-4 font-semibold text-base-900'>
                      No people found
                    </p>
                    <p className='mt-2 text-base-500'>
                      We couldn't find anything with that term. Please try
                      again.
                    </p>
                  </div>
                )}
              </div>
              <div className='w-1/2 overflow-y-auto max-h-96'>
                {selectedProfile && (
                  <div className='p-6'>
                    <div className='text-center'>
                      <User className='mx-auto h-16 w-16 rounded-full' />
                      <h2 className='mt-3 font-semibold text-base-900'>{`${selectedProfile.firstName} ${selectedProfile.lastName}`}</h2>
                      <p className='text-sm leading-6 text-base-500'>
                        {selectedProfile.email}
                      </p>
                    </div>
                    <dl className='mt-6 grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-base-700'>
                      <dt className='col-end-1 font-semibold text-base-900'>
                        Email
                      </dt>
                      <dd className='truncate'>
                        <a
                          href={`mailto:${selectedProfile.email}`}
                          className='text-brand-600 underline'
                        >
                          {selectedProfile.email}
                        </a>
                      </dd>
                    </dl>
                    <div className='mt-6'>
                      <select
                        value={selectedContactType}
                        onChange={e => setSelectedContactType(e.target.value)}
                        className='mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-base-900 ring-1 ring-inset ring-base-300 focus:ring-2 focus:ring-brand-600 sm:text-sm sm:leading-6'
                      >
                        <option value=''>Select contact type</option>
                        {contactTypes.map(type => (
                          <option key={type.value} value={type.value}>
                            {type.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <FilledButton
                      onClick={handleAddContact}
                      disabled={!selectedContactType}
                      className='mt-6 w-full justify-center'
                    >
                      Add Contact
                    </FilledButton>
                  </div>
                )}
              </div>
            </div>
          </Combobox>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default AddContact;
