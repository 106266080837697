import {
  Clock,
  Building,
  CheckCircle,
  PoundSterling,
  Users as UsersIcon,
  ReceiptText,
  Coins,
  Download,
  LoaderCircle,
  Globe,
  MessageSquare,
  Plus,
  Trash,
  Pencil
} from 'lucide-react';
import Entity from '../../../../components/entity/Entity';
import ImportedCompany from '../../../../models/ImportedCompany';
import DateCard from '../../../../components/entity/DateCard';
import {
  Badge,
  TextButton,
  Input,
  Popup,
  FilledButton,
  DropdownInput,
  Alert
} from 'core';
import Field from '../../../../components/entity/Field';
import CoreEntityTypes from '../../../../models/CoreEntityType';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { EntityTypeEnum } from '../../../../utilities/Enumerables';
import UserProfile from '../../../../models/UserProfile';
import {
  SiFacebook,
  SiGoogle,
  SiLinkedin,
  SiX
} from '@icons-pack/react-simple-icons';
import ConvertCompanyPopup from '../../../../components/popups/import-converter/ConvertCompanyPopup';
import { useNotification } from 'core';
import {
  determineLinkDisplay,
  determineLinkType
} from '../../../../utilities/Formatting';

const ImportedCompanyDetails = ({ currentUser }) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [linkUrl, setLinkUrl] = useState('');
  const [termsPopupOpen, setTermsPopupOpen] = useState(false);
  const [termOptions, setTermOptions] = useState([]);
  const [terms, setTerms] = useState({ days: '', type: '' });
  const [convertPopupOpen, setConvertPopupOpen] = useState(false);
  const { id } = useParams();

  const { addNotification } = useNotification();

  useEffect(() => {
    document.title = `Company #${id} | CRM | BOHSA LTD`;
    const fetchOptions = async () => {
      try {
        const options = await CoreEntityTypes.getByEntityId(
          EntityTypeEnum.CompanyStatus
        );
        const terms = await CoreEntityTypes.getByEntityId(
          EntityTypeEnum.CompanyTerms
        );
        const { data: fetchedUsers } = await UserProfile.getAll();

        const formattedOptions = options.map(option => ({
          label: option.type,
          value: option.id
        }));
        const formattedTerms = terms.map(option => ({
          label: option.type,
          value: option.id
        }));

        setStatusOptions(formattedOptions);
        setTermOptions(formattedTerms);
        setUsers(fetchedUsers);
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    fetchOptions();
  }, []);

  const infoItems = [
    {
      icon: Building,
      label: 'Company',
      field: 'name',
      dataType: 'text',
      isEditable: true
    },
    {
      icon: CheckCircle,
      label: 'Status',
      displayField: 'status.name',
      editField: 'status.id',
      dataType: 'select',
      isEditable: true,
      options: statusOptions
    },
    {
      icon: Building,
      label: 'Capsule ID',
      field: 'capsuleId',
      dataType: 'text',
      isEditable: true
    },
    {
      icon: Building,
      label: 'Account No#',
      field: 'accountNumber',
      dataType: 'text',
      isEditable: true
    },
    {
      icon: UsersIcon,
      label: 'Account Manager',
      displayField: 'accountManager',
      editField: 'accountManager',
      isEditable: true,
      dataType: 'user-select',
      options: users,
      inlineLabel: true,
      inlineEditor: false
    }
  ];

  const handleTermsEdit = () => {
    setTermsPopupOpen(true);
  };

  const handleConvertClick = () => {
    setConvertPopupOpen(true);
  };

  const accordionItems = [
    {
      title: 'Links',
      content: ({ data, onUpdate }) => (
        <div>
          {data.links.map(link => (
            <div
              key={link.id}
              className='flex items-center justify-between mb-2 group'
            >
              <div className='flex items-center'>
                {link.type === 'Website' && (
                  <Globe className='mr-2' size={18} />
                )}
                {link.type === 'X' && <SiX className='mr-2 text-x' size={18} />}
                {link.type === 'LinkedIn' && (
                  <SiLinkedin className='mr-2 text-linkedin' size={18} />
                )}
                {link.type === 'Facebook' && (
                  <SiFacebook className='mr-2 text-facebook' size={18} />
                )}
                {link.type === 'Google' && (
                  <SiGoogle className='mr-2 text-google' size={18} />
                )}
                <a
                  href={link.url}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='text-primary-600 hover:text-primary-700 hover:underline transition-colors duration-200'
                >
                  {link.display}
                </a>
              </div>
              <TextButton
                size='sm'
                colour='danger'
                className='hidden group-hover:flex'
                onClick={() =>
                  onUpdate(
                    'links',
                    data.links.filter(l => l.id !== link.id)
                  )
                }
              >
                <Trash size={16} />
              </TextButton>
            </div>
          ))}
          <div className='flex items-center gap-x-1 mb-4'>
            <Input
              placeholder='Enter link URL'
              value={linkUrl}
              onChange={e => {
                const value = e.target.value.replace(/^https?:\/\//, '');
                setLinkUrl(value);
              }}
              leftAddon='https://'
            />
            <TextButton
              size='sm'
              colour='primary'
              className='w-32'
              leftIcon={<Plus />}
              onClick={() => {
                if (linkUrl) {
                  try {
                    const fullUrl = `https://${linkUrl}`;
                    const linkObject = {
                      id: Date.now(),
                      url: fullUrl,
                      display: determineLinkDisplay(fullUrl),
                      type: determineLinkType(fullUrl)
                    };
                    onUpdate('links', [...data.links, linkObject]);
                    setLinkUrl('');
                  } catch (error) {
                    console.error('Invalid URL:', error);
                  }
                }
              }}
            >
              Add link
            </TextButton>
          </div>
        </div>
      )
    },
    {
      title: 'Key Dates',
      content: ({ data }) => (
        <div className='grid grid-cols-1 md:grid-cols-3 gap-2'>
          <DateCard date={data.createdDate} label='Created' />
          <DateCard date={data.updatedDate} label='Updated' />
          <DateCard date={data.lastBooked} label='Last Booked' />
        </div>
      )
    },
    {
      title: 'Accounts',
      content: ({ data, onUpdate }) => (
        <div>
          <Field
            Icon={ReceiptText}
            label='On Account'
            value={data.onAccount ? 'Yes' : 'No'}
            onUpdate={onUpdate}
            editField='onAccount'
            dataType='select'
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' }
            ]}
            isEditable={true}
          />
          <Field
            Icon={Clock}
            label='Terms'
            value={
              data.terms != null && data.terms.days !== null ? (
                <div className='flex items-center'>
                  <Badge colour='primary'>{data.terms.days}</Badge>
                  <span className='mx-2 whitespace-nowrap'>days after</span>
                  <Badge colour='primary'>{data.terms.type.type}</Badge>
                </div>
              ) : (
                'N/A'
              )
            }
            editField='terms'
            isEditable={false}
            onUpdate={onUpdate}
            extension={value => {
              return (
                <TextButton size='sm' onClick={handleTermsEdit}>
                  <Pencil size={16} />
                </TextButton>
              );
            }}
          />
          <Field
            Icon={Coins}
            label='Contract'
            value={data.creditContract ? data.creditContract.file_name : 'No'}
            onUpdate={onUpdate}
            isEditable={false}
            extension={value => {
              const [isDownloading, setIsDownloading] = useState(false);

              return (
                <TextButton
                  onClick={async () => {
                    setIsDownloading(true);
                    const baseUrl = 'https://storage.googleapis.com/bohsa-docs';
                    const filePath = data.creditContract.filePath;
                    const link = document.createElement('a');
                    link.href = `${baseUrl}/${filePath}`;
                    link.download = data.creditContract.file_name;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                  disabled={isDownloading}
                >
                  {isDownloading ? (
                    <LoaderCircle size={16} className='animate-spin' />
                  ) : (
                    <Download size={16} />
                  )}
                </TextButton>
              );
            }}
          />
          <Field
            Icon={Coins}
            label='Tradex'
            value={data.tradex ? 'Yes' : 'No'}
            onUpdate={onUpdate}
            editField='tradex'
            dataType='select'
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false }
            ]}
            isEditable={true}
          />
          {data.tradex && (
            <Field
              Icon={Coins}
              label='Tradex Options'
              editField='tradexOption'
              value={data.tradexOption}
              onUpdate={onUpdate}
              dataType='select'
              options={[
                { label: '0', value: 0 },
                { label: '24', value: 24 }
              ]}
              isEditable={true}
            />
          )}

          <Field
            Icon={PoundSterling}
            label='Company Levy'
            value={data.levy ?? 'N/A'}
            editField='levy'
            isEditable={true}
            onUpdate={onUpdate}
            dataType='text'
          />
          <Field
            Icon={MessageSquare}
            label='Account Comment'
            value={data.accountComment}
            editField='accountComment'
            isEditable={true}
            onUpdate={onUpdate}
            dataType='text'
          />
        </div>
      )
    }
  ];

  const additionalTabs = [];

  const alerts = [
    {
      condition: entity => entity?.readOnly === false,
      content: entity => (
        <Alert
          title='Imported Data'
          style='warning'
          description='This is an imported record with limited functionality. To access full features, please convert to an active company account.'
          inline={true}
          actions={[
            {
              text: 'Convert to Live Company',
              onClick: handleConvertClick
            }
          ]}
        />
      )
    },
    {
      condition: entity => entity?.readOnly === true,
      content: entity => (
        <Alert
          title='Read Only Data'
          style='info'
          description='This entity is marked as read only and cannot be changed.'
          inline={true}
        />
      )
    }
  ];

  return (
    <>
      <Entity
        currentUser={currentUser}
        entityType={EntityTypeEnum.ImportedCompany}
        model={ImportedCompany}
        infoItems={infoItems}
        accordionItems={accordionItems}
        additionalTabs={additionalTabs}
        alertItems={alerts}
      />
      <ConvertCompanyPopup
        isOpen={convertPopupOpen}
        onClose={() => setConvertPopupOpen(false)}
        companyId={id}
      />
      <Popup
        isOpen={termsPopupOpen}
        onClose={() => setTermsPopupOpen(false)}
        title='Edit Terms'
        size='sm'
      >
        <div className='space-y-2'>
          <Input
            type='number'
            placeholder='Days'
            value={terms.days}
            onChange={e => setTerms({ ...terms, days: e.target.value })}
          />
          <DropdownInput
            options={termOptions}
            value={terms.type}
            onChange={e => setTerms({ ...terms, type: e.target.value })}
            placeholder='Select Type'
            label='Type'
          />
          <div className='flex justify-end'>
            <FilledButton
              size='md'
              colour='primary'
              onClick={async () => {
                const company = await ImportedCompany.getById(id);
                if (company.readOnly) {
                  addNotification({
                    type: 'error',
                    title: 'Read Only Mode',
                    description:
                      'This entity is in READ ONLY mode, changes have not been saved.',
                    duration: 4000
                  });
                  return;
                }
                company.terms = {
                  days: terms.days,
                  type: { id: terms.type }
                };
                await company.update();
                setTermsPopupOpen(false);
              }}
            >
              Save
            </FilledButton>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default ImportedCompanyDetails;
