import BaseModel from './BaseModel';
import UserProfile from './UserProfile';

/**
 * Represents an Invoice in the system.
 * @extends BaseModel
 */
class Invoice extends BaseModel {
  /** @type {string} The name of the database table for Invoices */
  static table = 'invoices';

  /** @type {string} The SQL query to select Invoice data with related information */
  static selectQuery = `
    *,
    status:core_entity_types!status(id,type,metadata),
    companies!inner(name),
    invoice_address:locations(*)
  `;

  /**
   * Creates an instance of Invoice.
   * @param {Object} data - The invoice data.
   */
  constructor(data = {}) {
    super(data);
    this.company = data.company_id
      ? { id: data.company_id, name: data.companies?.name }
      : null;
    this.status =
      data.status && data.status.id && data.status.type
        ? { id: data.status.id, type: data.status.type }
        : null;
    this.createdDate = data.created_date || null;
    this.updatedDate = data.updated_date || null;
    this.reference = data.reference || null;
    this.issueDate = data.issue_date || null;
    this.dueDate = data.due_date || null;
    this.publishedDate = data.published_date || null;
    this.xero = data.xero || null;
    this.addressId = data.address_id || null;
    this.billingAddress = data.billing_address || null;
    this.subtotal = data.subtotal || null;
    this.totalTax = data.total_tax || null;
    this.total = data.total || null;
  }

  /**
   * Converts the Invoice instance to a database-friendly format.
   * @returns {Object} The invoice data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      company_id: this.company ? this.company.id : null,
      status: this.status.id,
      billing_address: this.billingAddress,
      created_date: this.createdDate,
      updated_date: this.updatedDate
    };
  }
}

export default Invoice;
