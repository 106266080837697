import React from 'react';
import { X } from 'lucide-react';

const Popup = ({ onClose, title, children }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white rounded-lg shadow-lg w-full max-w-5xl">
      <div className="flex justify-between items-center border-b border-neutral-200 p-4">
        <span className="text-lg font-semibold text-neutral-800">{title}</span>
        <button
          onClick={onClose}
          className="text-neutral-500 hover:text-neutral-700"
        >
          <X size={20} />
        </button>
      </div>
      <div className="p-4">{children}</div>
    </div>
  </div>
);

export default Popup;
