import React, { useState } from 'react';
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Calendar as CalendarIcon,
  List,
  MapPin
} from 'lucide-react';

import { TextButton, FilledButton } from '../inputs/buttons/Button';
import { ToggleGroup } from '../inputs/toggles/ToggleGroup';
import { Badge } from '../feedback/badges/Badge';

const Calendar = ({ events, onAddEvent, onEditEvent }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [view, setView] = useState('calendar');
  const [selectedDate, setSelectedDate] = useState(new Date());

  const getDaysInMonth = (year, month) =>
    new Date(year, month + 1, 0).getDate();
  const getFirstDayOfMonth = (year, month) => new Date(year, month, 1).getDay();

  const daysInMonth = getDaysInMonth(
    currentDate.getFullYear(),
    currentDate.getMonth()
  );
  const firstDayOfMonth = getFirstDayOfMonth(
    currentDate.getFullYear(),
    currentDate.getMonth()
  );

  const daysInPrevMonth = getDaysInMonth(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1
  );
  const prevMonthDays = Array.from(
    { length: firstDayOfMonth },
    (_, i) => daysInPrevMonth - firstDayOfMonth + i + 1
  );
  const currentMonthDays = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  const nextMonthDays = Array.from(
    { length: 42 - (firstDayOfMonth + daysInMonth) },
    (_, i) => i + 1
  );

  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const prevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const nextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };

  const goToToday = () => {
    setCurrentDate(new Date());
    setSelectedDate(new Date());
  };

  const handleDateClick = (date, hasMore) => {
    setSelectedDate(date);
    if (hasMore) {
      setView('list');
    }
  };

  const handleEditEvent = event => {
    if (onEditEvent) {
      onEditEvent(event);
    }
  };

  const renderMonthView = () => {
    return (
      <div
        className='flex flex-auto flex-col'
        style={{ height: 'calc(100vh - 67px)' }}
      >
        <div className='grid grid-cols-7 border-b border-base-300 bg-base-100 text-center text-xs font-semibold leading-6 text-base-700'>
          {weekdays.map(day => (
            <div key={day} className='bg-white py-4'>
              <div className='text-center text-xs font-semibold text-base-700'>
                {day.charAt(0)}
                <span className='sr-only sm:not-sr-only'>{day.slice(1)}</span>
              </div>
            </div>
          ))}
        </div>
        <div className='flex-auto bg-base-50 text-xs leading-6 text-base-700'>
          <div className='w-full grid grid-cols-7 grid-rows-6'>
            {[...prevMonthDays, ...currentMonthDays, ...nextMonthDays].map(
              (day, index) => {
                const date = new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth() +
                    (index < firstDayOfMonth
                      ? -1
                      : index >= firstDayOfMonth + daysInMonth
                      ? 1
                      : 0),
                  day
                );
                const dayEvents = events.filter(
                  event =>
                    new Date(event.start).toDateString() === date.toDateString()
                );
                const isCurrentMonth =
                  index >= firstDayOfMonth &&
                  index < firstDayOfMonth + daysInMonth;
                const isToday =
                  date.toDateString() === new Date().toDateString();
                const isSelected =
                  date.toDateString() === selectedDate.toDateString();
                const hasMore = dayEvents.length > 3;
                return (
                  <div
                    key={index}
                    className={`border-r border-b border-base-200 p-2 h-25 overflow-y-auto ${
                      isCurrentMonth ? 'bg-white' : 'bg-base-50'
                    } ${isToday ? 'bg-primary-100' : ''} ${
                      isSelected ? 'bg-info-100' : ''
                    } hover:bg-base-100`}
                    onClick={() => handleDateClick(date, hasMore)}
                  >
                    <div
                      className={`font-regular mb-1 w-6 h-6 rounded-full flex items-center justify-center ${
                        isToday ? 'bg-primary-700 text-white font-semibold' : ''
                      } ${!isCurrentMonth ? 'text-base-400' : ''}`}
                    >
                      {day}
                    </div>
                    {dayEvents.slice(0, 3).map((event, eventIndex) => (
                      <div
                        key={eventIndex}
                        className='text-xs mb-1 flex items-center'
                        onClick={e => {
                          e.stopPropagation();
                          handleEditEvent(event);
                        }}
                      >
                        <span
                          className={`w-2 h-2 min-w-2 rounded-full bg-${event.data.category.metadata.colour}-500 mr-1`}
                        />
                        <span className='truncate flex-grow font-regular hover:text-info-700 cursor-pointer'>
                          {event.title}
                        </span>
                        <span className='ml-1 w-8 min-w-8 text-end text-base-400 font-regular'>
                          {new Date(event.start).toLocaleTimeString([], {
                            hour: 'numeric',
                            hour12: true
                          })}
                        </span>
                      </div>
                    ))}
                    {hasMore && (
                      <div
                        className='text-xs text-base-600 cursor-pointer'
                        onClick={e => {
                          e.stopPropagation();
                          handleDateClick(date, true);
                        }}
                      >
                        +{dayEvents.length - 3} more
                      </div>
                    )}
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderListView = () => {
    const selectedEvents = events.filter(
      event =>
        new Date(event.start).toDateString() === selectedDate.toDateString()
    );

    return (
      <div className='grid grid-cols-8 lg:gap-x-8 p-6 w-full'>
        {/* Calendar */}
        <div className='max-md:col-span-8 md:max-lg:col-span-3 lg:max-xl:col-span-8 xl:col-span-3'>
          <div className='flex justify-between rounded-md md:items-stretch w-full'>
            <TextButton onClick={prevMonth} colour='base'>
              <ChevronLeft className='w-5 h-5' />
            </TextButton>
            <TextButton onClick={goToToday} colour='base'>
              {currentDate.toLocaleString('default', { month: 'long' })}
            </TextButton>
            <TextButton onClick={nextMonth} colour='base'>
              <ChevronRight className='w-5 h-5' />
            </TextButton>
          </div>
          <div className='mt-6 grid grid-cols-7 text-xs leading-6 text-base-500 w-full'>
            {weekdays.map(day => (
              <div key={day} className='text-center font-semibold'>
                {day.charAt(0).toUpperCase()}
              </div>
            ))}
          </div>
          <div className='isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-base-200 text-sm shadow ring-1 ring-base-200 w-full overflow-hidden'>
            {[...prevMonthDays, ...currentMonthDays, ...nextMonthDays].map(
              (day, index) => {
                const date = new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth() +
                    (index < firstDayOfMonth
                      ? -1
                      : index >= firstDayOfMonth + daysInMonth
                      ? 1
                      : 0),
                  day
                );
                const isCurrentMonth =
                  index >= firstDayOfMonth &&
                  index < firstDayOfMonth + daysInMonth;
                const isToday =
                  date.toDateString() === new Date().toDateString();
                const isSelected =
                  date.toDateString() === selectedDate.toDateString();
                const hasEvents = events.some(
                  event =>
                    new Date(event.start).toDateString() === date.toDateString()
                );

                return (
                  <button
                    key={index}
                    type='button'
                    onClick={() => handleDateClick(date, false)}
                    className={`py-1.5 hover:bg-base-100 focus:z-10 ${
                      isCurrentMonth ? 'bg-white' : 'bg-base-50 text-base-400'
                    } ${isToday ? 'font-semibold text-primary-600' : ''} ${
                      isSelected ? 'bg-info-100' : ''
                    }`}
                  >
                    <time
                      dateTime={`${date.getFullYear()}-${String(
                        date.getMonth() + 1
                      ).padStart(2, '0')}-${String(day).padStart(2, '0')}`}
                      className={`mx-auto flex h-7 w-7 items-center justify-center rounded-full ${
                        isToday ? 'bg-primary-600 font-semibold text-white' : ''
                      } ${
                        isSelected && !isToday
                          ? 'bg-info-300 font-semibold text-info-700'
                          : ''
                      }`}
                    >
                      {day}
                    </time>
                    {hasEvents && (
                      <div className='w-1 h-1 bg-primary-500 rounded-full mx-auto mt-1'></div>
                    )}
                  </button>
                );
              }
            )}
          </div>
          <div className='hidden max-sm:block mt-6 flex w-full'>
            <FilledButton
              colour='primary'
              onClick={onAddEvent}
              className='text-center w-full items-center justify-center'
            >
              Add event
            </FilledButton>
          </div>
        </div>
        {/* List */}
        <ol className='mt-8 max-md:col-span-8 md:max-lg:col-span-5 lg:max-xl:col-span-5 xl:col-span-3 w-full divide-y divide-base-100 text-sm leading-6'>
          {selectedEvents.length === 0 ? (
            <li className='py-6 text-center text-base-500 list-none'>
              No events for this date
            </li>
          ) : (
            selectedEvents.map((event, index) => (
              <li
                key={index}
                className='space-x-6 py-6 xl:static'
                onClick={() => handleEditEvent(event)}
              >
                <div className='flex-auto'>
                  <div className='flex items-center pr-10 xl:pr-0'>
                    <h4 className='font-semibold text-sm text-base-900 mr-2 hover:text-info-700 cursor-pointer'>
                      {event.title}
                    </h4>
                    <Badge
                      colour={event.data.category.metadata.colour}
                      size='xs'
                    >
                      {event.data.category.type}
                    </Badge>
                  </div>
                  <dl className='mt-2 flex flex-col text-base-500 xl:flex-row'>
                    <div className='flex items-center space-x-3'>
                      <dt>
                        <span className='sr-only'>Date</span>
                        <CalendarIcon
                          className='h-4 w-4 text-base-400'
                          aria-hidden='true'
                        />
                      </dt>
                      <dd>
                        <time dateTime={event.start}>
                          {new Date(event.start).toLocaleDateString('en-GB', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric'
                          })}{' '}
                          at{' '}
                          {new Date(event.start).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                        </time>
                      </dd>
                    </div>
                    {event.data.location &&
                      event.data.category.metadata.type !== 'virtual' && (
                        <div className='mt-2 flex items-center space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5'>
                          <dt>
                            <span className='sr-only'>Location</span>
                            <MapPin
                              className='h-4 w-4 text-gray-400'
                              aria-hidden='true'
                            />
                          </dt>
                          <dd>{event.data.location}</dd>
                        </div>
                      )}
                  </dl>
                </div>
              </li>
            ))
          )}
        </ol>
      </div>
    );
  };

  return (
    <div className='flex h-full flex-col border rounded-md bg-base-50'>
      <header className='flex items-center justify-between border-b border-base-200 p-4 lg:flex-none top-0 bg-white'>
        <h4 className='text-base font-semibold leading-6 text-base-800'>
          {view === 'calendar'
            ? currentDate.toLocaleString('default', {
                month: 'long',
                year: 'numeric'
              })
            : selectedDate.toLocaleString('default', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
              })}
        </h4>
        <div className='flex items-center'>
          <div className='hidden md:block lg:hidden xl:block'>
            <ToggleGroup
              options={[
                { value: 'calendar', icon: CalendarIcon },
                { value: 'list', icon: List }
              ]}
              onChange={value => setView(value)}
            />
          </div>

          <div className='ml-4 relative flex rounded-md bg-white border border-base-200 shadow-sm md:items-stretch'>
            <TextButton onClick={prevMonth} colour='base'>
              <ChevronLeft className='w-5 h-5' />
            </TextButton>
            <TextButton onClick={goToToday} colour='base'>
              Today
            </TextButton>
            <TextButton onClick={nextMonth} colour='base'>
              <ChevronRight className='w-5 h-5' />
            </TextButton>
          </div>
          <div className='hidden ml-2 sm:flex sm:items-center'>
            <div className='hidden sm:block mx-0 sm:mx-6 sm:h-6 w-px bg-base-300' />
            <FilledButton colour='primary' onClick={onAddEvent}>
              Add event
            </FilledButton>
          </div>
        </div>
      </header>
      <div className='hidden md:max-lg:flex xl:flex'>
        {view === 'calendar' ? renderMonthView() : renderListView()}
      </div>
      <div className='hidden max-md:flex lg:max-xl:flex'>
        {renderListView()}
      </div>
    </div>
  );
};

export { Calendar };
