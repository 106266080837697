import React from 'react';
import { Modal, TextButton, FilledButton } from 'core';

const DeleteTagModal = ({ isOpen, onClose, onDelete, tag }) => {
  const handleDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Delete Tag'>
      <div className='space-y-4'>
        <p className='text-gray-700'>
          Are you sure you want to delete this tag? This action cannot be
          undone.
        </p>
        <div className='flex justify-end space-x-2'>
          <TextButton onClick={onClose}>Cancel</TextButton>
          <FilledButton onClick={handleDelete} colour='danger'>
            Delete Tag
          </FilledButton>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteTagModal;
