import { useState, useEffect, useContext } from 'react';
import { supabase } from '../../../utilities/supabase';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrimaryInput } from '../../../components/inputs/Forms';
import { FilledButton } from 'core';
import PasswordInput from '../../../components/inputs/Password';
import Auth from '../Auth';
import UserProfile from '../../../models/UserProfile';
import { UserProfileContext } from '../../../App';

const Signup = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [profile, setProfile] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [hasStarted, setHasStarted] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    confirmPassword: false
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { setUserProfile } = useContext(UserProfileContext);

  useEffect(() => {
    const profileFromUrl = new URLSearchParams(location.search).get('profile');
    setProfile(profileFromUrl);
  }, [location]);

  useEffect(() => {
    if (hasStarted.confirmPassword) {
      const passwordsMatch = formData.password === formData.confirmPassword;
      setFormErrors(prev => ({
        ...prev,
        confirmPassword: passwordsMatch ? null : "Passwords don't match"
      }));
    }
  }, [formData.password, formData.confirmPassword, hasStarted.confirmPassword]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setHasStarted(prev => ({ ...prev, [name]: true }));

    if (name === 'email') {
      const isValid = validateEmail(value);
      setFormErrors(prev => ({
        ...prev,
        email: isValid ? null : 'A valid email is required'
      }));
    } else if (
      ['firstName', 'lastName', 'password'].includes(name) &&
      value.trim()
    ) {
      setFormErrors(prev => {
        const { [name]: _, ...rest } = prev;
        return rest;
      });
    }
  };

  const validateEmail = email => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSignup = async e => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setFormErrors({});

    const errors = {};
    if (!formData.firstName.trim()) errors.firstName = 'First name is required';

    if (!formData.lastName.trim()) errors.lastName = 'Last name is required';

    if (!formData.email.trim()) errors.email = 'Email is required';
    else if (!validateEmail(formData.email))
      errors.email = 'A valid email is required';

    if (!formData.password.trim()) errors.password = 'Password is required';

    if (formData.password !== formData.confirmPassword)
      errors.confirmPassword = "Passwords don't match";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setLoading(false);

      return;
    }

    try {
      const { data, error } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
        options: {
          data: {
            first_name: formData.firstName,
            last_name: formData.lastName,
            profile
          }
        }
      });

      if (error) throw error;

      // Fetch the new UserProfile
      const { data: userProfiles, error: profileError } =
        await UserProfile.getByAuthId(data.user.id);

      if (profileError) throw profileError;

      if (userProfiles && userProfiles.length > 0) {
        // Set the user profile in the app context
        setUserProfile(userProfiles[0]);

        // Navigate to the dashboard or appropriate page
        navigate('/');
      } else {
        throw new Error('User profile not found after signup');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
      console.error('Signup error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Auth title='Create Account' onSubmit={handleSignup} type='signup'>
      <div className='flex space-x-4'>
        <div className='flex-1'>
          <PrimaryInput
            id='first-name'
            name='firstName'
            type='text'
            placeholder='Enter your first name'
            value={formData.firstName}
            onChange={handleInputChange}
            label='First Name'
            error={formErrors.firstName}
          />
        </div>
        <div className='flex-1'>
          <PrimaryInput
            id='last-name'
            name='lastName'
            type='text'
            placeholder='Enter your last name'
            value={formData.lastName}
            onChange={handleInputChange}
            label='Last Name'
            error={formErrors.lastName}
          />
        </div>
      </div>
      <PrimaryInput
        id='email'
        name='email'
        type='text'
        placeholder='Enter your email'
        value={formData.email}
        onChange={handleInputChange}
        label='Email'
        error={formErrors.email}
      />
      <PasswordInput
        id='password'
        name='password'
        type='password'
        placeholder='Enter your password'
        value={formData.password}
        onChange={handleInputChange}
        label='Password'
        error={formErrors.password}
      />
      <PrimaryInput
        id='confirm-password'
        name='confirmPassword'
        type='password'
        placeholder='Confirm your password'
        value={formData.confirmPassword}
        onChange={handleInputChange}
        label='Confirm Password'
        error={formErrors.confirmPassword}
      />
      {error && <p className='text-red-500 text-sm'>{error}</p>}
      <div>
        <FilledButton
          type='submit'
          className='w-full'
          colour='primary'
          size='md'
        >
          {loading ? 'Signing up...' : 'Sign Up'}
        </FilledButton>
      </div>
    </Auth>
  );
};

export default Signup;
