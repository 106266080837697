import React, { useState, useEffect } from 'react';
import { CheckCircle, AlertTriangle, Info, X } from 'lucide-react';

const NotificationItem = ({
  type = 'success',
  duration = 4000,
  onClose,
  title,
  description
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isLeaving, setIsLeaving] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  const handleClose = () => {
    setIsLeaving(true);
    setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, 300);
  };

  if (!isVisible) return null;

  const getIcon = () => {
    switch (type) {
      case 'success':
        return <CheckCircle size={18} className='h-4 w-4 text-success-700' />;
      case 'warning':
        return <AlertTriangle className='h-4 w-4 text-warning-700' />;
      case 'error':
        return <AlertTriangle className='h-4 w-4 text-danger-700' />;
      default:
        return <Info className='h-4 w-4 text-brand-700' />;
    }
  };

  const animationClasses = isLeaving ? 'animate-leave' : 'animate-enter';

  return (
    <div
      className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 ${animationClasses}`}
    >
      <div className='p-4'>
        <div className='flex items-start'>
          <div className='flex-shrink-0'>{getIcon()}</div>
          <div className='ml-3 w-0 flex-1 pt-0.5'>
            <p className='text-sm font-medium text-gray-900'>{title}</p>
            {description && (
              <p className='mt-1 text-sm text-gray-500'>{description}</p>
            )}
          </div>
          <div className='ml-4 flex flex-shrink-0'>
            <button
              type='button'
              onClick={handleClose}
              className='inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
            >
              <span className='sr-only'>Close</span>
              <X className='h-5 w-5' aria-hidden='true' />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { NotificationItem };
