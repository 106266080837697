import React from 'react';

const InputBase = ({
  id,
  name,
  type = 'text',
  value,
  onChange,
  placeholder,
  label,
  error,
  className,
  borderColor,
  focusBorderColor,
  focusRingColor,
  errorBorderColor,
  errorRingColor,
  size = 'medium',
  disabled = false,
  ...props
}) => {
  const sizeClasses = {
    small: 'text-xs py-0.5 px-1 rounded', // 2x4
    medium: 'text-sm py-1 px-2 rounded-md min-h-[34px]', // 4x8
    large: 'text-base py-2 px-3 rounded-lg', // Default 8x12
    xlarge: 'text-lg py-3 px-4 rounded-lg' // 12x16
  };

  const baseClasses = `
    block w-full 
    border rounded-md shadow-sm 
    focus:outline-none focus:ring-1 
    transition duration-150 ease-in-out
    sm:text-sm
  `;

  const themedClasses = `
    ${borderColor || 'border-neutral-100'}
    ${focusBorderColor || 'focus:border-brand-500'}
    ${focusRingColor || 'focus:ring-brand-500'}
    ${error ? errorBorderColor || 'border-danger-500' : ''}
    ${error ? errorRingColor || 'focus:ring-danger-500' : ''}
    ${disabled ? 'bg-neutral-100' : ''}
  `;

  return (
    <>
      {label && (
        <label
          htmlFor={id}
          className='block text-sm font-medium text-neutral-700 mb-1'
        >
          {label}
        </label>
      )}
      <input
        id={id}
        name={name}
        type={type}
        defaultValue={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`${baseClasses} ${themedClasses} ${sizeClasses[size]} ${
          className || ''
        }`}
        disabled={disabled}
        {...props}
      />
      {error && <p className='mt-1 text-sm text-danger-700'>{error}</p>}
    </>
  );
};

const PrimaryInput = props => (
  <InputBase
    {...props}
    borderColor='border-neutral-100'
    focusBorderColor='focus:border-brand-700'
    focusRingColor='focus:ring-brand-700'
    errorBorderColor='border-danger-700'
    errorRingColor='focus:ring-danger-700'
  />
);

const SecondaryInput = props => (
  <InputBase
    {...props}
    borderColor='border-neutral-100'
    focusBorderColor='focus:border-secondary-300'
    focusRingColor='focus:ring-secondary-700'
    errorBorderColor='border-danger-700'
    errorRingColor='focus:ring-danger-700'
  />
);

const DropdownInput = ({
  id,
  name,
  value,
  onChange,
  options,
  label,
  error,
  className,
  size = 'medium',
  disabled = false,
  ...props
}) => {
  const sizeClasses = {
    small: 'text-xs py-0.5 px-1 rounded', // 2x4
    medium: 'text-sm py-1 px-2 rounded-md', // 4x8
    large: 'text-base py-2 px-3 rounded-lg', // Default 8x12
    xlarge: 'text-lg py-3 px-4 rounded-lg' // 12x16
  };

  const baseClasses = `
    block w-full 
    border rounded-md shadow-sm 
    focus:outline-none focus:ring-1 
    transition duration-150 ease-in-out
    sm:text-sm
  `;

  const themedClasses = `
    border-neutral-100
    focus:border-brand-700
    focus:ring-brand-700
    ${error ? 'border-danger-700' : ''}
    ${error ? 'focus:ring-danger-700' : ''}
    ${disabled ? 'bg-neutral-100' : ''}
  `;

  return (
    <div>
      {label && (
        <label
          htmlFor={id}
          className='block text-sm font-medium text-neutral-700 mb-1'
        >
          {label}
        </label>
      )}
      <select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className={`${baseClasses} ${themedClasses} ${sizeClasses[size]} ${
          className || ''
        }`}
        disabled={disabled}
        {...props}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <p className='mt-1 text-sm text-danger-600'>{error}</p>}
    </div>
  );
};

export { PrimaryInput, SecondaryInput, DropdownInput };
