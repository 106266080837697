import React, { useState, useEffect } from 'react';
import {
  Popup,
  Input,
  DropdownInput,
  FilledButton,
  TextSkeleton,
  SoftButton,
  Modal
} from 'core';
import AdditionalItem from '../../../models/AdditionalItem';
import CoreEntityTypes from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import Booking from '../../../models/Booking';
import Invoice from '../../../models/Invoice';
import InvoiceLine from '../../../models/InvoiceLine';

const EditAdditionalItem = ({
  isOpen,
  onClose,
  bookingId,
  itemId,
  onItemUpdated
}) => {
  const [item, setItem] = useState({
    description: '',
    itemTypeId: '',
    statusId: '',
    vatRate: '',
    amount: ''
  });
  const [itemTypes, setItemTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [booking, setBooking] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State for delete confirmation modal

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [itemTypeOptions, fetchedBooking, fetchedItem] =
          await Promise.all([
            CoreEntityTypes.getByEntityId(EntityTypeEnum.AdditionalItemType),
            Booking.getById(bookingId),
            AdditionalItem.getById(itemId)
          ]);

        setItemTypes(
          itemTypeOptions.map(option => ({
            value: option.id,
            label: option.type
          }))
        );
        setBooking(fetchedBooking);
        setItem({
          description: fetchedItem.description,
          itemTypeId: fetchedItem.itemType.id,
          vatRate: fetchedItem.vatRate,
          amount: fetchedItem.amount
        });

        if (fetchedBooking.invoiceId) {
          const fetchedInvoice = await Invoice.getById(
            fetchedBooking.invoiceId
          );
          setInvoice(fetchedInvoice);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [bookingId, itemId]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    let tempVal = value;
    // If the item type is a credit note, convert it to a negative number
    if (name === 'amount' && item.itemTypeId == 134 && tempVal > 0) {
      tempVal = 0 - value;
    }
    if (item.itemTypeId == 134 && item.amount > 0) {
      setItem(prev => ({ ...prev, amount: 0 - item.amount }));
    }
    setItem(prev => ({ ...prev, [name]: tempVal }));
  };

  const handleUpdateItem = async () => {
    try {
      const updatedItem = new AdditionalItem({
        id: itemId,
        booking: bookingId,
        description: item.description,
        item_type: { id: item.itemTypeId },
        vat_rate: item.vatRate,
        amount: item.amount
      });

      // Set invoice_id if booking has an invoice and its status is not sent
      if (booking?.invoiceId && invoice && invoice.status !== 220) {
        updatedItem.invoiceId = booking.invoiceId;
      }

      await updatedItem.update();
      onItemUpdated(updatedItem);
      onClose();
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleDeleteItem = async () => {
    try {
      const invoiceLines = await InvoiceLine.getAll({
        additional_id: { operator: 'eq', value: itemId }
      });

      if (invoiceLines.data && invoiceLines.data.length > 0) {
        await Promise.all(invoiceLines.data.map(line => line.delete()));
      }
      const tempItem = await AdditionalItem.getById(itemId);
      console.log(tempItem);
      await tempItem.delete();
      onItemUpdated(null); // Notify parent that item has been deleted
      onClose();
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  return (
    <>
      <Popup isOpen={isOpen} onClose={onClose} title='Edit Additional Item'>
        <div className='space-y-4'>
          {isLoading ? (
            <>
              <TextSkeleton className='w-full h-10' />
              <TextSkeleton className='w-full h-10' />
              <TextSkeleton className='w-full h-10' />
              <TextSkeleton className='w-full h-10' />
              <TextSkeleton className='w-full h-10' />
            </>
          ) : (
            <>
              <DropdownInput
                label='Item Type'
                name='itemTypeId'
                value={item.itemTypeId}
                onChange={handleInputChange}
                options={itemTypes}
                required
              />
              <Input
                label='Description'
                name='description'
                value={item.description}
                onChange={handleInputChange}
              />
              {item.itemTypeId != 137 && (
                <>
                  <Input
                    label='VAT Rate'
                    name='vatRate'
                    type='number'
                    value={item.vatRate}
                    onChange={handleInputChange}
                    required
                  />
                  <Input
                    label='Amount'
                    name='amount'
                    type='number'
                    value={item.amount}
                    onChange={handleInputChange}
                    required
                    hint={
                      item.itemTypeId === 134
                        ? 'Credit notes are automatically converted to a negative value.'
                        : ''
                    }
                  />
                </>
              )}
              <div className='flex justify-end space-x-2'>
                <FilledButton onClick={onClose} colour='base'>
                  Cancel
                </FilledButton>
                <SoftButton
                  onClick={() => setIsDeleteModalOpen(true)}
                  colour='danger'
                >
                  Delete
                </SoftButton>
                <FilledButton onClick={handleUpdateItem} colour='primary'>
                  Update Item
                </FilledButton>
              </div>
            </>
          )}
        </div>
      </Popup>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        title='Confirm Deletion'
        actions={[
          {
            label: 'Delete',
            onClick: handleDeleteItem,
            style: 'danger'
          },
          {
            label: 'Cancel',
            onClick: () => setIsDeleteModalOpen(false),
            style: 'base'
          }
        ]}
      >
        <p>Are you sure you want to delete this item?</p>
      </Modal>
    </>
  );
};

export default EditAdditionalItem;
