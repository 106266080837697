import React from 'react';
import { useNavigate } from 'react-router-dom';
import Trainer from '../../../models/Trainer';
import EntityTable from '../../../components/tables/EntityTable';
import { supabase } from '../../../utilities/supabase';
import { formatDate } from '../../../utilities/Formatting';
import { Link } from 'react-router-dom';
import { Star, Pencil, Trash, SquareArrowOutUpRight } from 'lucide-react';
import { EntityTypeEnum } from '../../../utilities/Enumerables';

const Trainers = () => {
  //Set the page title
  document.title = 'Trainers | CRM | BOHSA LTD';
  const navigate = useNavigate();

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/trainers/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => navigate(`/trainers/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const handleRowClick = data => {
    navigate(`/trainers/${data.id}`);
  };

  return (
    <div className='p-4'>
      <EntityTable
        name='Trainers'
        filter={[
          { field: 'id', label: 'ID', type: 'text' },
          { field: 'name', label: 'Trainer Name', type: 'text' },
          { field: 'status', label: 'Status', type: 'text' },
          { field: 'approved', label: 'Approved', type: 'boolean' },
          { field: 'created_date_from', label: 'Created From', type: 'date' },
          { field: 'created_date_to', label: 'Created To', type: 'date' }
        ]}
        sort={[
          { value: 'name', label: 'Trainer Name' },
          { value: 'created_date', label: 'Date Created' },
          { value: 'updated_date', label: 'Last Modified' }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/trainers/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          { label: 'Trainer Name', field: 'name' },
          { label: 'Status', field: 'statusName' },
          {
            label: 'Approved',
            field: 'approved',
            cell: value => (value ? 'Yes' : 'No')
          },
          {
            label: 'Rating',
            field: 'rating',
            cell: value => {
              const stars = [];
              for (let i = 0; i < 5; i++) {
                stars.push(
                  i < value ? (
                    <Star key={i} size={18} fill='#FFD700' color='#FFD700' />
                  ) : (
                    <Star key={i} size={18} color='#D3D3D3' />
                  )
                );
              }
              return <div className='flex'>{stars}</div>;
            }
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={Trainer}
        entityType={EntityTypeEnum.Trainer}
        onRowClick={handleRowClick}
        canCreate={true}
      />
    </div>
  );
};

export default Trainers;
