import { useState, useEffect, useContext } from 'react';
import { Check, X, Pencil } from 'lucide-react';
import {
  FilledButton,
  SoftButton,
  UserSelector,
  UserProfilePicture,
  Input,
  DropdownInput,
  ComboBox,
  Badge,
  DatePicker
} from 'core';
import { UserProfileContext } from '../../App';

const Field = ({
  Icon,
  label,
  value,
  valueMetadata,
  editField,
  isEditable,
  onUpdate,
  dataType = 'text', // text, select, date, search, user-select, link
  options,
  loadOptions,
  extension,
  inlineLabel = true,
  inlineEditor = true
}) => {
  const [editing, setEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);
  const { userProfile } = useContext(UserProfileContext);

  useEffect(() => {
    setEditValue(value);
  }, [value]);

  const handleUserSelect = selectedUser => {
    setEditValue(selectedUser);
  };

  const handleUpdate = () => {
    let valueToUpdate = editValue;

    // If the dataType is 'search' and editValue is an object, extract the value
    if (
      (dataType === 'search' || dataType === 'select') &&
      typeof editValue === 'object' &&
      editValue.value
    ) {
      valueToUpdate = editValue.value;
    }

    onUpdate(editField, valueToUpdate);
    setEditing(false);
  };

  const renderEditInput = () => {
    switch (dataType) {
      case 'user-select':
        return (
          <UserSelector
            users={options}
            selectedUser={editValue}
            onSelect={handleUserSelect}
          />
        );
      case 'select':
        return (
          <DropdownInput
            value={editValue}
            onChange={value => {
              console.log(
                `Changing ${editField} from ${editValue} to ${value.target.value}`
              );
              setEditValue(value.target.value);
            }}
            options={options}
            className='flex-grow'
          />
        );
      case 'date':
        return (
          <DatePicker
            value={editValue}
            onChange={date => setEditValue(date)}
            placeholder="Select date"
            className='flex-grow'
          />
        );
      case 'search':
        return (
          <ComboBox
            options={options}
            loadOptions={loadOptions}
            value={editValue}
            onChange={setEditValue}
            placeholder='Search...'
            className='flex-grow'
          />
        );
      case 'text':
      default:
        return (
          <Input
            type='text'
            value={editValue}
            onChange={e => setEditValue(e.target.value)}
            size='md'
            className='flex-grow'
          />
        );
    }
  };

  const renderLabelContent = () => (
    <>
      <Icon size={16} strokeWidth={1.8} className='text-base-500 mr-2 leading-6' />
      <span
        className={`text-sm font-medium text-base-950 ${
          inlineLabel ? `w-32` : 'flex-grow'
        }`}
      >
        {label}
      </span>
    </>
  );

  const renderFieldContent = () => (
    <div className='flex items-center w-full'>
      <span className='text-base-600 flex-grow whitespace-normal'>
        <span className='inline-block w-full text-sm'>
          {dataType === 'followers' ? (
            <div className='flex items-center'>
              <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 mr-2'>
                {value.length} followers
              </span>
              <SoftButton
                size='sm'
                onClick={() => {
                  const isFollowing = value.includes(userProfile.id);
                  const newFollowers = isFollowing
                    ? value.filter(id => id !== userProfile.id)
                    : [...value, userProfile.id];
                  onUpdate(editField, newFollowers);
                }}
                className={`transition-colors duration-200 ${
                  value.includes(userProfile.id)
                    ? 'hover:bg-danger-100 hover:text-danger-700'
                    : ''
                }`}
              >
                {value.includes(userProfile.id) ? 'Unfollow' : 'Follow'}
              </SoftButton>
            </div>
          ) : dataType === 'user-select' ? (
            <div className='flex items-center gap-x-1'>
              <UserProfilePicture
                firstName={value?.firstName ?? ''}
                lastName={value?.lastName ?? ''}
                profilePicture={value?.profilePhoto ?? ''}
                size='sm'
              />
              <span>{value?.fullName ?? 'N/A'}</span>
            </div>
          ) : dataType === 'link' ? (
            <a
              href={valueMetadata.url}
              target='_blank'
              rel='noopener noreferrer'
              className='text-brand-600 hover:underline'
            >
              {value}
            </a>
          ) : dataType === 'currency' ? (
            `£${parseFloat(value).toFixed(2)}`
          ) : dataType === 'date' ? (
            value ? new Date(value).toLocaleDateString() : 'Not set'
          ) : (
            value
          )}
        </span>
      </span>
      {extension && extension(value, editValue, setEditValue)}
    </div>
  );

  const renderButtons = () => (
    <div className='flex ml-2'>
      {editing && isEditable ? (
        <>
          <FilledButton
            onClick={handleUpdate}
            colour='base'
            size='sm'
            className='mr-2'
          >
            <Check size={18} />
          </FilledButton>
          <FilledButton
            onClick={() => {
              setEditValue(value);
              setEditing(false);
            }}
            colour='base'
            size='sm'
          >
            <X size={18} />
          </FilledButton>
        </>
      ) : (
        isEditable && (
          <SoftButton
            onClick={() => setEditing(true)}
            colour='base'
            size='sm'
            className='lg:opacity-0 lg:group-hover:opacity-100 transition-opacity duration-200'
          >
            <Pencil size={18} />
          </SoftButton>
        )
      )}
    </div>
  );

  const renderContent = () => (
    <>
      <div className={`flex flex-col mb-4 group`}>
        <div className={`flex items-center mb-1`}>
          {renderLabelContent()}
          {inlineLabel && (
            <div className='flex-grow'>
              {!inlineEditor && !editing && renderFieldContent()}
              {inlineEditor &&
                (!editing ? renderFieldContent() : renderEditInput())}
            </div>
          )}
          {renderButtons()}
        </div>
        {!inlineLabel &&
          (!editing ? (
            <div className='w-full'>{renderFieldContent()}</div>
          ) : (
            <div className='w-full'>{renderEditInput()}</div>
          ))}
        {inlineLabel && !inlineEditor && editing && (
          <div className='w-full'>{renderEditInput()}</div>
        )}
      </div>
    </>
  );

  return renderContent();
};

export default Field;
