import React, { useState, useEffect } from 'react';
import { Modal, TextButton, FilledButton, Input, ColourPicker, Checkbox } from 'core';

const EditTagModal = ({ isOpen, onClose, onEdit, tag }) => {
  const [name, setName] = useState('');
  const [color, setColor] = useState('zinc');
  const [showOnPipelines, setShowOnPipelines] = useState(false);

  useEffect(() => {
    if (tag) {
      setName(tag.name);
      setColor(tag.colour);
      setShowOnPipelines(tag.pipelines || false);
    }
  }, [tag]);

  const handleSubmit = () => {
    onEdit(name, color, showOnPipelines);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title='Edit Tag'>
      <div className='space-y-4'>
        <Input
          label='Tag Name'
          placeholder='Enter tag name'
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <div>
          <label className='block text-sm font-medium text-gray-700 mb-1'>
            Tag Color
          </label>
          <ColourPicker
            showShades={false}
            colour={color}
            onChange={colour => setColor(colour)}
          />
        </div>
        <Checkbox
          label='Show on pipelines'
          checked={showOnPipelines}
          onChange={e => setShowOnPipelines(e.target.checked)}
        />
        <div className='flex justify-end space-x-2'>
          <TextButton onClick={onClose}>Cancel</TextButton>
          <FilledButton onClick={handleSubmit} disabled={!name}>
            Save Changes
          </FilledButton>
        </div>
      </div>
    </Modal>
  );
};

export default EditTagModal;
