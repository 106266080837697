import React from 'react';
import { useNavigate } from 'react-router-dom';
import Quote from '../../../models/Quote';
import EntityTable from '../../../components/tables/EntityTable';
import { Alert } from 'core';
import { formatDate, formatProfile } from '../../../utilities/Formatting';
import { Link } from 'react-router-dom';
import { Pencil, Trash, UserRound } from 'lucide-react';
import { EntityTypeEnum } from '../../../utilities/Enumerables';
import { useContext } from 'react';
import { UserProfileContext } from '../../../App';
import UserProfile from '../../../models/UserProfile';
import { PERMISSIONS } from '../../../utilities/Permissions';

const Quotes = () => {
  //Set the page title
  document.title = 'Quotes | CRM | BOHSA LTD';
  const navigate = useNavigate();
  const currentUser = new UserProfile(useContext(UserProfileContext).userProfile);

  const menuItems = [
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => navigate(`/quotes/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const handleRowClick = data => {
    navigate(`/quotes/${data.id}`);
  };

  //Test user permissions for view access
  if (!currentUser.hasPermission(EntityTypeEnum.Quote, PERMISSIONS[EntityTypeEnum.Quote].VIEW)) {
    return (
      <Alert
        title="Permission Denied"
        description="You do not have permission to view quotes."
        style="danger"
      />
    );
  }
  return (
    <div className='p-4'>
      <EntityTable
        name='Quotes'
        filter={[
          { field: 'id', label: 'ID', type: 'text' },
          { field: 'companies.name', label: 'Company', type: 'text' },
          { field: 'user_profiles.first_name', label: 'Owner', type: 'text' },
          { field: 'course_name', label: 'Course', type: 'text' },
          { field: 'status', label: 'Status', type: 'text' },
          { field: 'created_date_from', label: 'Created From', type: 'date' },
          { field: 'created_date_to', label: 'Created To', type: 'date' },
          {
            field: 'requested_date_from',
            label: 'Requested From',
            type: 'date'
          },
          { field: 'requested_date_to', label: 'Requested To', type: 'date' }
        ]}
        sort={[
          { value: 'id', label: 'Quote ID' },
          { value: 'companies.name', label: 'Company Name' },
          { value: 'course_name', label: 'Course Name' },
          { value: 'created_date', label: 'Date Created' },
          { value: 'requested_date', label: 'Requested Date' },
          { value: 'status', label: 'Status' }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/quotes/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          { label: 'Company', field: 'company.name' },
          {
            label: 'Owner',
            field: 'owner',
            cell: value => formatProfile(value)
          },
          { label: 'Course', field: 'courseName' },
          { label: 'Status', field: 'status.name' },
          { label: 'Delegates', field: 'delegates' },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Requested Date',
            field: 'requestedDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={Quote}
        entityType={EntityTypeEnum.Quote}
        onRowClick={handleRowClick}
        canCreate={true}
      />
    </div>
  );
};

export default Quotes;
