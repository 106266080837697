import BaseModel from './BaseModel';

/**
 * Represents a User Profile in the system.
 * @extends BaseModel
 */
class UserProfile extends BaseModel {
  /** @type {string} The name of the database table for User Profiles */
  static table = 'user_profiles';

  /** @type {string} The SQL query to select User Profile data */
  static selectQuery = '*';

  /**
   * Creates an instance of UserProfile.
   * @param {Object} data - The user profile data.
   */
  constructor(data = {}) {
    super(data);
    this.auth = data.auth || null;
    this.firstName = data.first_name || null;
    this.lastName = data.last_name || null;
    this.fullName = data.full_name || null;
    this.email = data.email || null;
    this.phones = data.phones || [];
    this.profilePhoto = data.profile_photo || null;
    this.company = data.company_id || null;
    this.levy = data.levy || null;
    this.permissions = data.permissions || {};
    this.metrics = data.metrics || {};
    this.active = data.active || false;
    this.pipelines = data.pipelines || null;
    this.displayId = data.display_id || null; // New column for display_id
  }

  /**
   * Converts the UserProfile instance to a database-friendly format.
   * @returns {Object} The user profile data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      auth: this.auth,
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email,
      phones: this.phones,
      profile_photo: this.profilePhoto,
      active: this.active,
      metrics: this.metrics,
      pipelines: this.pipelines,
      display_id: this.displayId // Include display_id in the database format
    };
  }

  /**
   * Get a record by its ID.
   * @param {string|number} id - The ID of the record to fetch.
   * @returns {Promise<BaseModel>} A promise that resolves to a new instance of the model.
   */
  static async getById(id) {
    return this.fetchOne('display_id', id);
  }

  /**
   * Fetch a user profile by auth ID.
   * @param {string} id - The auth ID to fetch.
   * @returns {Promise<UserProfile>} A promise that resolves to a new instance of UserProfile.
   */
  static async getByAuthId(id, activeOnly = false) {
    const filters = { auth: { value: id } };
    if (activeOnly) {
      filters.active = { value: true };
    }
    return this.getAll(filters);
  }

  /**
   * Search for user profiles.
   * @param {string} searchTerm - The term to search for.
   * @param {number} page - The page number.
   * @param {number} size - The page size.
   * @returns {Promise<Object>} A promise that resolves to an object containing the search results and pagination info.
   */
  static async search(searchTerm, page = 1, size = 10) {
    try {
      const { data, error, count } = await supabase
        .from(this.table)
        .select(this.selectQuery, { count: 'exact' })
        .textSearch('fts', searchTerm, {
          type: 'websearch',
          config: 'english'
        })
        .range((page - 1) * size, page * size - 1);

      if (error) throw error;

      return {
        data: data.map(profile => new this(profile)),
        count: count,
        page: page,
        size: size,
        total: Math.ceil(count / size)
      };
    } catch (error) {
      console.error('[Error] Searching User Profiles: ', error);
      throw error;
    }
  }

  /**
   * Check if the user has a specific permission for a given entity.
   * @param {number} entityId - The ID of the entity to check permissions for.
   * @param {number} permissionFlag - The permission flag to check (e.g., 1 for read, 2 for write, 4 for update, 8 for delete).
   * @returns {boolean} True if the user has the specified permission, false otherwise.
   */
  hasPermission(entityId, permissionFlag) {
    // Check if the user has permissions for the given entity
    if (this.permissions && this.permissions[entityId]) {
      // Perform bitwise AND operation to check if the permission flag is set
      return (this.permissions[entityId] & permissionFlag) === permissionFlag;
    }
    return false; // No permissions found for the entity
  }
}

export default UserProfile;
