import { useEffect } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Heading from '@tiptap/extension-heading';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import {
  Bold,
  Italic,
  List,
  ListOrdered,
  Heading1,
  Heading2,
  Heading3,
  Trash2,
  Eye,
  Send
} from 'lucide-react';

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className='flex flex-wrap gap-1 p-2 bg-neutral-100 rounded-t-lg border-b border-neutral-200'>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={`p-1 rounded hover:bg-neutral-200 transition-colors ${
          editor.isActive('bold')
            ? 'bg-brand-100 text-brand-700'
            : 'text-neutral-700'
        }`}
      >
        <Bold size={18} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={`p-1 rounded hover:bg-neutral-200 transition-colors ${
          editor.isActive('italic')
            ? 'bg-brand-100 text-brand-700'
            : 'text-neutral-700'
        }`}
      >
        <Italic size={18} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={`p-1 rounded hover:bg-neutral-200 transition-colors ${
          editor.isActive('heading', { level: 1 })
            ? 'bg-brand-100 text-brand-700'
            : 'text-neutral-700'
        }`}
      >
        <Heading1 size={18} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={`p-1 rounded hover:bg-neutral-200 transition-colors ${
          editor.isActive('heading', { level: 2 })
            ? 'bg-brand-100 text-brand-700'
            : 'text-neutral-700'
        }`}
      >
        <Heading2 size={18} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={`p-1 rounded hover:bg-neutral-200 transition-colors ${
          editor.isActive('heading', { level: 3 })
            ? 'bg-brand-100 text-brand-700'
            : 'text-neutral-700'
        }`}
      >
        <Heading3 size={18} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={`p-1 rounded hover:bg-neutral-200 transition-colors ${
          editor.isActive('bulletList')
            ? 'bg-brand-100 text-brand-700'
            : 'text-neutral-700'
        }`}
      >
        <List size={18} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={`p-1 rounded hover:bg-neutral-200 transition-colors ${
          editor.isActive('orderedList')
            ? 'bg-brand-100 text-brand-700'
            : 'text-neutral-700'
        }`}
      >
        <ListOrdered size={18} />
      </button>
    </div>
  );
};
const TipTap = ({ content, onContentChange, extra }) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: false,
        bulletList: false,
        orderedList: false,
        listItem: false
      }),
      Heading.configure({
        levels: [1, 2, 3]
      }),
      BulletList,
      OrderedList,
      ListItem
    ],
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      onContentChange(html);
    },
    editorProps: {
      attributes: {
        class:
          'prose prose-sm sm:prose lg:prose-lg xl:prose-xl focus:outline-none py-1 px-2',
        style: 'min-height: 200px; max-height: 400px; overflow-y: auto;'
      }
    }
  });

  useEffect(() => {
    if (editor && content) {
      editor.commands.setContent(content);
    }
  }, [editor, content]);

  return (
    <div className='editor border border-neutral-200 rounded-lg overflow-hidden w-full'>
      <MenuBar editor={editor} />
      <EditorContent editor={editor} />
      {extra && (
        <div className='flex justify-end gap-2 p-2 rounded-b-lg'>{extra}</div>
      )}
    </div>
  );
};

export default TipTap;
