import React, { useState, useEffect, useContext } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { GripVertical, Plus, X } from 'lucide-react';
import {
  FilledButton,
  OutlineButton,
  TextButton,
  Popup,
  Input,
  ColourPicker,
  Checkbox,
  DropdownInput
} from 'core';
import CoreEntity from '../../../models/CoreEntity';
import { UserProfileContext } from '../../../App';
import { EntityTypeEnum } from '../../../utilities/Enumerables';

const CreateBasicPipeline = ({ onClose, onSave }) => {
  const [title, setTitle] = useState('');
  const [columns, setColumns] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [linkedEntity, setLinkedEntity] = useState('');
  const [coreEntities, setCoreEntities] = useState([]);

  const userProfile = useContext(UserProfileContext).userProfile;

  useEffect(() => {
    const fetchCoreEntities = async () => {
      try {
        const { data: entities } = await CoreEntity.getAll(
          { 'metadata->>pipelines': { value: 'enabled' } },
          1,
          100
        );
        setCoreEntities(entities);
      } catch (error) {
        console.error('Error fetching core entities:', error.message);
      }
    };

    fetchCoreEntities();
  }, []);

  const handleDragEnd = result => {
    if (!result.destination) return;

    const items = Array.from(columns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedColumns = items.map((item, index) => ({
      ...item,
      order: index
    }));

    setColumns(updatedColumns);
  };

  const handleColumnChange = (index, field, value) => {
    const updatedColumns = [...columns];
    updatedColumns[index] = { ...updatedColumns[index], [field]: value };
    setColumns(updatedColumns);
  };

  const handleAddColumn = () => {
    const newColumnId = Math.max(...columns.map(col => col.id || 0), 0) + 1;
    const newColumn = {
      id: newColumnId,
      label: 'New Column',
      colour: 'gray',
      order: columns.length
    };
    setColumns([...columns, newColumn]);
  };

  const handleRemoveColumn = index => {
    const updatedColumns = columns
      .filter((_, i) => i !== index)
      .map((col, i) => ({ ...col, order: i }));
    setColumns(updatedColumns);
  };

  const getDefaultColumns = entityId => {
    const entityIdNum = Number(entityId);
    switch (entityIdNum) {
      case EntityTypeEnum.Company:
        return 'id,name,last_booked,account_manager:user_profiles!account_manager(id,first_name,last_name,profile_photo),pipelines';
      case EntityTypeEnum.User:
        return 'id,first_name,last_name,full_name,profile_photo,email,phones,companies!company_id(id,name),pipelines';
      // case 'quotes':
      //   return 'id,companies(id,name),created_at,total_amount,status,pipelines';
      default:
        return 'id,name,pipelines';
    }
  };

  const getDefaultConfig = entityId => {
    const entityIdNum = Number(entityId);
    switch (entityIdNum) {
      case EntityTypeEnum.Company:
        return {
          id: { icon: 'Hash', inline: true, labelStyle: 'iconOnly', order: 1 },
          name: {
            icon: 'Building',
            inline: false,
            labelStyle: 'iconOnly',
            order: 2
          },
          account_manager: {
            icon: 'User',
            inline: false,
            labelStyle: 'iconOnly',
            order: 3
          },
          last_booked: {
            icon: 'Calendar',
            inline: true,
            labelStyle: 'full',
            order: 4
          },
          pinned_note: {
            icon: 'Pin',
            inline: false,
            labelStyle: 'iconOnly',
            order: 5
          },
          pipelines: {
            icon: 'GitBranch',
            inline: false,
            labelStyle: 'hidden',
            order: 6
          }
        };
      case EntityTypeEnum.User:
        return {
          id: { icon: 'Hash', inline: true, labelStyle: 'iconOnly', order: 1 },
          full_name: {
            icon: 'User',
            inline: false,
            labelStyle: 'iconOnly',
            order: 2
          },
          email: {
            icon: 'Mail',
            inline: false,
            labelStyle: 'iconOnly',
            order: 3
          },
          phones: {
            icon: 'Phone',
            inline: false,
            labelStyle: 'iconOnly',
            order: 4
          },
          companies: {
            icon: 'Building',
            inline: false,
            labelStyle: 'iconOnly',
            order: 5
          },
          last_chase: {
            icon: 'Calendar',
            inline: true,
            labelStyle: 'full',
            order: 6
          },
          next_chase: {
            icon: 'CalendarPlus',
            inline: true,
            labelStyle: 'full',
            order: 7
          },
          pinned_note: {
            icon: 'Pin',
            inline: false,
            labelStyle: 'iconOnly',
            order: 8
          },
          pipelines: {
            icon: 'GitBranch',
            inline: false,
            labelStyle: 'hidden',
            order: 9
          }
        };
      default:
        return {
          id: { icon: 'Hash', inline: true, labelStyle: 'iconOnly', order: 1 },
          name: {
            icon: 'Building',
            inline: false,
            labelStyle: 'iconOnly',
            order: 2
          },
          pipelines: {
            icon: 'GitBranch',
            inline: false,
            labelStyle: 'hidden',
            order: 3
          }
        };
    }
  };

  const defaultColumns = getDefaultColumns(linkedEntity);
  const defaultConfig = getDefaultConfig(linkedEntity);

  const handleSubmit = e => {
    e.preventDefault();
    onSave({
      owner: userProfile.id,
      title,
      groups: columns,
      private: isPrivate,
      entity: linkedEntity,
      columns: defaultColumns,
      config: defaultConfig
    });
    onClose();
  };

  const handleColourChange = (index, colour) => {
    const baseColour = colour.split('-')[0];
    handleColumnChange(index, 'colour', baseColour);
  };

  return (
    <Popup
      isOpen={true}
      onClose={onClose}
      title='Create Basic Pipeline'
      size='md'
    >
      <form onSubmit={handleSubmit}>
        <div className='flex'>
          <div className='w-1/2 pr-4'>
            <Input
              label='Pipeline Title'
              value={title}
              onChange={e => setTitle(e.target.value)}
              className='mb-4'
            />

            <DropdownInput
              label='Linked Entity'
              value={linkedEntity}
              onChange={e => setLinkedEntity(e.target.value)}
              className='mb-4'
              options={coreEntities.map(entity => ({
                value: entity.id,
                label: entity.displayName
              }))}
              placeholder='Select an entity'
            />

            <h3 className='text-lg font-semibold mb-2'>Pipeline Columns</h3>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId='columns'>
                {provided => (
                  <ul
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className='space-y-2'
                  >
                    {columns.map((column, index) => (
                      <Draggable
                        key={column.id}
                        draggableId={column.id.toString()}
                        index={index}
                      >
                        {provided => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className='flex items-center space-x-2 bg-gray-100 p-2 rounded'
                          >
                            <div {...provided.dragHandleProps}>
                              <GripVertical size={20} />
                            </div>
                            <Input
                              value={column.label}
                              onChange={e =>
                                handleColumnChange(
                                  index,
                                  'label',
                                  e.target.value
                                )
                              }
                              className='flex-grow'
                            />
                            <ColourPicker
                              showShades={false}
                              colour={column.colour}
                              onChange={colour =>
                                handleColourChange(index, colour)
                              }
                            />
                            <TextButton
                              onClick={() => handleRemoveColumn(index)}
                            >
                              <X size={20} />
                            </TextButton>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
            <TextButton onClick={handleAddColumn} className='mt-2'>
              <Plus size={20} /> Add Column
            </TextButton>
          </div>
          <div className='w-px border-r border-gray-200 my-4'></div>
          <div className='w-1/2 pl-4'>
            <Checkbox
              label='Private Pipeline'
              checked={isPrivate}
              onChange={e => setIsPrivate(e.target.checked)}
            />
          </div>
        </div>

        <div className='mt-4 flex justify-end space-x-2'>
          <OutlineButton type='button' onClick={onClose}>
            Cancel
          </OutlineButton>
          <FilledButton type='submit'>Create Pipeline</FilledButton>
        </div>
      </form>
    </Popup>
  );
};

export default CreateBasicPipeline;
