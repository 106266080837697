import UserProfile from './UserProfile';

/**
 * Represents an Imported User Profile in the system.
 * @extends UserProfile
 */
class ImportedUserProfile extends UserProfile {
  /** @type {string} The name of the database table for Imported User Profiles */
  static table = 'imported_user_profiles';

  /**
   * Creates an instance of ImportedUserProfile.
   * @param {Object} data - The imported user profile data.
   */
  constructor(data = {}) {
    super(data);
    this.readOnly = data.read_only ?? null;
    // Add any additional properties specific to imported user profiles here
  }

  /**
   * Get a record by its ID.
   * @param {string|number} id - The ID of the record to fetch.
   * @returns {Promise<BaseModel>} A promise that resolves to a new instance of the model.
   */
  static async getById(id) {
    return this.fetchOne('display_id', id);
  }

  /**
   * Converts the ImportedUserProfile instance to a database-friendly format.
   * @returns {Object} The imported user profile data ready for database operations.
   */
  toDatabase() {
    const baseData = super.toDatabase();
    // Add or override properties as needed for imported user profiles
    return {
      ...baseData
      // Add any additional properties specific to imported user profiles here
    };
  }
}

export default ImportedUserProfile;
