import BaseModel from './BaseModel';

/**
 * Represents a Filter in the system.
 * @extends BaseModel
 */
class Filter extends BaseModel {
  /** @type {string} The name of the database table for Filters */
  static table = 'filters';

  /** @type {string} The SQL query to select Filter data */
  static selectQuery = '*';

  /**
   * Creates an instance of Filter.
   * @param {Object} data - The filter data.
   */
  constructor(data = {}) {
    super(data);
    this.owner = data.owner || null;
    this.status = data.status || null;
    this.entityType = data.entity_type || null;
    this.filterData = data.filter_data || {};
    this.name = data.name || null;
  }

  /**
   * Converts the Filter instance to a database-friendly format.
   * @returns {Object} The filter data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      owner: this.owner,
      status: this.status,
      entity_type: this.entityType,
      filter_data: this.filterData,
      name: this.name
    };
  }
}

export default Filter;
