import React, { useEffect, useRef, useState } from 'react';

const Map = ({
  address,
  latitude = 53.00709710294123, // Default latitude for BOHSA
  longitude = -2.165005429504804, // Default longitude for UK center
  zoom = 18,
  width = '100%',
  height = '200px'
}) => {
  const mapRef = useRef(null);
  const googleMapRef = useRef(null);
  const markerRef = useRef(null);
  const [coordinates, setCoordinates] = useState({
    lat: latitude,
    lng: longitude
  });

  useEffect(() => {
    const initMap = async () => {
      if (!window.google?.maps) {
        return;
      }

      const { Map: GoogleMap } = await google.maps.importLibrary('maps');
      const { AdvancedMarkerElement } = await google.maps.importLibrary(
        'marker'
      );
      const { Geocoder } = await google.maps.importLibrary('geocoding');

      // Set UK-wide view if no address provided
      const initialConfig = {
        center: coordinates,
        zoom: address ? zoom : 5, // Use zoom 5 for UK view if no address
        mapId: '8f348c3c6cf90b9b'
      };

      googleMapRef.current = new GoogleMap(mapRef.current, initialConfig);

      // Only show marker and geocode if address exists
      if (address) {
        // Geocoding function
        const geocoder = new Geocoder();
        geocoder.geocode({ address: address }, (results, status) => {
          if (status === 'OK' && results[0]) {
            const newCoordinates = results[0].geometry.location.toJSON();
            setCoordinates(newCoordinates);
            googleMapRef.current.setCenter(newCoordinates);

            // Create marker only after geocoding succeeds
            markerRef.current = new AdvancedMarkerElement({
              position: newCoordinates,
              map: googleMapRef.current
            });
          }
        });
      }
    };

    initMap();
  }, [address, zoom]);

  useEffect(() => {
    if (googleMapRef.current && markerRef.current) {
      markerRef.current.position = coordinates;
      googleMapRef.current.setCenter(coordinates);
    }
  }, [coordinates]);

  if (!window.google?.maps) {
    return (
      <div
        className='relative flex items-center justify-center bg-gray-100 rounded-lg shadow-md'
        style={{ width, height }}
      >
        <div className='text-gray-500 text-center p-4'>
          <div className='text-lg font-semibold'>Map Unavailable</div>
          <div className='text-sm'>Google Maps API not loaded</div>
        </div>
      </div>
    );
  }

  return (
    <div className='relative' style={{ width, height }}>
      <div
        ref={mapRef}
        style={{ width: '100%', height: '100%' }}
        className='rounded-lg shadow-md'
      />
    </div>
  );
};

export { Map };
