import React, { useState } from 'react';
import {
  Clock,
  AlertTriangle,
  Star,
  PinIcon,
  FileText,
  Phone,
  MessageCircle,
  Mail,
  ThumbsUp,
  Ampersand
} from 'lucide-react';
import { FilledButton, TextButton, Badge } from 'core';
import TipTap from '../TipTap';
import { PrimaryInput } from '../../inputs/Forms';
import TemplateModel from '../../../models/ActivityTemplate';
import { supabase } from '../../../utilities/supabase';
import Popup from '../Popup';

const CreateTemplate = ({ onClose, onSubmit }) => {
  const [title, setTitle] = useState('');
  const [templateType, setTemplateType] = useState('note');
  const [content, setContent] = useState('');
  const [selectedIcon, setSelectedIcon] = useState('');
  const [selectedColor, setSelectedColor] = useState('');

  const handleSubmit = async () => {
    const templateData = {
      title,
      type: templateType,
      content,
      icon: selectedIcon.id,
      colour: selectedColor
    };
    try {
      const createdTemplate = await TemplateModel.create(templateData);
      onSubmit(createdTemplate);
      onClose();
    } catch (error) {
      console.error('Error creating template:', error);
    }
  };

  const templateTypes = [
    { type: 119, label: 'Note' },
    { type: 120, label: 'Email' },
    { type: 121, label: 'Text' },
    { type: 122, label: 'Call' }
  ];

  const icons = [
    { id: 'clock', component: Clock },
    { id: 'alert-triangle', component: AlertTriangle },
    { id: 'star', component: Star },
    { id: 'pin', component: PinIcon },
    { id: 'file-text', component: FileText },
    { id: 'phone', component: Phone },
    { id: 'message-circle', component: MessageCircle },
    { id: 'mail', component: Mail },
    { id: 'thumbs-up', component: ThumbsUp },
    { id: 'ampersand', component: Ampersand }
  ];
  const colors = [
    '#FF6B6B',
    '#FFD93D',
    '#6BCB77',
    '#4D96FF',
    '#F473B9',
    '#8D72E1',
    '#FF9F45',
    '#000000'
  ];

  return (
    <Popup onClose={onClose} title='Create Template'>
      <div className='p-2'>
        <div className='flex'>
          <div className='w-1/3 pr-2'>
            <div className='mb-2'>
              <label
                htmlFor='title'
                className='block text-xs font-medium text-neutral-700 mb-1'
              >
                Title
              </label>
              <PrimaryInput
                id='title'
                type='text'
                value={title}
                onChange={e => setTitle(e.target.value)}
                placeholder='Enter template title'
                required
              />
            </div>
            <div className='mb-2'>
              <label className='block text-xs font-medium text-neutral-700 mb-1'>
                Type
              </label>
              <div className='flex space-x-1'>
                {templateTypes.map(({ type, label }) => (
                  <Badge
                    key={type}
                    onClick={() => setTemplateType(type)}
                    colour={templateType === type ? 'primary' : 'neutral'}
                  >
                    {label}
                  </Badge>
                ))}
              </div>
            </div>
            <div className='mb-2'>
              <label className='block text-xs font-medium text-neutral-700 mb-1'>
                Icon
              </label>
              <div className='flex flex-wrap gap-1'>
                {icons.map(({ id, component: Icon }) => (
                  <TextButton
                    key={id}
                    onClick={() => setSelectedIcon({ id, component: Icon })}
                    className={`w-6 h-6 flex items-center justify-center rounded ${
                      selectedIcon.id === id
                        ? 'bg-brand-100 text-brand-700'
                        : 'bg-neutral-100 text-neutral-700'
                    }`}
                    colour='base'
                    size='xs'
                  >
                    <Icon size={16} />
                  </TextButton>
                ))}
              </div>
            </div>
            <div className='mb-2'>
              <label className='block text-xs font-medium text-neutral-700 mb-1'>
                Color
              </label>
              <div className='flex flex-wrap gap-1'>
                {colors.map(color => (
                  <TextButton
                    key={color}
                    onClick={() => setSelectedColor(color)}
                    className={`w-6 h-6 rounded-full border ${
                      selectedColor === color
                        ? 'border-brand-700'
                        : 'border-transparent'
                    }`}
                    style={{ backgroundColor: color }}
                    colour='base'
                    size='xs'
                  />
                ))}
              </div>
            </div>
          </div>
          <div className='w-2/3 pl-2'>
            <div className='mb-2'>
              <label
                htmlFor='content'
                className='block text-xs font-medium text-neutral-700 mb-1'
              >
                Content
              </label>
              <TipTap onContentChange={setContent} />
            </div>
          </div>
        </div>
        <div className='flex justify-end mt-2'>
          <TextButton
            onClick={onClose}
            colour='base'
            size='md'
            className='mr-1'
          >
            Cancel
          </TextButton>
          <FilledButton onClick={handleSubmit} colour='primary' size='md'>
            Create Template
          </FilledButton>
        </div>
      </div>
    </Popup>
  );
};

export default CreateTemplate;
