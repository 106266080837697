import React from 'react';
import { ArrowUp, ArrowDown, Users } from 'lucide-react';
import { Tooltip, Badge } from 'core';

const StatsCard = ({ label, value, change, changeType, previousValue }) => {
  const isPositive = changeType === 'positive';
  const ChangeIcon = isPositive ? ArrowUp : ArrowDown;

  return (
    <div className='bg-white p-4 rounded-lg shadow'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <div className='bg-brand-100 p-3 rounded-lg'>
            <Users className='w-6 h-6 text-brand-600' />
          </div>
          <div className='flex flex-col ml-3'>
            <p className='text-sm font-regular text-neutral-500'>{label}</p>
            <div className='flex flex-row justify-between items-end'>
              <p className='text-xl font-semibold text-neutral-900 mr-2'>
                {value}
              </p>
              <Tooltip
                content={`Previous: ${previousValue}`}
                className='w-auto min-w-24'
              >
                <Badge
                  colour={isPositive ? 'success' : 'danger'}
                  size='xs'
                  shape='pill'
                  leftIcon={ChangeIcon}
                >
                  {change}
                </Badge>
              </Tooltip>
            </div>
          </div>
        </div>
        {/* <a href="#" className='text-sm text-brand-600 hover:text-brand-700'>View all</a> */}
      </div>
    </div>
  );
};

export default StatsCard;
