import React, { useState, useEffect } from 'react';

const Tooltip = ({ children, content, className = '' }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    let timeoutId;
    if (isVisible) {
      timeoutId = setTimeout(() => setOpacity(1), 10);
    } else {
      setOpacity(0);
    }
    return () => clearTimeout(timeoutId);
  }, [isVisible]);

  return (
    <div className='relative inline-block'>
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible && (
        <div
          className={`absolute z-10 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 transition-opacity duration-300 ease-in-out -translate-y-1 ${className}`}
          style={{ opacity }}
        >
          {content}
          <div className='tooltip-arrow' data-popper-arrow></div>
        </div>
      )}
    </div>
  );
};

export { Tooltip };
