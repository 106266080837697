import React from 'react';
import { useNavigate } from 'react-router-dom';
import Course from '../../../models/Course';
import EntityTable from '../../../components/tables/EntityTable';
import { formatDate } from '../../../utilities/Formatting';
import { Link } from 'react-router-dom';
import { Pencil, Trash } from 'lucide-react';
import { EntityTypeEnum } from '../../../utilities/Enumerables';

const Courses = () => {
  //Set the page title
  document.title = 'Courses | CRM | BOHSA LTD';
  const navigate = useNavigate();

  const menuItems = [
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => navigate(`/courses/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const handleRowClick = data => {
    navigate(`/courses/${data.id}`);
  };

  return (
    <div className='p-4'>
      <EntityTable
        name='Courses'
        filter={[
          { field: 'id', label: 'ID', type: 'text' },
          { field: 'name', label: 'Course Name', type: 'text' },
          { field: 'grant', label: 'Grant', type: 'boolean' },
          { field: 'created_date_from', label: 'Created From', type: 'date' },
          { field: 'created_date_to', label: 'Created To', type: 'date' }
        ]}
        sort={[
          { value: 'id', label: 'Course ID' },
          { value: 'name', label: 'Course Name' },
          { value: 'created_date', label: 'Date Created' },
          { value: 'updated_date', label: 'Last Modified' }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/courses/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          { label: 'Course Name', field: 'name' },
          {
            label: 'Grant',
            field: 'grant',
            cell: value => (value ? 'Yes' : 'No')
          },
          {
            label: 'On Website',
            field: 'websiteDetails',
            cell: value => {
              return value && value.visible ? 'Yes' : 'No';
            }
          },
          {
            label: 'Status',
            field: 'statusName'
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={Course}
        entityType={EntityTypeEnum.Course}
        onRowClick={handleRowClick}
        canCreate={true}
      />
    </div>
  );
};

export default Courses;
