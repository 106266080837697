import React from 'react';
import { Check } from 'lucide-react';

const Checkbox = ({
  checked,
  onChange,
  className,
  label,
  description,
  disabled = false,
  colour = 'primary'
}) => {
  const getColourClasses = () => {
    switch (colour) {
      case 'brand':
        return 'border-base-300 text-brand-700 focus:ring-brand-700';
      case 'info':
        return 'border-base-300 text-info-600 focus:ring-info-600';
      case 'success':
        return 'border-base-300 text-success-600 focus:ring-success-600';
      case 'warning':
        return 'border-base-300 text-warning-600 focus:ring-warning-600';
      case 'danger':
        return 'border-base-300 text-danger-600 focus:ring-danger-600';
      case 'primary':
      default:
        return 'border-base-300 text-primary-700 focus:ring-primary-700';
    }
  };

  const inputClasses = `h-4 w-4 rounded ${getColourClasses()} ${disabled ? 'opacity-60' : ''}`;
  const labelClasses = 'font-medium text-base-900';
  const descriptionClasses = 'text-base-500';

  return (
    <div className='relative flex items-start'>
      <div className='flex h-6 items-center'>
        <input
          type='checkbox'
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className={`${inputClasses} ${className || ''}`}
        />
      </div>
      <div className='ml-3 text-sm leading-6'>
        {label && <label className={labelClasses}>{label}</label>}
        {description && <p className={descriptionClasses}>{description}</p>}
      </div>
    </div>
  );
};

export { Checkbox };
