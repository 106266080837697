import moment from 'moment';

/**
 * Calculate a date based on specified days, terms, and dates.
 * @param {number} days - Number of days to add (0 to infinity).
 * @param {number} terms - ID of the term type (90, 91, 92, 93, or 94).
 * @param {Object} dates - Object containing relevant dates.
 * @param {string} dates.invoiceDate - The invoice date.
 * @param {string} dates.courseStartDate - The course start date.
 * @param {string} dates.courseEndDate - The course end date.
 * @returns {Date} The calculated date as a JavaScript Date object.
 */
export const calculateDate = (days, terms, dates) => {
  let baseDate;

  switch (terms) {
    case 90: // Invoice
      baseDate = moment(dates.invoiceDate);
      break;
    case 91: // 1st day of course
      baseDate = moment(dates.courseStartDate);
      break;
    case 92: // Last day of course
      baseDate = moment(dates.courseEndDate);
      break;
    case 93: // End of current month
      baseDate = moment().endOf('month');
      break;
    case 94: // End of following month
      baseDate = moment().add(1, 'month').endOf('month');
      break;
    default:
      baseDate = new Date();
  }

  return baseDate.add(days, 'days').toDate();
};
