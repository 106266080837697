import React, { useState, useEffect, useCallback } from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label
} from '@headlessui/react';
import { debounce } from 'lodash';

const ComboBox = ({
  loadOptions,
  label,
  placeholder,
  onChange,
  value,
  className = '',
  colour = 'primary',
  hint
}) => {
  const [availableOptions, setAvailableOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [query, setQuery] = useState('');

  const pageSize = 10;

  const handleLoadOptions = useCallback(
    async (searchQuery, pageNumber) => {
      if (loadOptions) {
        setLoading(true);
        try {
          const results = (await loadOptions(searchQuery, pageNumber)) || [];
          setAvailableOptions(prevOptions =>
            pageNumber === 1 ? results : [...prevOptions, ...results]
          );
          setHasMore(results.length === pageSize);
          setPage(pageNumber);
        } catch (error) {
          console.error('Error loading options:', error);
          setHasMore(false);
        } finally {
          setLoading(false);
        }
      }
    },
    [loadOptions]
  );

  const debouncedLoadOptions = useCallback(
    debounce(searchQuery => {
      handleLoadOptions(searchQuery, 1);
    }, 300),
    [handleLoadOptions]
  );

  useEffect(() => {
    setAvailableOptions([]);
    debouncedLoadOptions(query);
  }, [query, debouncedLoadOptions]);

  const handleLoadMore = () => {
    if (loadOptions && hasMore) {
      handleLoadOptions(query, page + 1);
    }
  };

  const filteredOptions = availableOptions;

  const colourClasses = {
    brand: 'ring-brand-300 focus:ring-brand-600',
    primary: 'ring-primary-300 focus:ring-primary-600',
    info: 'ring-info-300 focus:ring-info-600',
    success: 'ring-success-300 focus:ring-success-600',
    warning: 'ring-warning-300 focus:ring-warning-600',
    danger: 'ring-danger-300 focus:ring-danger-600',
    base: 'ring-base-300 focus:ring-base-600'
  };

  const focusClasses = {
    brand: 'data-[focus]:bg-brand-100 data-[focus]:text-brand-700',
    primary: 'data-[focus]:bg-primary-100 data-[focus]:text-primary-700',
    info: 'data-[focus]:bg-info-100 data-[focus]:text-info-700',
    success: 'data-[focus]:bg-success-100 data-[focus]:text-success-700',
    warning: 'data-[focus]:bg-warning-100 data-[focus]:text-warning-700',
    danger: 'data-[focus]:bg-danger-100 data-[focus]:text-danger-700',
    base: 'data-[focus]:bg-base-100 data-[focus]:text-base-700'
  };

  return (
    <Combobox
    immediate
      as='div'
      value={value}
      onChange={selectedOption => {
        setQuery('');
        onChange(selectedOption);
      }}
      className={className}
    >
      {label && (
        <Label className='block text-sm font-medium leading-6 text-primary-900'>
          {label}
        </Label>
      )}
      <div className='relative'>
        <ComboboxInput
          className={`w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-primary-900 shadow-sm ring-1 ring-inset ${colourClasses[colour]} focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
          onChange={async event => {
            const newQuery = event.target.value;
            setQuery(newQuery);
            handleLoadOptions(newQuery, 1);
          }}
          displayValue={option => option?.label}
          placeholder={placeholder}
        />
        <ComboboxButton className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
          <ChevronsUpDown
            className='h-5 w-5 text-primary-400'
            aria-hidden='true'
          />
        </ComboboxButton>

        {filteredOptions.length > 0 && (
          <ComboboxOptions className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-primary-900 ring-opacity-5 focus:outline-none sm:text-sm'>
            {filteredOptions.map(option => (
              <ComboboxOption
                key={option.value}
                value={option}
                className={`group relative cursor-default select-none py-2 pl-3 pr-9 text-primary-900 ${focusClasses[colour]}`}
              >
                <div className='flex flex-col md:flex-row md:items-center md:justify-start'>
                  <span className='block truncate group-data-[selected]:font-semibold'>
                    {option.label}
                  </span>
                  {option.secondaryLabel && (
                    <span
                      className={`mt-1 truncate md:mt-0 md:ml-2 text-primary-500 group-${focusClasses[colour]} text-sm`}
                    >
                      {option.secondaryLabel}
                    </span>
                  )}
                </div>

                <span
                  className={`absolute inset-y-0 right-0 hidden items-center pr-4 text-${colour}-700 group-data-[selected]:flex group-${focusClasses[colour]}`}
                >
                  <Check className='h-5 w-5' aria-hidden='true' />
                </span>
              </ComboboxOption>
            ))}
            {hasMore && (
              <div
                className='py-2 px-3 text-sm text-primary-700 cursor-pointer hover:bg-primary-100'
                onClick={handleLoadMore}
              >
                {loading ? 'Loading...' : 'Load more'}
              </div>
            )}
          </ComboboxOptions>
        )}
      </div>
      {hint && (
        <p className="mt-2 text-sm text-primary-500">{hint}</p>
      )}
    </Combobox>
  );
};

export { ComboBox };
