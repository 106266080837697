import BaseModel from './BaseModel';

/**
 * Represents an AutomationRun in the system.
 * @extends BaseModel
 */
class AutomationRun extends BaseModel {
  /** @type {string} The name of the database table for AutomationRuns */
  static table = 'automation_runs';

  /** @type {string} The SQL query to select AutomationRun data with related information */
  static selectQuery = `
    *,
    automation:automations (id)
  `;

  /**
   * Creates an instance of AutomationRun.
   * @param {Object} data - The automation run data.
   */
  constructor(data = {}) {
    super(data);
    this.automation = data.automation || null;
    this.entityType = data.entity_type || null;
    this.entityId = data.entity_id || null;
    this.createdDate = data.created_date || null;
    this.updatedDate = data.updated_date || null;
    this.responses = data.responses || null;
    this.exitResponses = data.exit_responses || [];
    this.status = data.status || 'pending';
  }

  /**
   * Converts the AutomationRun instance to a database-friendly format.
   * @returns {Object} The automation run data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      automation: this.automation,
      entity_type: this.entityType,
      entity_id: this.entityId,
      created_date: this.createdDate,
      updated_date: this.updatedDate,
      responses: this.responses,
      exit_responses: this.exitResponses,
      status: this.status
    };
  }
}

export default AutomationRun;
