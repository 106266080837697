import React, { useState, useEffect } from 'react';
import { X, Zap, Play, Trash2 } from 'lucide-react';
import { realtime, supabase } from '../../../utilities/supabase';
import { Badge } from 'core';

const AutomationsTab = ({ entity, entityType, setAutomations }) => {
  const [automationRuns, setAutomationRuns] = useState([]);

  useEffect(() => {
    fetchAutomationRuns();
  }, [entity]);

  const fetchAutomationRuns = async () => {
    const { data: runsData, error: runsError } = await supabase
      .from('automation_runs')
      .select(
        `
        *,
        automation:automations(name)
      `
      )
      .eq('entity_id', entity.id)
      .eq('status', 223)
      .order('created_date', { ascending: false })
      .limit(5);

    if (runsError) {
      console.error('Error fetching automation runs:', runsError);
      return;
    }

    setAutomationRuns(
      runsData.map(run => ({
        ...run,
        automationName: run.automation.name
      }))
    );

    setAutomations(runsData);
  };

  useEffect(() => {
    const subscription = realtime(
      '*',
      'automation_runs',
      payload => {
        fetchAutomationRuns();
      },
      `entity_id=eq.${entity.id}`
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [entityType, entity]);

  const handleCancelRun = async (e, runId) => {
    e.stopPropagation();
    try {
      await supabase
        .from('automation_runs')
        .update({ status: 224 })
        .eq('id', runId);

      setAutomationRuns(prev => prev.filter(run => run.id !== runId));
    } catch (error) {
      console.error('Error cancelling automation run:', error);
    }
  };

  return (
    <div className='space-y-2'>
      {automationRuns.map(run => (
        <div
          key={run.id}
          className='flex items-center justify-between text-xs text-base-600 group py-1 px-2 rounded-md bg-base-100 hover:bg-brand-100 transition-colors duration-200 cursor-pointer'
        >
          <div className='flex items-center space-x-2'>
            <Zap size={16} className='text-brand-600' />
            <span className='font-small text-sm text-base-900'>
              {run.automationName}
            </span>
            <Play
              size={12}
              className={
                run.status === 'success'
                  ? 'text-success-600'
                  : 'text-danger-600'
              }
            />
            <span>
              {new Date(run.created_date).toLocaleString().replace(',', '')}
            </span>
          </div>
          <button
            className='text-base-600 hover:text-danger-600 transition-colors duration-200 opacity-0 group-hover:opacity-100'
            aria-label='Cancel run'
            onClick={e => handleCancelRun(e, run.id)}
          >
            <Trash2 size={12} className='text-danger-600' />
          </button>
        </div>
      ))}
      {automationRuns.length === 0 && (
        <p className='text-sm text-base-600'>No automation runs available.</p>
      )}
    </div>
  );
};

export default AutomationsTab;
