import React from 'react';
import { useNavigate } from 'react-router-dom';
import Company from '../../../models/Company';
import EntityTable from '../../../components/tables/EntityTable';
import { formatDate, formatProfile } from '../../../utilities/Formatting';
import { Link } from 'react-router-dom';
import { Pencil, Trash } from 'lucide-react';
import {
  EntityTypeEnum,
  FilterOperatorEnum
} from '../../../utilities/Enumerables';

const Companies = () => {
  //Set the page title
  document.title = 'Companies | CRM | BOHSA LTD';
  const navigate = useNavigate();

  const menuItems = [
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => navigate(`/companies/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const handleRowClick = data => {
    navigate(`/companies/${data.id}`);
  };

  return (
    <div className='p-4'>
      <EntityTable
        name='Companies'
        filter={[
          { field: 'id', label: 'ID', type: 'text', operator: FilterOperatorEnum.EQUALS },
          { field: 'name', label: 'Company Name', type: 'text', operator: FilterOperatorEnum.ILIKE },
          {
            field: 'user_profiles.first_name',
            label: 'Account Manager',
            type: 'text',
            operator: FilterOperatorEnum.ILIKE
          },
          { field: 'on_account', label: 'On Account', type: 'boolean', operator: FilterOperatorEnum.EQUALS },
          { field: 'status', label: 'Status', type: 'text', operator: FilterOperatorEnum.EQUALS },
          { field: 'tradex', label: 'Tradex', type: 'boolean', operator: FilterOperatorEnum.EQUALS },
          { field: 'created_date_from', label: 'Created From', type: 'date', operator: FilterOperatorEnum.GREATER_THAN_OR_EQUAL },
          { field: 'created_date_to', label: 'Created To', type: 'date', operator: FilterOperatorEnum.LESS_THAN_OR_EQUAL }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/companies/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          { label: 'Company Name', field: 'name' },
          {
            label: 'Account Manager',
            field: 'accountManager.name',
            foreignKey: { table: 'user_profiles', column: 'first_name' },
            cell: value => formatProfile(value)
          },
          {
            label: 'On Account',
            field: 'onAccount',
            cell: value => (value ? 'Yes' : 'No')
          },
          {
            label: 'Status',
            field: 'status.name',
            foreignKey: { table: 'core_entity_types', column: 'type' }
          },
          {
            label: 'Tradex',
            field: 'tradex',
            cell: value => (value ? 'Yes' : 'No')
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Last Booked',
            field: 'lastBooked',
            cell: value => formatDate(value)
          }
        ]}
        menuItems={menuItems}
        model={Company}
        entityType={EntityTypeEnum.Company}
        onRowClick={handleRowClick}
        canCreate={true}
      />
    </div>
  );
};

export default Companies;
