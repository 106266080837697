import BaseModel from './BaseModel';

/**
 * Represents a Quote in the system.
 * @extends BaseModel
 */
class Quote extends BaseModel {
  /** @type {string} The name of the database table for Quotes */
  static table = 'quotes';

  /** @type {string} The SQL query to select Quote data with related information */
  static selectQuery = `
    *,
    companies (name),
    user_profiles (id, first_name, last_name, profile_photo),
    status:core_entity_types (id, type),
    locations (address_1, address_2, city, county, postcode)
  `;

  /**
   * Creates an instance of Quote.
   * @param {Object} data - The quote data.
   */
  constructor(data = {}) {
    super(data);
    this.requestedDate = data.requested_date || null;
    this.courseName = data.course_name || null;
    this.status = data.status
      ? { id: data.status.id, name: data.status.type }
      : null;
    this.company = data.company_id
      ? { id: data.company_id, name: data.companies?.name }
      : null;
    this.owner = data.owner
      ? {
          id: data.owner,
          firstName: data.user_profiles?.first_name,
          lastName: data.user_profiles?.last_name,
          profilePhoto: data.user_profiles?.profile_photo
        }
      : null;
    this.lastChaseDate = data.last_chase_date || null;
    this.nextChaseDate = data.next_chase_date || null;
    this.readyDate = data.ready_date || null;
    this.sentDate = data.sent_date || null;
    this.expiryDate = data.expiry_date || null;
    this.completedDate = data.completed_date || null;
    this.isFull = data.is_full || false;
    this.cost = data.cost || null;
    this.profit = data.profit || null;
    this.margin = data.margin || null;
    this.deliveryMethods = data.delivery_methods || [];
    this.accreditations = data.accreditations || [];
    this.delegates = data.delegates || null;
    this.datePrefs = data.date_prefs || null;
    this.locations = data.locations || null;
    this.owners = data.owners || [];
    this.bookers = data.bookers || [];
    this.invoiceAddress = data.invoice_address
      ? {
          id: data.invoice_address,
          address1: data.locations?.address_1,
          address2: data.locations?.address_2,
          city: data.locations?.city,
          county: data.locations?.county,
          postcode: data.locations?.postcode
        }
      : null;
  }

  /**
   * Converts the Quote instance to a database-friendly format.
   * @returns {Object} The quote data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      requested_date: this.requestedDate,
      course_name: this.courseName,
      status: this.status ? this.status.id : null,
      company_id: this.company ? this.company.id : null,
      owner: this.owner ? this.owner.id : null,
      last_chase_date: this.lastChaseDate,
      next_chase_date: this.nextChaseDate,
      ready_date: this.readyDate,
      sent_date: this.sentDate,
      expiry_date: this.expiryDate,
      completed_date: this.completedDate,
      is_full: this.isFull,
      cost: this.cost,
      profit: this.profit,
      margin: this.margin,
      delivery_methods: this.deliveryMethods,
      accreditations: this.accreditations,
      delegates: this.delegates,
      date_prefs: this.datePrefs,
      locations: this.locations,
      owners: this.owners,
      bookers: this.bookers,
      invoice_address: this.invoiceAddress ? this.invoiceAddress.id : null
    };
  }
}

export default Quote;
