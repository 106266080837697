import React, { useState, useEffect } from 'react';
import {
  Popup,
  FilledButton,
  TextButton,
  Input,
  DatePicker,
  TimePicker,
  DropdownMenu,
  Badge,
  TextEditor,
  MultiSelectBox
} from 'core';
import {
  MapPin,
  Video,
  Calendar,
  Clock,
  ArrowRight,
  Users,
  Tag,
  Type
} from 'lucide-react';
import CoreEntityTypes from '../../../models/CoreEntityType';
import { EntityTypeEnum } from '../../../utilities/Enumerables';

const EditMeeting = ({ isOpen, onClose, meeting, onEditMeeting }) => {
  const [title, setTitle] = useState(meeting.title);
  const [description, setDescription] = useState(meeting.description);
  const [startDate, setStartDate] = useState(new Date(meeting.startDate));
  const [startTime, setStartTime] = useState(() => {
    const start = new Date(meeting.startDate);
    return `${start.getHours().toString().padStart(2, '0')}:${start
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  });
  const [endDate, setEndDate] = useState(new Date(meeting.endDate));
  const [endTime, setEndTime] = useState(() => {
    const end = new Date(meeting.endDate);
    return `${end.getHours().toString().padStart(2, '0')}:${end
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  });
  const [category, setCategory] = useState(meeting.category);
  const [attendees, setAttendees] = useState(meeting.attendees || []);
  const [location, setLocation] = useState(meeting.location);
  const [meetingLink, setMeetingLink] = useState(meeting.location);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    const newEndDate = new Date(startDate);
    newEndDate.setMinutes(newEndDate.getMinutes() + 30);
    setEndDate(newEndDate);
    setEndTime(
      `${newEndDate.getHours().toString().padStart(2, '0')}:${newEndDate
        .getMinutes()
        .toString()
        .padStart(2, '0')}`
    );
  }, [startDate, startTime]);

  const fetchCategories = async () => {
    try {
      const fetchedCategories = await CoreEntityTypes.getByEntityId(
        EntityTypeEnum.MeetingCategory
      );
      const mappedCategories = fetchedCategories.map(cat => ({
        id: cat.id,
        name: cat.type,
        color: cat.metadata.color || 'gray',
        isVirtual: cat.metadata.isVirtual || false
      }));
      setCategories(mappedCategories);

      // Set the category based on the meeting we're editing
      const matchingCategory = mappedCategories.find(
        cat => cat.id === meeting.data.category.id
      );
      if (matchingCategory) {
        setCategory(matchingCategory);
      }
    } catch (error) {
      console.error('Error fetching meeting categories:', error);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    const combinedStartDate = new Date(startDate);
    const [startHours, startMinutes] = startTime.split(':');
    combinedStartDate.setHours(parseInt(startHours), parseInt(startMinutes));

    const combinedEndDate = new Date(endDate);
    const [endHours, endMinutes] = endTime.split(':');
    combinedEndDate.setHours(parseInt(endHours), parseInt(endMinutes));

    onEditMeeting({
      ...meeting,
      title,
      description,
      startDate: combinedStartDate,
      endDate: combinedEndDate,
      category,
      attendees,
      location: category?.isVirtual ? meetingLink : location
    });
    onClose();
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose} title='Edit Meeting' size='md'>
      <form onSubmit={handleSubmit} className='space-y-6'>
        <div className='grid grid-cols-1 gap-6'>
          <Input
            value={title}
            onChange={e => setTitle(e.target.value)}
            required
            placeholder='Enter meeting title'
            leftIcon={Type}
          />
          <TextEditor
            value={description}
            onChange={setDescription}
            placeholder='Enter meeting description'
          />
          <div className='flex items-center space-x-2'>
            <DatePicker
              value={startDate}
              onChange={setStartDate}
              placeholder='Start date'
              size='md'
              required
            />
            <TimePicker
              value={startTime}
              onChange={setStartTime}
              placeholder='Start time'
              size='md'
              required
            />
            <ArrowRight className='text-gray-400' />
            <DatePicker
              value={endDate}
              onChange={setEndDate}
              placeholder='End date'
              size='md'
              required
            />
            <TimePicker
              value={endTime}
              onChange={setEndTime}
              placeholder='End time'
              size='md'
              required
            />
          </div>
          <DropdownMenu
            options={categories.map(category => ({
              value: category,
              label: (
                <div className='flex items-center'>
                  <Badge colour={category.color} className='mr-2'>
                    {category.name}
                  </Badge>
                </div>
              )
            }))}
            value={category}
            onChange={setCategory}
            placeholder='Select a category'
            leftIcon={Tag}
          />
          <MultiSelectBox
            selectedOptions={attendees.map(email => ({
              value: email,
              label: email
            }))}
            onChange={newAttendees =>
              setAttendees(newAttendees.map(attendee => attendee.value))
            }
            placeholder='Enter attendee emails'
            allowCreate={true}
            size='medium'
            leftIcon={Users}
          />
          {category?.isVirtual ? (
            <Input
              value={meetingLink}
              onChange={e => setMeetingLink(e.target.value)}
              placeholder='Enter virtual meeting link'
              leftIcon={Video}
            />
          ) : (
            <Input
              value={location}
              onChange={e => setLocation(e.target.value)}
              placeholder='Enter meeting location'
              leftIcon={MapPin}
            />
          )}
        </div>
        <div className='flex justify-end space-x-2 pt-4'>
          <TextButton onClick={onClose} colour='base'>
            Cancel
          </TextButton>
          <FilledButton type='submit' colour='primary'>
            Save Changes
          </FilledButton>
        </div>
      </form>
    </Popup>
  );
};

export default EditMeeting;
