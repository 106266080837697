import React, { useState, useEffect } from 'react';
import { CheckCircle } from 'lucide-react';
import Logo from '../../../assets/logo-star.png';

const Loading = () => {
  const [steps, setSteps] = useState([
    { text: 'Retrieving your information...', completed: false },
    { text: 'Organising and processing data...', completed: false },
    { text: 'Finalising your personalised dashboard...', completed: false }
  ]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timers = steps.map((_, index) => {
      return setTimeout(() => {
        setSteps(prevSteps => {
          const updatedSteps = [...prevSteps];
          if (index < updatedSteps.length) {
            updatedSteps[index].completed = true;
          }
          return updatedSteps;
        });
        setProgress((index + 1) * (100 / steps.length));
      }, (index + 1) * 1500);
    });

    return () => timers.forEach(clearTimeout);
  }, []);

  return (
    <div className="min-h-screen bg-[#ba1b1d] flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        <img src={Logo} alt="Logo" className="mx-auto mb-6 h-[100px]" />
        <h1 className="text-2xl font-bold text-[#ba1b1d] mb-6 text-center">
          Setting Up Your Dashboard
        </h1>
        <div className="mb-6">
          <div className="w-full bg-neutral-200 rounded-full h-2.5">
            <div
              className="bg-[#ba1b1d] h-2.5 rounded-full transition-all duration-500 ease-out"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
        <ul className="space-y-4">
          {steps.map((step, index) => (
            <li key={index} className="flex items-center">
              <CheckCircle
                size={24}
                className={`mr-3 ${
                  step.completed ? 'text-[#ba1b1d]' : 'text-neutral-300'
                }`}
              />
              <span
                className={`text-lg ${
                  step.completed ? 'text-[#ba1b1d]' : 'text-neutral-500'
                }`}
              >
                {step.text}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Loading;
