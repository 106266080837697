import BaseModel from './BaseModel';

/**
 * Represents an ActivityLog in the system.
 * @extends BaseModel
 */
class ActivityLog extends BaseModel {
  /** @type {string} The name of the database table for ActivityLogs */
  static table = 'activity_logs';

  /** @type {string} The SQL query to select ActivityLog data with related information */
  static selectQuery = `
    *,
    owner:user_profiles(id, first_name, last_name, profile_photo),
    entity_type:core_entities(id, display_name),
    audit_type:core_entity_types(id, type, description)
  `;

  /**
   * Creates an instance of ActivityLog.
   * @param {Object} data - The activity log data.
   */
  constructor(data = {}) {
    super(data);
    this.owner = data.owner
      ? {
          id: data.owner.id,
          firstName: data.owner.first_name,
          lastName: data.owner.last_name,
          profilePhoto: data.owner.profile_photo
        }
      : null;
    this.entityType = data.entity_type
      ? {
          id: data.entity_type.id,
          displayName: data.entity_type.display_name
        }
      : null;
    this.entityId = data.entity_id || null;
    this.auditType = data.audit_type
      ? {
          id: data.audit_type.id,
          type: data.audit_type.type,
          description: data.audit_type.description
        }
      : null;
    this.pinned = data.pinned || false;
    this.internal = data.internal || true;
    this.oldData = data.old_data || null;
    this.newData = data.new_data || null;
    this.content = data.content || null;
  }

  /**
   * Converts the ActivityLog instance to a database-friendly format.
   * @returns {Object} The activity log data ready for database operations.
   */
  toDatabase() {
    return {
      ...super.toDatabase(),
      owner: this.owner ? this.owner.id : null,
      entity_type: this.entityType ? this.entityType.id : null,
      entity_id: this.entityId,
      audit_type: this.auditType ? this.auditType.id : null,
      pinned: this.pinned,
      internal: this.internal,
      old_data: this.oldData,
      new_data: this.newData,
      content: this.content
    };
  }
}

export default ActivityLog;
