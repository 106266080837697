import Personal from './Personal';
import Security from './Security';

const Account = () => (
  <div className='h-full flex flex-col overflow-y-auto'>
    <div className='flex-1 grid grid-cols-1 md:grid-cols-3 gap-10 px-4 sm:px-6 lg:px-8 py-10'>
      <div className='col-span-1 flex flex-col'>
        <h2 className='text-lg font-semibold text-gray-900'>Profile</h2>
        <p className='mt-1 text-sm text-gray-600'>
          This information will be displayed publicly so be careful what you
          share.
        </p>
      </div>
      <div className='col-span-2'>
        <Personal />
      </div>
      <div className='col-span-1 flex flex-col'>
        <h2 className='text-lg font-semibold text-gray-900'>Security</h2>
        <p className='mt-1 text-sm text-gray-600'>
          Update your password and manage two-factor authentication.
        </p>
      </div>
      <div className='col-span-2'>
        <Security />
      </div>
    </div>
  </div>
);

export default Account;
