import BaseModel from './BaseModel';

class ActivityTemplate extends BaseModel {
  static table = 'activity_templates';

  constructor(data = {}) {
    super(data);
    this.owner = data.owner || null;
    this.title = data.title || null;
    this.icon = data.icon || null;
    this.colour = data.colour || null;
    this.content = data.content || null;
    this.type = data.type || null;
  }

  toDatabase() {
    return {
      ...super.toDatabase(),
      owner: this.owner,
      title: this.title,
      icon: this.icon,
      colour: this.colour,
      content: this.content,
      type: this.type
    };
  }
}

export default ActivityTemplate;
