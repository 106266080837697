import React, { useEffect } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Typography from '@tiptap/extension-typography';
import {
  Bold,
  Italic,
  Strikethrough,
  Code,
  Eraser,
  Trash2,
  Heading1,
  Heading2,
  Heading3,
  List,
  ListOrdered,
  FileCode,
  Quote,
  Minus,
  Undo,
  Redo,
  Type
} from 'lucide-react';

// custom Extensions
import { SmilieReplacer } from '../../../utilities/tiptap/SmilieReplacer';

const TextEditor = ({
  label,
  labelHint,
  placeholder,
  value,
  onChange,
  className,
  hint,
  type = 'standard',
  ...props
}) => {
  const extensions = [
    StarterKit,
    Placeholder.configure({
      placeholder: placeholder || 'Start typing...'
    }),
    SmilieReplacer,
    Typography
  ];

  const editor = useEditor({
    editorProps: {
      attributes: {
        class:
          'prose prose-sm sm:prose-base lg:prose-lg xl:prose-2xl mb-12 focus:outline-none'
      },
      parseOptions: {
        preserveWhitespace: 'full'
      }
    },
    immediatelyRender: true,
    shouldRerenderOnTransaction: false,
    extensions,
    content: value,
    onUpdate: ({ editor }) => {
      const htmlContent = editor.getHTML();
      onChange(htmlContent);
    }
  });

  if (!editor) {
    return null;
  }

  const MenuBar = () => {
    if (!editor) {
      return null;
    }

    return (
      <div className='flex flex-wrap gap-1 p-1 bg-base-100 border-b border-base-300'>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('bold') ? 'bg-base-200' : ''
          }`}
        >
          <Bold size={18} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('italic') ? 'bg-base-200' : ''
          }`}
        >
          <Italic size={18} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('strike') ? 'bg-base-200' : ''
          }`}
        >
          <Strikethrough size={18} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={!editor.can().chain().focus().toggleCode().run()}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('code') ? 'bg-base-200' : ''
          }`}
        >
          <Code size={18} />
        </button>
        <button
          onClick={() => editor.chain().focus().unsetAllMarks().run()}
          className='p-2 rounded hover:bg-base-200'
        >
          <Eraser size={18} />
        </button>
        <button
          onClick={() => editor.chain().focus().clearNodes().run()}
          className='p-2 rounded hover:bg-base-200'
        >
          <Trash2 size={18} />
        </button>
        <button
          onClick={() => editor.chain().focus().setParagraph().run()}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('paragraph') ? 'bg-base-200' : ''
          }`}
        >
          <Type size={18} />
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('heading', { level: 1 }) ? 'bg-base-200' : ''
          }`}
        >
          <Heading1 size={18} />
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('heading', { level: 2 }) ? 'bg-base-200' : ''
          }`}
        >
          <Heading2 size={18} />
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('heading', { level: 3 }) ? 'bg-base-200' : ''
          }`}
        >
          <Heading3 size={18} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('bulletList') ? 'bg-base-200' : ''
          }`}
        >
          <List size={18} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('orderedList') ? 'bg-base-200' : ''
          }`}
        >
          <ListOrdered size={18} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('codeBlock') ? 'bg-base-200' : ''
          }`}
        >
          <FileCode size={18} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={`p-2 rounded hover:bg-base-200 ${
            editor.isActive('blockquote') ? 'bg-base-200' : ''
          }`}
        >
          <Quote size={18} />
        </button>
        <button
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
          className='p-2 rounded hover:bg-base-200'
        >
          <Minus size={18} />
        </button>
        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
          className='p-2 rounded hover:bg-base-200'
        >
          <Undo size={18} />
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
          className='p-2 rounded hover:bg-base-200'
        >
          <Redo size={18} />
        </button>
      </div>
    );
  };

  return (
    <div
      className={`border border-base-300 rounded-md overflow-hidden ${className}`}
    >
      <MenuBar />
      <EditorContent editor={editor} className='' />
    </div>
  );
};

export { TextEditor };
