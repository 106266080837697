import { createClient } from '@supabase/supabase-js';
import { v4 } from 'uuid';

const url = 'https://leqtvmuqmyxkdkaxxfqu.supabase.co';
const anonKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxlcXR2bXVxbXl4a2RrYXh4ZnF1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjM1Mzc3MzUsImV4cCI6MjAzOTExMzczNX0.0Z7m1Y279JCjXnnkrypIkRRMo49uJR5T6ul5RldMyUE';

const supabase = createClient(url, anonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    flowType: 'pkce'
  }
});

const realtime = (event = '*', table, callback, filter, filters = {}) => {
  return supabase
    .channel(v4())
    .on(
      'postgres_changes',
      {
        event,
        schema: 'public',
        table,
        filter
      },
      payload => {
        const filtersCheck = Object.entries(filters).every(([key, value]) => {
          return payload.new[key] == value;
        });

        if (filtersCheck) callback(payload);
      }
    )
    .subscribe();
};

export { supabase, realtime };
