import React from 'react';
import { Modal, TextButton, FilledButton } from 'core';

const DeleteCategoryModal = ({ isOpen, onClose, onDelete, category }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={`Delete ${category?.type}`}>
      <div className='space-y-4'>
        <p className='text-base-700'>
          Are you sure you want to delete <span className='font-medium'>{category?.type}</span>? Any tags in this
          category will be uncategorized.
        </p>
        <div className='flex justify-end space-x-2'>
          <TextButton onClick={onClose}>Cancel</TextButton>
          <FilledButton 
            onClick={onDelete} 
            colour='danger'
            disabled={category?.id === 225} // Prevent deleting default category
          >
            Delete Category
          </FilledButton>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteCategoryModal;
