import { useState } from 'react';
import { PrimaryInput } from '../../components/inputs/Forms';

const PasswordInput = ({ onChange, ...properties }) => {
  const [passwordStrength, setPasswordStrength] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    numberOrSpecial: false
  });
  const [strengthScore, setStrengthScore] = useState(0);
  const [showPasswordRequirements, setShowPasswordRequirements] =
    useState(false);

  const checkPasswordStrength = password => {
    const newStrength = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      numberOrSpecial: /[0-9!@#$%^&*(),.?":{}|<>]/.test(password)
    };
    setPasswordStrength(newStrength);
    const score = Object.values(newStrength).filter(Boolean).length;
    setStrengthScore(score);
    return score;
  };

  const getPasswordColor = strength => {
    if (strength === 4) return 'text-green-500';
    if (strength >= 3) return 'text-green-500';
    if (strength >= 2) return 'text-yellow-500';
    return 'text-red-500';
  };

  const getProgressBarColor = strength => {
    if (strength === 4) return 'bg-green-500';
    if (strength >= 3) return 'bg-green-500';
    if (strength >= 2) return 'bg-yellow-500';
    return 'bg-red-500';
  };

  const getPasswordStrengthText = strength => {
    if (strength === 4) return 'Strong';
    if (strength === 3) return 'Good';
    if (strength === 2) return 'Fair';
    return 'Weak';
  };

  const handlePasswordChange = e => {
    onChange(e);
    checkPasswordStrength(e.target.value);
  };

  return (
    <div>
      <PrimaryInput
        {...properties}
        onChange={handlePasswordChange}
        onFocus={() => setShowPasswordRequirements(true)}
      />
      {showPasswordRequirements && (
        <>
          <div className='mt-2 flex items-center justify-between'>
            <div className='flex-1 h-2 bg-neutral-200 rounded-full overflow-hidden relative mr-2'>
              <div
                className={`h-full ${getProgressBarColor(
                  strengthScore
                )} transition-all duration-300 ease-in-out`}
                style={{ width: `${(strengthScore / 4) * 100}%` }}
              ></div>
            </div>
            <span
              className={`px-2 py-1 text-xs font-semibold rounded ${getPasswordColor(
                strengthScore
              )}`}
            >
              {getPasswordStrengthText(strengthScore)}
            </span>
          </div>
          {strengthScore < 4 && (
            <PasswordRequirements passwordStrength={passwordStrength} />
          )}
        </>
      )}
    </div>
  );
};

const PasswordRequirements = ({ passwordStrength }) => (
  <div className='mt-2 p-3 pb-0 border border-neutral-300 rounded-md bg-base-100'>
    <p className='text-sm font-medium text-neutral-700 mb-2'>
      Your password should have:
    </p>
    <ul className='space-y-1 text-sm ps-5'>
      <li
        className={passwordStrength.length ? 'text-green-500' : 'text-red-500'}
      >
        {passwordStrength.length ? '✓' : '✗'} At least 8 characters
      </li>
      <li
        className={
          passwordStrength.uppercase ? 'text-green-500' : 'text-red-500'
        }
      >
        {passwordStrength.uppercase ? '✓' : '✗'} At least one uppercase letter
      </li>
      <li
        className={
          passwordStrength.lowercase ? 'text-green-500' : 'text-red-500'
        }
      >
        {passwordStrength.lowercase ? '✓' : '✗'} At least one lowercase letter
      </li>
      <li
        className={
          passwordStrength.numberOrSpecial ? 'text-green-500' : 'text-red-500'
        }
      >
        {passwordStrength.numberOrSpecial ? '✓' : '✗'} At least one number or
        special character
      </li>
    </ul>
  </div>
);

export default PasswordInput;
