import { User as UserIcon, Mail, Phone } from 'lucide-react';
import Entity from '../../../../components/entity/Entity';
import Field from '../../../../components/entity/Field';
import { EntityTypeEnum } from '../../../../utilities/Enumerables';
import DateCard from '../../../../components/entity/DateCard';
import ImportedUserProfile from '../../../../models/ImportedUserProfile';
import { Alert } from 'core';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ConvertUserPopup from '../../../../components/popups/import-converter/ConvertUserPopup';

const ImportedUserDetails = ({ currentUser }) => {
  const [convertPopupOpen, setConvertPopupOpen] = useState(false);
  const { id } = useParams();

  const infoItems = [
    {
      icon: UserIcon,
      label: 'First Name',
      field: 'firstName',
      isEditable: true
    },
    {
      icon: UserIcon,
      label: 'Last Name',
      field: 'lastName',
      isEditable: true
    },
    { icon: Mail, label: 'Email', field: 'email', isEditable: true }
    // {
    //   icon: Briefcase,
    //   label: 'Job Title',
    //   field: 'jobTitle',
    //   isEditable: true
    // }
  ];

  const accordionItems = [
    {
      title: 'Key Dates',
      content: ({ data, onUpdate }) => (
        <div className='grid grid-cols-2 md:grid-cols-3 gap-2'>
          <DateCard
            date={data.metrics.last_chase}
            label='Last Chase'
            isEditable={true}
            editField='metrics.last_chase'
            onUpdate={(field, value) =>
              onUpdate('metrics', { ...data.metrics, last_chase: value })
            }
          />
          <DateCard
            date={data.metrics.next_chase}
            label='Next Chase'
            isEditable={true}
            editField='metrics.next_chase'
            onUpdate={(field, value) =>
              onUpdate('metrics', { ...data.metrics, next_chase: value })
            }
          />
        </div>
      )
    },
    {
      title: 'Contact Details',
      content: ({ data }) => (
        <div>
          {data.phones &&
            data.phones.map((phone, index) => (
              <Field
                key={index}
                Icon={Phone}
                label={phone.type.charAt(0).toUpperCase() + phone.type.slice(1)}
                value={phone.number}
                isEditable={false}
              />
            ))}
        </div>
      )
    },
    { title: 'Other', content: <div>Other content</div> }
  ];

  const additionalTabs = [
    //{ label: 'Test 2', path: 'test2', content: <div>Test content</div> }
  ];

  const alerts = [
    {
      condition: entity => entity?.readOnly === false,
      content: entity => (
        <Alert
          title='Imported Data'
          style='warning'
          description='This is an imported record with limited functionality. To access full features, please convert to an active user account.'
          inline={true}
          actions={[
            {
              text: 'Convert to Live User',
              onClick: handleConvertClick
            }
          ]}
        />
      )
    },
    {
      condition: entity => entity?.readOnly === true,
      content: entity => (
        <Alert
          title='Read Only Data'
          style='info'
          description='This entity is marked as read only and cannot be changed.'
          inline={true}
        />
      )
    }
  ];

  const handleConvertClick = () => {
    setConvertPopupOpen(true);
  };

  return (
    <>
      <Entity
        currentUser={currentUser}
        entityType={EntityTypeEnum.ImportedUser}
        model={ImportedUserProfile}
        infoItems={infoItems}
        accordionItems={accordionItems}
        additionalTabs={additionalTabs}
        alertItems={alerts}
      />
      <ConvertUserPopup
        isOpen={convertPopupOpen}
        onClose={() => setConvertPopupOpen(false)}
        userId={id}
      />
    </>
  );
};

export default ImportedUserDetails;
