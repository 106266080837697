import React, { useState, useEffect } from 'react';
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  DialogBackdrop
} from '@headlessui/react';
import { ChevronRight, Search, Users, UserPlus, UserMinus } from 'lucide-react';
import UserProfile from '../../../models/UserProfile';
import BookingSpace from '../../../models/BookingSpace';
import { FilledButton } from 'core';

const AssignUser = ({ bookingSpaceId, onClose, onAssign }) => {
  const [query, setQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [previewUser, setPreviewUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bookingSpace, setBookingSpace] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchBookingSpace = async () => {
      try {
        const space = await BookingSpace.getById(bookingSpaceId);
        setBookingSpace(space);
        if (space.delegate && space.delegate.id) {
          const delegate = await UserProfile.getById(space.delegate.id);
          setSelectedUser(delegate);
          setPreviewUser(delegate);
        }
      } catch (err) {
        setError('Failed to fetch booking space data');
      } finally {
        setLoading(false);
      }
    };

    fetchBookingSpace();
  }, [bookingSpaceId]);

  const loadUserOptions = async searchQuery => {
    try {
      if (!bookingSpace?.companyId) return;

      const { data: fetchedUsers } = await UserProfile.getAll({
        fts: {
          operator: 'textSearch',
          value: searchQuery
        },
        company_id: {
          operator: 'eq',
          value: bookingSpace.companyId
        }
      });
      setUsers(fetchedUsers);
    } catch (err) {
      console.error('Failed to load user options:', err);
      setUsers([]);
    }
  };

  useEffect(() => {
    loadUserOptions(query);
  }, [query, bookingSpace?.companyId]);

  const handleAssign = async () => {
    setLoading(true);
    setError(null);
    try {
      await bookingSpace.update({
        delegate: previewUser
      });
      onAssign();
      onClose();
    } catch (err) {
      setError('Failed to assign user');
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = async () => {
    setLoading(true);
    setError(null);
    try {
      await bookingSpace.update({ delegate: null });
      onAssign();
      onClose();
    } catch (err) {
      setError('Failed to remove user');
    } finally {
      setLoading(false);
    }
  };

  const renderUserDetails = user => {
    if (!user) return null;

    return (
      <div className='hidden h-96 w-1/2 flex-none flex-col divide-y divide-primary-100 overflow-y-auto sm:flex'>
        <div className='flex-none p-6 text-center'>
          <div className='mx-auto h-16 w-16 rounded-full bg-primary-100 flex items-center justify-center'>
            <span className='text-xl font-medium text-primary-900'>
              {user.firstName[0]}
              {user.lastName[0]}
            </span>
          </div>
          <h2 className='mt-3 font-semibold text-primary-900'>{`${user.firstName} ${user.lastName}`}</h2>
          <p className='text-sm leading-6 text-primary-500'>{user.email}</p>
        </div>
        <div className='flex flex-auto flex-col justify-between p-6'>
          <dl className='grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-primary-700'>
            <dt className='col-end-1 font-semibold text-primary-900'>Email</dt>
            <dd className='truncate'>
              <a
                href={`mailto:${user.email}`}
                className='text-brand-600 underline'
              >
                {user.email}
              </a>
            </dd>
            {user.phones && user.phones.length > 0 && (
              <>
                <dt className='col-end-1 font-semibold text-primary-900'>
                  Phone Numbers
                </dt>
                {user.phones.map((phone, index) => (
                  <dd key={index} className='flex items-center'>
                    <span>{phone.number}</span>
                    <span className='ml-2 inline-flex items-center rounded-full bg-primary-100 px-2.5 py-0.5 text-xs font-medium text-primary-800'>
                      {phone.type}
                    </span>
                  </dd>
                ))}
              </>
            )}
          </dl>
          <FilledButton
            onClick={user.id === selectedUser?.id ? handleRemove : handleAssign}
            colour={user.id === selectedUser?.id ? 'danger' : 'primary'}
            className='mt-6 w-full justify-center'
            leftIcon={
              user.id === selectedUser?.id ? <UserMinus /> : <UserPlus />
            }
          >
            {user.id === selectedUser?.id
              ? 'Remove assigned user'
              : 'Assign this user'}
          </FilledButton>
        </div>
      </div>
    );
  };

  //   if (loading) {
  //     return <div>Loading...</div>;
  //   }

  //   if (error) {
  //     return <div>Error: {error}</div>;
  //   }

  return (
    <Dialog className='relative z-10' open={true} onClose={onClose}>
      <DialogBackdrop className='fixed inset-0 bg-primary-500 bg-opacity-25 transition-opacity' />

      <div className='fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20'>
        <DialogPanel className='mx-auto max-w-3xl transform divide-y divide-primary-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-primary-900 ring-opacity-5 transition-all'>
          <Combobox onChange={user => setPreviewUser(user)}>
            {() => (
              <>
                <div className='relative'>
                  <Search
                    className='pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-primary-400'
                    aria-hidden='true'
                  />
                  <ComboboxInput
                    className='h-12 w-full border-0 bg-transparent pl-11 pr-4 text-primary-900 placeholder:text-primary-400 focus:ring-0 sm:text-sm'
                    placeholder='Search users...'
                    onChange={event => setQuery(event.target.value)}
                  />
                </div>

                {(query === '' || users.length > 0) && (
                  <ComboboxOptions
                    as='div'
                    static
                    hold
                    className='flex transform-gpu divide-x divide-primary-100'
                  >
                    <div className='max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4'>
                      <div className='-mx-2 text-sm text-primary-700'>
                        {users.map(user => (
                          <ComboboxOption
                            as='div'
                            key={user.id}
                            value={user}
                            className='group flex cursor-default select-none items-center rounded-md p-2 data-[focus]:bg-primary-100 data-[focus]:text-primary-900'
                          >
                            <div className='h-6 w-6 flex-none rounded-full bg-primary-100 flex items-center justify-center'>
                              <span className='text-xs font-medium text-primary-900'>
                                {user.firstName[0]}
                                {user.lastName[0]}
                              </span>
                            </div>
                            <span className='ml-3 flex-auto truncate'>{`${user.firstName} ${user.lastName}`}</span>
                            <ChevronRight
                              className='ml-3 h-5 w-5 flex-none text-primary-400'
                              aria-hidden='true'
                            />
                          </ComboboxOption>
                        ))}
                      </div>
                    </div>

                    {renderUserDetails(previewUser || selectedUser)}
                  </ComboboxOptions>
                )}

                {query !== '' && users.length === 0 && (
                  <div className='px-6 py-14 text-center text-sm sm:px-14'>
                    <Users
                      className='mx-auto h-6 w-6 text-primary-400'
                      aria-hidden='true'
                    />
                    <p className='mt-4 font-semibold text-primary-900'>
                      No people found
                    </p>
                    <p className='mt-2 text-primary-500'>
                      We couldn't find anything with that term. Please try
                      again.
                    </p>
                  </div>
                )}
              </>
            )}
          </Combobox>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default AssignUser;
