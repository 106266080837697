import { useState, useEffect, useMemo, useContext } from 'react';
import { supabase } from '../../../utilities/supabase';
import StatsGroup from '../../../components/entity/stats/StatsGroup';
import { LogoLoader } from 'core';
import { UserProfileContext } from '../../../App';
import Booking from '../../../models/Booking';
import Table from '../../../components/tables/Table';
import { Link, useNavigate } from 'react-router-dom';
import { FilterOperatorEnum } from '../../../utilities/Enumerables';

const Home = () => {
  //Set the page title
  document.title = 'Dashboard | CRM | BOHSA LTD';
  const { userProfile } = useContext(UserProfileContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [monthStats, setMonthStats] = useState(null);
  const [incompleteBookings, setIncompleteBookings] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: stats, error: statsError } = await supabase.rpc(
          'getmonthstats',
          {
            target_month: new Date().toISOString().split('T')[0]
          }
        );
        if (statsError) throw statsError;
        setMonthStats(stats);

        const { data: bookings, error: bookingsError } = await Booking.getAll({
          booking_items_incomplete: {
            operator: FilterOperatorEnum.GREATER_THAN,
            value: 0
          }
        });
        if (bookingsError) throw bookingsError;
        setIncompleteBookings(bookings);
      } catch (error) {
        setError(error.message);
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatChange = value =>
    value === null ? '0%' : `${(value * 100).toFixed(1)}%`;

  const formatCurrency = value =>
    new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);

  const getChangeType = value =>
    value === null || value === 0
      ? 'neutral'
      : value > 0
      ? 'positive'
      : 'negative';

  const last30DaysStats = useMemo(
    () =>
      monthStats
        ? [
            {
              label: 'Bookings',
              value: monthStats.current_month.booking_count.toLocaleString(),
              change: formatChange(monthStats.comparisons.booking_count_change),
              changeType: getChangeType(
                monthStats.comparisons.booking_count_change
              ),
              previousValue:
                monthStats.previous_month.booking_count.toLocaleString()
            },
            {
              label: 'Booking Value',
              value: formatCurrency(monthStats.current_month.booking_value),
              change: formatChange(monthStats.comparisons.booking_value_change),
              changeType: getChangeType(
                monthStats.comparisons.booking_value_change
              ),
              previousValue: formatCurrency(
                monthStats.previous_month.booking_value
              )
            },
            {
              label: 'Conversion Rate',
              value: `${(
                monthStats.current_month.conversion_rate * 100
              ).toFixed(2)}%`,
              change: formatChange(
                monthStats.comparisons.conversion_rate_change
              ),
              changeType: getChangeType(
                monthStats.comparisons.conversion_rate_change
              ),
              previousValue: `${(
                monthStats.previous_month.conversion_rate * 100
              ).toFixed(2)}%`
            }
          ]
        : [],
    [monthStats]
  );

  const actionItemsStats = [
    {
      label: 'Incomplete Bookings',
      value: incompleteBookings.length.toString(),
      onClick: () => {
        navigate('/bookings?filter=incomplete');
      }
    }
  ];

  if (loading) return <LogoLoader logo={'/logo-star.png'} />;
  if (error) return <div className='text-center text-danger-700'>{error}</div>;

  return (
    <div className='p-4'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <h1 className='text-3xl font-bold text-neutral-800 mb-6'>
          Welcome {userProfile?.firstName || 'User'}.
        </h1>

        <div className='p-6 mb-6'>
          <h2 className='text-xl font-semibold text-neutral-800 mb-4'>
            This Month
          </h2>
          <StatsGroup stats={last30DaysStats} />
        </div>

        <div className='p-6 mb-6'>
          <h2 className='text-xl font-semibold text-neutral-800 mb-4'>
            Action Items
          </h2>
          <StatsGroup stats={actionItemsStats} />
        </div>

        <div className='p-6'>
          <h2 className='text-xl font-semibold text-neutral-800 mb-4'>
            Overdue Tasks
          </h2>
          <p>coming soon</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
