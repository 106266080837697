import React, { useState, useEffect } from 'react';
import { supabase } from '../../utilities/supabase';
import UserProfile from '../../models/UserProfile';
import { UserRound, Camera, Save, Trash } from 'lucide-react';
import {
  LogoLoader,
  FilledButton,
  OutlineButton,
  TextButton,
  SoftButton
} from 'core';
import { PrimaryInput, DropdownInput } from '../../components/inputs/Forms';
import { useNotification } from 'core';
import ProfilePicturePopup from '../../components/popups/account/ProfilePicture';

const Personal = () => {
  const [profile, setProfile] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phones, setPhones] = useState([]);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const { addNotification } = useNotification();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const {
          data: { user }
        } = await supabase.auth.getUser();
        if (user) {
          const { data, error } = await UserProfile.getByAuthId(user.id, true);
          if (error) throw error;

          const userProfile = data[0];
          setProfile(userProfile);
          setFirstName(userProfile.firstName || '');
          setLastName(userProfile.lastName || '');
          setPhones(userProfile.phones || []);
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        addNotification({
          type: 'error',
          title: 'Error',
          description: 'Failed to fetch profile'
        });
      }
    };

    fetchUserProfile();
  }, [addNotification]);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const updatedData = {
        first_name: firstName,
        last_name: lastName,
        phones
      };

      const { error } = await profile.update(updatedData);
      if (error) throw error;

      addNotification({
        type: 'success',
        title: 'Profile updated',
        description: 'Changes saved successfully'
      });
    } catch (error) {
      console.error('Error updating profile:', error);
      addNotification({
        type: 'error',
        title: 'Error',
        description: 'Failed to update profile'
      });
    }
  };

  const handlePhoneChange = async (index, field, value) => {
    try {
      const updatedPhones = phones.map((phone, i) =>
        i === index ? { ...phone, [field]: value } : phone
      );
      setPhones(updatedPhones);

      const { error } = await profile.update({ phones: updatedPhones });
      if (error) throw error;
    } catch (error) {
      console.error('Error updating phones:', error);
      addNotification({
        type: 'error',
        title: 'Error',
        description: 'Failed to update phone numbers'
      });
    }
  };

  const addPhone = () => {
    setPhones([...phones, { type: '', number: '', country_code: '' }]);
  };

  const removePhone = async index => {
    try {
      const updatedPhones = phones.filter((_, i) => i !== index);
      setPhones(updatedPhones);

      const { error } = await profile.update({ phones: updatedPhones });
      if (error) throw error;
    } catch (error) {
      console.error('Error removing phone:', error);
      addNotification({
        type: 'error',
        title: 'Error',
        description: 'Failed to remove phone number'
      });
    }
  };

  const handleImageUpload = e => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedImage(reader.result);
        setShowPhotoModal(true);
      };
      reader.readAsDataURL(file);
    }
  };

  if (!profile) {
    return <LogoLoader logo={'/logo-star.png'} />;
  }

  const phoneTypeOptions = [
    { value: 'mobile', label: 'Mobile' },
    { value: 'home', label: 'Home' },
    { value: 'work', label: 'Work' },
    { value: 'other', label: 'Other' }
  ];

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className='bg-white shadow-sm ring-1 ring-gray-900/5 rounded-xl md:col-span-2'
      >
        <div className='px-4 py-6 sm:p-8'>
          <div className='grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10'>
            <div className='col-span-full'>
              <label
                htmlFor='photo'
                className='block text-sm/6 font-medium text-gray-900'
              >
                Photo
              </label>
              <div className='mt-2 flex items-center gap-x-8'>
                {profile.profilePhoto ? (
                  <img
                    src={profile.profilePhoto}
                    alt='User'
                    className='h-20 w-20 rounded-full object-cover'
                  />
                ) : (
                  <UserRound className='h-12 w-12 text-gray-300' />
                )}
                <button
                  type='button'
                  className='rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                  onClick={() => document.getElementById('imageUpload').click()}
                >
                  Change
                </button>
                <input
                  type='file'
                  id='imageUpload'
                  style={{ display: 'none' }}
                  onChange={handleImageUpload}
                />
              </div>
            </div>

            <div className='grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2'>
              <div className='col-span-full flex gap-x-6'>
                <div className='flex-1'>
                  <label
                    htmlFor='firstName'
                    className='block text-sm/6 font-medium text-gray-900'
                  >
                    First name
                  </label>
                  <div className='mt-2'>
                    <PrimaryInput
                      id='firstName'
                      name='firstName'
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                    />
                  </div>
                </div>

                <div className='flex-1'>
                  <label
                    htmlFor='lastName'
                    className='block text-sm/6 font-medium text-gray-900'
                  >
                    Last name
                  </label>
                  <div className='mt-2'>
                    <PrimaryInput
                      id='lastName'
                      name='lastName'
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='col-span-full'>
              <h3 className='text-sm font-medium text-gray-900 mb-4'>
                Phone Numbers
              </h3>
              <div className='space-y-4'>
                {phones.map((phone, index) => (
                  <div key={index} className='flex items-center gap-x-4'>
                    <DropdownInput
                      id={`phoneType-${index}`}
                      name={`phoneType-${index}`}
                      value={phone.type}
                      onChange={e =>
                        handlePhoneChange(index, 'type', e.target.value)
                      }
                      options={phoneTypeOptions}
                      className='min-w-24'
                    />
                    <PrimaryInput
                      id={`phoneNumber-${index}`}
                      name={`phoneNumber-${index}`}
                      value={phone.number}
                      onChange={e =>
                        handlePhoneChange(index, 'number', e.target.value)
                      }
                    />
                    <TextButton
                      colour='danger'
                      size='lg'
                      onClick={() => removePhone(index)}
                    >
                      <Trash />
                    </TextButton>
                  </div>
                ))}
                <OutlineButton colour='base' size='md' onClick={addPhone}>
                  Add Phone
                </OutlineButton>
              </div>
            </div>
          </div>
        </div>

        <div className='px-4 py-4 sm:px-8 flex justify-end border-t border-gray-900/10'>
          <FilledButton type='submit' leftIcon={<Save />}>
            Save Changes
          </FilledButton>
        </div>
      </form>

      <ProfilePicturePopup
        isOpen={showPhotoModal}
        onClose={() => setShowPhotoModal(false)}
        uploadedImage={uploadedImage}
      />
    </>
  );
};

export default Personal;
