import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { UserProfileContext } from '../../../App';
import { FilledButton, SoftButton } from 'core';
import Automation from '../../../models/Automations';
import { supabase } from '../../../utilities/supabase';
import CoreEntityType from '../../../models/CoreEntityType';
import Page1 from './create/Page1';
import Page2 from './create/Page2';
import Page3 from './create/Page3';
import {
  textColours,
  bgColours,
  renderIcon,
  iconOptions,
  colourOptions
} from './ColoursIcons';

const CreateAutomation = ({
  onClose,
  entityId,
  entityType,
  edit,
  setEditAuto,
  setIsCreating,
  automations,
  setAutomations,
  isCreating
}) => {
  const navigate = useNavigate();

  const { userProfile } = useContext(UserProfileContext);

  const [currentStep, setCurrentStep] = useState(0);
  const [showErrors, setShowErrors] = useState(false);
  const [ready, setReady] = useState(false);

  const createEmptyRequest = (index, type) => ({
    name: `${type} Request ${index + 1}`,
    method: 'GET',
    url: '',
    headers: [{ key: 'Content-Type', value: 'application/json' }],
    body: ''
  });

  useEffect(() => {
    if (edit) handleEditAutomation(edit);
    else {
      setId(null);
      setName('');
      setDescription('');
      setIcon('Zap');
      setColour('blue');
      setSendRequests([createEmptyRequest(0, 'Send')]);
      setExitRequests([createEmptyRequest(0, 'Exit')]);
      setSendResponses([]);
      setExitResponses([]);
      setExitVariables([]);
      setFilters([{ key: '', condition: '', value: '', options: [] }]);
      setExitFilters([{ key: '', condition: '', value: '', options: [] }]);
    }
  }, [edit, isCreating]);

  const [id, setId] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [icon, setIcon] = useState('Zap');
  const [colour, setColour] = useState('blue');
  const [sendRequests, setSendRequests] = useState([
    createEmptyRequest(0, 'Send')
  ]);
  const [exitRequests, setExitRequests] = useState([
    createEmptyRequest(0, 'Exit')
  ]);
  const [sendResponses, setSendResponses] = useState([]);
  const [exitResponses, setExitResponses] = useState([]);
  const [exitVariables, setExitVariables] = useState([]);
  const [filters, setFilters] = useState([
    { key: '', condition: '', value: '', options: [] }
  ]);
  const [exitFilters, setExitFilters] = useState([
    { key: '', condition: '', value: '', options: [] }
  ]);

  const handleEditAutomation = automation => {
    setId(automation.id);
    setName(automation.name);
    setDescription(automation.description || '');
    setIcon(automation.metadata?.icon || 'Zap');
    setColour(automation.metadata?.colour || 'brand-600');
    setEntity(automation.entity.id || null);
    handleTableChange({ target: { value: automation.entity.id } });
    setRecheck(true);
    setAction(automation.trigger?.id || null);
    setSendRequests(
      automation.send
        ? automation.send.map((request, index) => ({
            ...request,
            name: request.name || `Send Request ${index + 1}`,
            headers: Array.isArray(request.headers)
              ? request.headers
              : Object.entries(request.headers).map(([key, value]) => ({
                  key,
                  value
                })),
            body: request.body || ''
          }))
        : [createEmptyRequest(0, 'Send')]
    );
    setExitRequests(
      automation.exit
        ? automation.exit.map((request, index) => ({
            ...request,
            name: request.name || `Exit Request ${index + 1}`,
            headers: Array.isArray(request.headers)
              ? request.headers
              : Object.entries(request.headers).map(([key, value]) => ({
                  key,
                  value
                })),
            body: request.body || ''
          }))
        : [createEmptyRequest(0, 'Exit')]
    );
    setFilters(
      automation.filters?.start || [
        { key: '', condition: '', value: '', options: [] }
      ]
    );
    setExitFilters(
      automation.filters?.exit || [
        { key: '', condition: '', value: '', options: [] }
      ]
    );
    setEditAuto(automation);
    setIsCreating(true);
  };

  const handleNameChange = e => setName(e.target.value);
  const handleDescriptionChange = e => setDescription(e.target.value);
  const handleIconChange = icon => setIcon(icon);
  const handleColourChange = colour => setColour(colour);

  const handleRequestChange = (type, index, field, value) => {
    const requests = type === 'send' ? [...sendRequests] : [...exitRequests];
    requests[index][field] = value;
    type === 'send' ? setSendRequests(requests) : setExitRequests(requests);
  };

  const handleHeaderChange = (
    type,
    requestIndex,
    headerIndex,
    field,
    value
  ) => {
    const requests = type === 'send' ? [...sendRequests] : [...exitRequests];
    requests[requestIndex].headers[headerIndex][field] = value;
    type === 'send' ? setSendRequests(requests) : setExitRequests(requests);
  };

  const addHeader = (type, requestIndex) => {
    const requests = type === 'send' ? [...sendRequests] : [...exitRequests];
    requests[requestIndex].headers.push({ key: '', value: '' });
    type === 'send' ? setSendRequests(requests) : setExitRequests(requests);
  };

  const removeHeader = (type, requestIndex, headerIndex) => {
    const requests = type === 'send' ? [...sendRequests] : [...exitRequests];
    requests[requestIndex].headers = requests[requestIndex].headers.filter(
      (_, i) => i !== headerIndex
    );
    type === 'send' ? setSendRequests(requests) : setExitRequests(requests);
  };

  const addRequest = type => {
    const newRequest = createEmptyRequest(
      type === 'send' ? sendRequests.length : exitRequests.length,
      type === 'send' ? 'Send' : 'Exit'
    );
    type === 'send'
      ? setSendRequests([...sendRequests, newRequest])
      : setExitRequests([...exitRequests, newRequest]);
  };

  const removeRequest = (type, index) => {
    const requests = type === 'send' ? [...sendRequests] : [...exitRequests];
    const updatedRequests = requests.filter((_, i) => i !== index);
    type === 'send'
      ? setSendRequests(updatedRequests)
      : setExitRequests(updatedRequests);
  };

  const handleCreateAutomation = async () => {
    try {
      const automationData = {
        name,
        description,
        entity: entity,
        metadata: {
          icon,
          colour
        },
        trigger: { id: action },
        owner: userProfile.id,
        send: sendRequests.map(request => ({
          name: request.name,
          method: request.method,
          url: request.url,
          headers: request.headers.reduce((acc, header) => {
            if (header.key && header.value) {
              acc[header.key] = header.value;
            }
            return acc;
          }, {}),
          body: request.body
        })),
        exit: exitRequests.map(request => ({
          name: request.name,
          method: request.method,
          url: request.url,
          headers: request.headers.reduce((acc, header) => {
            if (header.key && header.value) {
              acc[header.key] = header.value;
            }
            return acc;
          }, {}),
          body: request.body
        })),
        filters: {
          start: filters.map(({ key, condition, value }) => ({
            key,
            condition,
            value
          })),
          exit: exitFilters.map(({ key, condition, value }) => ({
            key,
            condition,
            value
          }))
        },
        updated_date: new Date(),
        created_date: new Date()
      };

      let savedAutomation;

      if (edit) {
        const automation = new Automation({
          ...edit,
          ...automationData
        });
        savedAutomation = await automation.update();
        setAutomations(
          automations.map(a =>
            a.id === savedAutomation.id ? savedAutomation : a
          )
        );
      } else {
        const automation = new Automation(automationData);
        savedAutomation = await automation.insert();
        setAutomations([...automations, savedAutomation]);
      }

      setIsCreating(false);
      setEditAuto(null);
    } catch (error) {
      console.error('Error creating or updating automation:', error);
    }
  };

  const handleTestConnection = async (type, index) => {
    try {
      const requestData = {
        entityType,
        entityId,
        method:
          type === 'send'
            ? sendRequests[index].method
            : exitRequests[index].method,
        url:
          type === 'send' ? sendRequests[index].url : exitRequests[index].url,
        headers: (type === 'send'
          ? sendRequests[index].headers
          : exitRequests[index].headers
        ).reduce((acc, header) => {
          if (header.key && header.value) {
            acc[header.key] = header.value;
          }
          return acc;
        }, {}),
        body:
          type === 'send' ? sendRequests[index].body : exitRequests[index].body
      };

      const { data, error } = await supabase.functions.invoke('connection', {
        body: JSON.stringify(requestData)
      });

      if (error) {
        throw new Error('Connection test failed');
      }

      if (type === 'send') {
        setSendResponses(prevResponses => {
          const newResponses = [...prevResponses];
          newResponses[index] = data;
          return newResponses;
        });

        generateExitVariables(data, index);
      } else {
        setExitResponses(prevResponses => {
          const newResponses = [...prevResponses];
          newResponses[index] = data;
          return newResponses;
        });
      }
    } catch (error) {
      console.error('Error testing connection:', error);
      if (type === 'send') {
        setSendResponses(prevResponses => {
          const newResponses = [...prevResponses];
          newResponses[index] = null;
          return newResponses;
        });
      } else {
        setExitResponses(prevResponses => {
          const newResponses = [...prevResponses];
          newResponses[index] = null;
          return newResponses;
        });
      }
    }
  };

  const generateExitVariables = (response, requestIndex) => {
    const variables = [];

    for (const header in response.headers) {
      variables.push({
        variable: `{{${requestIndex}.headers.${header}}}`,
        value: response.headers[header]
      });
    }

    variables.push({
      variable: `{{${requestIndex}.status}}`,
      value: response.status
    });

    variables.push({
      variable: `{{${requestIndex}.body.text}}`,
      value: response.body
    });
    if (response.headers['content-type']?.includes('application/json')) {
      try {
        const parsedBody = JSON.parse(response.body);
        const processObject = (obj, prefix = `${requestIndex}.body.json.`) => {
          for (const [key, value] of Object.entries(obj)) {
            if (Array.isArray(value)) {
              value.forEach((item, index) => {
                if (typeof item === 'object' && item !== null) {
                  processObject(item, `${prefix}${key}[${index}].`);
                } else {
                  variables.push({
                    variable: `{{${prefix}${key}[${index}]}}`,
                    value: item
                  });
                }
              });
            } else if (typeof value === 'object' && value !== null) {
              processObject(value, `${prefix}${key}.`);
            } else {
              variables.push({
                variable: `{{${prefix}${key}}}`,
                value: value
              });
            }
          }
        };
        processObject(parsedBody);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }

    setExitVariables(prevVariables => {
      const updatedVariables = [...prevVariables];
      updatedVariables[requestIndex] = variables;
      return updatedVariables;
    });
  };

  const [tableColumns, setTableColumns] = useState([]);

  const handleTableChange = async ({ target: { value } }) => {
    try {
      const { data, error } = await supabase.rpc('get_schema', {
        entity_id_param: value
      });
      if (error) {
        throw error;
      }
      setTableColumns(data.columns || []);
    } catch (error) {
      console.error('Error fetching schema:', error);
    }
  };

  const [entity, setEntity] = useState('');
  const [action, setAction] = useState(221);

  const [recheck, setRecheck] = useState(false);

  useEffect(() => {
    if (!recheck) return;

    setRecheck(false);

    const processFilters = (filters, setFilters) => {
      filters.forEach((filter, index) => {
        const entityMap = {
          2: {
            status: 29,
            sub_status: 30,
            delivery_type: 6
          },
          4: {
            status: 10,
            term_type: 11
          },
          35: {
            status: 37,
            category: 36
          }
        };

        const entityId = entityMap[entity]?.[filter.key];

        if (!entityId) return;

        CoreEntityType.getAll(
          { entity_id: { operator: 'eq', value: entityId } },
          1,
          100
        )
          .then(({ data }) => {
            const updatedOptions = data.map(entity => ({
              value: entity.id,
              label: entity.type
            }));

            setFilters(prevFilters =>
              prevFilters.map((f, i) => {
                if (i === index && f.key == filter.key) {
                  return {
                    ...f,
                    options: updatedOptions
                  };
                }
                return f;
              })
            );
          })
          .catch(error => {
            console.error('Error fetching core types:', error);
          });
      });
    };

    processFilters(filters, setFilters);
    processFilters(exitFilters, setExitFilters);
  }, [recheck]);

  const steps = [
    {
      title: 'Automation Details',
      render: (
        <Page1
          name={name}
          description={description}
          icon={icon}
          colour={colour}
          iconOptions={iconOptions}
          colourOptions={colourOptions}
          handleNameChange={handleNameChange}
          handleDescriptionChange={handleDescriptionChange}
          handleIconChange={handleIconChange}
          handleColourChange={handleColourChange}
          renderIcon={renderIcon}
          bgColours={bgColours}
          textColours={textColours}
          setReady={setReady} // Added for validation
          showErrors={showErrors}
        />
      )
    },
    {
      title: 'Automation Filters',
      render: (
        <Page3
          entity={entity}
          action={action}
          setEntity={setEntity}
          setAction={setAction}
          handleTableChange={handleTableChange}
          filters={filters}
          tableColumns={tableColumns}
          exitFilters={exitFilters}
          setFilters={setFilters}
          setExitFilters={setExitFilters}
          setRecheck={setRecheck}
          showErrors={showErrors}
          setReady={setReady}
        />
      )
    },
    {
      title: 'Automation Requests',
      render: (
        <Page2
          bgColours={bgColours}
          colour={colour}
          textColours={textColours}
          icon={icon}
          renderIcon={renderIcon}
          sendRequests={sendRequests}
          handleRequestChange={handleRequestChange}
          handleHeaderChange={handleHeaderChange}
          removeHeader={removeHeader}
          addHeader={addHeader}
          sendResponses={sendResponses}
          handleTestConnection={handleTestConnection}
          removeRequest={removeRequest}
          addRequest={addRequest}
          exitVariables={exitVariables}
          exitRequests={exitRequests}
          exitResponses={exitResponses}
        />
      )
    }
  ];

  const handleNext = () => {
    console.log(`Current Step: ${currentStep}, Ready: ${ready}`);
    if ((currentStep == 0 || currentStep == 1) && !ready) {
      setShowErrors(true);
    } else {
      setCurrentStep(prev => Math.min(steps.length - 1, prev + 1));
      setShowErrors(false);
      if (currentStep !== 2) {
        setReady(false);
      }
    }
  };

  return (
    <div className='bg-base-100 overflow-y-scroll'>
      <div className='max-w-4xl mx-auto px-4 py-8 overflow-y-scroll'>
        <SoftButton
          colour='primary'
          size='md'
          leftIcon={<ArrowLeft size={18} />}
          onClick={() => {
            setIsCreating(false);
            setEditAuto(null);
            navigate('/automations');
          }}
          className='mb-8'
        >
          Back to Automations
        </SoftButton>

        <div className='bg-white shadow-md rounded-lg overflow-visible'>
          <div className='px-6 py-4'>
            <h2 className='text-2xl font-bold'>
              {edit ? 'Update Automation' : 'Create New Automation'}
            </h2>
          </div>

          <div className='p-6'>
            <div className='mb-8'>
              <div className='flex justify-between'>
                {steps.map((step, index) => (
                  <div
                    key={index}
                    className={`flex items-center ${
                      index <= currentStep ? 'text-brand-700' : 'text-base-400'
                    }`}
                  >
                    <div
                      className={`w-8 h-8 rounded-full flex items-center justify-center ${
                        index <= currentStep
                          ? 'bg-brand-700 text-white'
                          : 'bg-base-200'
                      }`}
                    >
                      {index + 1}
                    </div>
                    <span className='ml-2 text-sm font-medium'>
                      {step.title}
                    </span>
                  </div>
                ))}
              </div>
              <div className='mt-4 h-2 bg-base-200 rounded-full'>
                <div
                  className='h-full bg-brand-700 rounded-full transition-all duration-300 ease-in-out'
                  style={{
                    width: `${((currentStep + 1) / steps.length) * 100}%`
                  }}
                ></div>
              </div>
            </div>

            {steps[currentStep].render}

            <div className='mt-8 flex justify-between'>
              {edit && currentStep == 0 ? (
                <SoftButton
                  colour='danger'
                  onClick={async () => {
                    try {
                      const { error } = await supabase
                        .from('automations')
                        .delete()
                        .eq('id', id);

                      if (error) {
                        throw error;
                      }

                      setAutomations(prevAutomations =>
                        prevAutomations.filter(
                          automation => automation.id !== edit.id
                        )
                      );

                      console.log('Delete action successful');
                    } catch (error) {
                      console.error('Error deleting automation:', error);
                    } finally {
                      setIsCreating(false);
                      setEditAuto(null);
                    }
                  }}
                >
                  Delete
                </SoftButton>
              ) : (
                <SoftButton
                  colour='primary'
                  size='lg'
                  leftIcon={<ArrowLeft size={18} />}
                  onClick={() => setCurrentStep(prev => Math.max(0, prev - 1))}
                  disabled={currentStep === 0}
                >
                  Previous
                </SoftButton>
              )}

              {currentStep < steps.length - 1 ? (
                <FilledButton
                  colour='primary'
                  size='lg'
                  rightIcon={<ArrowRight size={18} />}
                  onClick={handleNext}
                >
                  Next
                </FilledButton>
              ) : (
                <FilledButton
                  colour='primary'
                  size='lg'
                  onClick={handleCreateAutomation}
                >
                  {edit ? 'Update Automation' : 'Create Automation'}
                </FilledButton>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAutomation;
