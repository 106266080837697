import React from 'react';

const ProgressBar = ({ title, progress, steps, colour = 'primary' }) => {
  const progressPercentage = (progress / steps.length) * 100;

  const colourClasses = {
    brand: 'bg-brand-600',
    primary: 'bg-primary-600',
    info: 'bg-info-600',
    success: 'bg-success-600',
    warning: 'bg-warning-600',
    danger: 'bg-danger-600'
  };

  const textColourClasses = {
    brand: 'text-brand-600',
    primary: 'text-primary-600',
    info: 'text-info-600',
    success: 'text-success-600',
    warning: 'text-warning-600',
    danger: 'text-danger-600'
  };

  const getColourClass = colour => {
    return colourClasses[colour] || `bg-${colour}-600`;
  };

  const getTextColourClass = colour => {
    return textColourClasses[colour] || `text-${colour}-600`;
  };
  return (
    <div>
      <h4 className='sr-only'>Status</h4>
      <div aria-hidden='true'>
        <div className='overflow-hidden rounded-full bg-base-200'>
          <div
            style={{ width: `${progressPercentage}%` }}
            className={`h-2 rounded-full ${getColourClass(colour)}`}
          />
        </div>
        <div className='mt-2'>
          <div className='hidden md:grid text-sm font-medium text-base-600' style={{
            gridTemplateColumns: `repeat(${steps.length}, minmax(0, 1fr))`
          }}>
            {steps.map((step, index) => (
              <div
                key={index}
                className={`text-right ${
                  index < progress ? getTextColourClass(colour) : ''
                }`}
              >
                {step.label}
              </div>
            ))}
          </div>
          <div className='md:hidden text-sm font-medium text-base-600'>
            <div className={getTextColourClass(colour)}>
              {steps[progress - 1] ? steps[progress - 1].label : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProgressBar };
