import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { GripVertical, Plus, X } from 'lucide-react';
import {
  FilledButton,
  OutlineButton,
  TextButton,
  Popup,
  Input,
  ColourPicker,
  Checkbox
} from 'core';

const EditPipeline = ({ pipeline, onClose, onSave }) => {
  const [title, setTitle] = useState(pipeline.title);
  const [columns, setColumns] = useState(pipeline.groups);
  const [isArchived, setIsArchived] = useState(pipeline.archived || false);
  const [isPrivate, setIsPrivate] = useState(pipeline.private || false);

  useEffect(() => {
    setTitle(pipeline.title);
    setColumns(pipeline.groups);
    setIsArchived(pipeline.archived || false);
    setIsPrivate(pipeline.private || false);
  }, [pipeline]);

  const handleDragEnd = result => {
    if (!result.destination) return;

    const items = Array.from(columns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedColumns = items.map((item, index) => ({
      ...item,
      order: index
    }));

    setColumns(updatedColumns);
  };

  const handleColumnChange = (index, field, value) => {
    const updatedColumns = [...columns];
    updatedColumns[index] = { ...updatedColumns[index], [field]: value };
    setColumns(updatedColumns);
  };

  const handleAddColumn = () => {
    const newColumnId = Math.max(...columns.map(col => col.id), 0) + 1;
    const newColumn = {
      id: newColumnId,
      label: 'New Column',
      colour: 'gray',
      order: columns.length
    };
    setColumns([...columns, newColumn]);
  };

  const handleRemoveColumn = index => {
    const updatedColumns = columns
      .filter((_, i) => i !== index)
      .map((col, i) => ({ ...col, order: i }));
    setColumns(updatedColumns);
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSave({
      ...pipeline,
      title,
      groups: columns,
      archived: isArchived,
      private: isPrivate,
    });
    onClose();
  };

  const handleColourChange = (index, colour) => {
    // Extract the base colour name, ignoring shade
    const baseColour = colour.split('-')[0];
    handleColumnChange(index, 'colour', baseColour);
  };

  return (
    <Popup isOpen={true} onClose={onClose} title='Edit Pipeline' size='md'>
      <form onSubmit={handleSubmit}>
        <div className='flex'>
          <div className='w-1/2 pr-4'>
            <Input
              label='Pipeline Title'
              value={title}
              onChange={e => setTitle(e.target.value)}
              className='mb-4'
            />

            <h3 className='text-lg font-semibold mb-2'>Pipeline Columns</h3>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId='columns'>
                {provided => (
                  <ul
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className='space-y-2'
                  >
                    {columns.map((column, index) => (
                      <Draggable
                        key={column.id}
                        draggableId={column.id.toString()}
                        index={index}
                      >
                        {provided => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className='flex items-center space-x-2 bg-gray-100 p-2 rounded'
                          >
                            <div {...provided.dragHandleProps}>
                              <GripVertical size={20} />
                            </div>
                            <Input
                              value={column.label}
                              onChange={e =>
                                handleColumnChange(
                                  index,
                                  'label',
                                  e.target.value
                                )
                              }
                              className='flex-grow'
                            />
                            <ColourPicker
                              showShades={false}
                              colour={column.colour}
                              onChange={colour =>
                                handleColourChange(index, colour)
                              }
                            />
                            <TextButton
                              onClick={() => handleRemoveColumn(index)}
                            >
                              <X size={20} />
                            </TextButton>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
            <TextButton onClick={handleAddColumn} className='mt-2'>
              <Plus size={20} /> Add Column
            </TextButton>
          </div>
          <div className='w-px border-r border-gray-200 my-4'></div>
          <div className='w-1/2 pl-4'>
            <Checkbox
              label='Archive Pipeline'
              checked={isArchived}
              onChange={e => setIsArchived(e.target.checked)}
            />
            <Checkbox
              label='Private Pipeline'
              checked={isPrivate}
              onChange={e => setIsPrivate(e.target.checked)}
            />
          </div>
        </div>

        <div className='mt-4 flex justify-end space-x-2'>
          <OutlineButton type='button' onClick={onClose}>
            Cancel
          </OutlineButton>
          <FilledButton type='submit'>Save Changes</FilledButton>
        </div>
      </form>
    </Popup>
  );
};

export default EditPipeline;
