import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { supabase } from '../../../utilities/supabase';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'core';

const PipelinesTab = ({ entity, onUpdate }) => {
  const [entityPipelines, setEntityPipelines] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPipelines = async () => {
      const { data: pipelinesData, error: pipelinesError } = await supabase
        .from('pipelines')
        .select('*');

      if (pipelinesError) {
        console.error('Error fetching pipelines:', pipelinesError);
        return;
      }

      const entityPipelinesData = pipelinesData.filter(pipeline =>
        entity.pipelines.some(
          entityPipeline => entityPipeline.id === pipeline.id
        )
      );

      setEntityPipelines(entityPipelinesData);
    };

    fetchPipelines();
  }, [entity]);

  const handlePipelineClick = pipelineId => {
    navigate(`/pipelines/${pipelineId}`);
  };

  const handleRemoveFromPipeline = async (e, pipelineId) => {
    e.stopPropagation();
    try {
      const updatedPipelines = entityPipelines.filter(
        pipeline => pipeline.id !== pipelineId
      );
      const formattedPipelines = updatedPipelines.map(pipeline => ({
        id: pipeline.id,
        group: entity.pipelines.find(p => p.id === pipeline.id)?.group || 1,
        order: 0
      }));

      const { data: entityData } = await supabase
        .from('core_entities')
        .select('table_name')
        .eq('id', pipelineId)
        .single();

      await supabase
        .from(entityData.table_name)
        .update({ pipelines: formattedPipelines })
        .eq('id', entity.id);

      setEntityPipelines(updatedPipelines);
      onUpdate();
    } catch (error) {
      console.error('Error removing entity from pipeline:', error);
    }
  };

  return (
    <div className='space-y-2'>
      {entityPipelines.map(pipeline => {
        const currentGroup = pipeline.groups.find(
          group =>
            entity.pipelines.find(p => p.id === pipeline.id)?.group === group.id
        );
        return (
          <div
            key={pipeline.id}
            className='flex items-center justify-between py-1 px-2 rounded-md bg-base-100 hover:bg-danger-100 transition-colors duration-200 cursor-pointer group'
            onClick={() => handlePipelineClick(pipeline.id)}
          >
            <div className='flex items-center space-x-2'>
              <span className='font-small text-sm text-base-900'>
                {pipeline.title}
              </span>
              {currentGroup && (
                <>
                  {/* <span className='text-sm text-base-900'>|</span>
                  <span className='text-sm text-base-900'>
                    {currentGroup.label}
                  </span> */}
                  <Badge size='sm' colour={currentGroup.colour}>
                    {currentGroup.label}
                  </Badge>
                </>
              )}
            </div>
            <button
              className='text-base-600 hover:text-danger-600 transition-colors duration-200 opacity-0 group-hover:opacity-100'
              aria-label='Remove from pipeline'
              onClick={e => handleRemoveFromPipeline(e, pipeline.id)}
            >
              <X size={16} className='text-danger-600' />
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default PipelinesTab;
