import React from 'react';
import { useNavigate } from 'react-router-dom';
import Task from '../../../models/Task';
import EntityTable from '../../../components/tables/EntityTable';
import { formatDate, formatProfile } from '../../../utilities/Formatting';
import { Link } from 'react-router-dom';
import { Pencil, Trash, SquareArrowOutUpRight } from 'lucide-react';
import { EntityTypeEnum } from '../../../utilities/Enumerables';

const Tasks = () => {
  //Set the page title
  document.title = 'Tasks | CRM | BOHSA LTD';
  const navigate = useNavigate();

  const menuItems = [
    {
      label: 'Open in new tab',
      icon: <SquareArrowOutUpRight size={14} />,
      onClick: data => window.open(`/tasks/${data.id}`, '_blank')
    },
    {
      label: 'Edit',
      icon: <Pencil size={14} />,
      onClick: data => navigate(`/tasks/${data.id}`)
    },
    {
      label: 'Delete',
      icon: <Trash size={14} className='text-danger-700' />,
      onClick: data => console.log('Delete clicked', data)
    }
  ];

  const handleRowClick = data => {
    navigate(`/tasks/${data.id}`);
  };

  return (
    <div className='p-4'>
      <EntityTable
        name='Tasks'
        filter={[
          { field: 'id', label: 'ID', type: 'text' },
          { field: 'title', label: 'Title', type: 'text' },
          {
            field: 'category.id',
            label: 'Category',
            type: 'select',
            options: [{ key: 155, value: 'Default' }]
          },
          { field: 'priority', label: 'Priority', type: 'text' },
          { field: 'status.name', label: 'Status', type: 'text' },
          { field: 'entityType', label: 'Entity Type', type: 'text' },
          { field: 'entityId', label: 'Entity ID', type: 'text' },
          { field: 'dueDate', label: 'Due Date', type: 'date' },
          { field: 'createdDate', label: 'Created Date', type: 'date' },
          { field: 'updatedDate', label: 'Updated Date', type: 'date' }
        ]}
        sort={[
          { value: 'title', label: 'Title' },
          { value: 'dueDate', label: 'Due Date' },
          { value: 'createdDate', label: 'Date Created' },
          { value: 'updatedDate', label: 'Last Modified' }
        ]}
        columns={[
          {
            label: 'ID',
            field: 'id',
            cell: value => (
              <Link
                to={`/tasks/${value}`}
                className='text-brand-700 hover:text-brand-800'
              >
                {value}
              </Link>
            )
          },
          { label: 'Title', field: 'title' },
          {
            label: 'Category',
            field: 'category',
            cell: value => value?.name || ''
          },
          { label: 'Priority', field: 'priority' },
          {
            label: 'Status',
            field: 'status',
            cell: value => value?.name || ''
          },
          {
            label: 'Due Date',
            field: 'dueDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Created Date',
            field: 'createdDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Updated Date',
            field: 'updatedDate',
            cell: value => formatDate(value)
          },
          {
            label: 'Owner',
            field: 'owner',
            cell: value => formatProfile(value)
          }
        ]}
        menuItems={menuItems}
        model={Task}
        entityType={EntityTypeEnum.Task}
        onRowClick={handleRowClick}
        canCreate={true}
      />
    </div>
  );
};

export default Tasks;
