import React, { useState, useEffect } from 'react';
import { FilledButton, TextButton } from 'core';
import Popup from '../Popup';
import TabView from '../../navigation/TabView';
import Details from './Details';
import Comments from './Comments';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Task from '../../../models/Task';

const TaskPopup = ({ task: initialTask, onClose }) => {
  const [task, setTask] = useState(initialTask);
  const [editedTask, setEditedTask] = useState({ ...initialTask });
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { '*': wildcard } = useParams();

  useEffect(() => {
    if (!wildcard) {
      navigate(`details`, { replace: true });
    }
  }, [wildcard, navigate]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const assignees = editedTask.assignees.map(assignee => assignee.value);
      const updatedTask = await task.update({
        ...editedTask,
        assignees
      });
      setTask(updatedTask);
      onClose();
    } catch (error) {
      console.error('Error updating task:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const tabs = [
    {
      label: 'Details',
      path: `${task.id}/details`,
      content: <Details editedTask={editedTask} setEditedTask={setEditedTask} />
    },
    {
      label: 'Comments',
      path: `${task.id}/comments`,
      content: <Comments taskId={task.id} />
    }
  ];

  return (
    <Popup title={editedTask.title} onClose={onClose}>
      <div className='space-y-4'>
        <TabView
          tabs={tabs}
          baseUrl={`${task.id}`}
          fullWidth={true}
          default={'details'}
        />
        <div className='flex justify-end space-x-2'>
          <TextButton colour='base' onClick={onClose}>
            Cancel
          </TextButton>
          <FilledButton
            colour='primary'
            onClick={handleSave}
            disabled={isLoading}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </FilledButton>
        </div>
      </div>
    </Popup>
  );
};

export default TaskPopup;
