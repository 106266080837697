import { SoftButton, Badge, Input, DropdownInput } from 'core';
import { Plus, X, LogOut, RotateCw, ChevronRight } from 'lucide-react';
import { Disclosure } from '@headlessui/react';

const Page2 = ({
  bgColours,
  textColours,
  colour,
  icon,
  renderIcon,
  sendRequests,
  handleRequestChange,
  handleHeaderChange,
  removeHeader,
  addHeader,
  sendResponses,
  handleTestConnection,
  removeRequest,
  addRequest,
  exitVariables,
  exitRequests,
  exitResponses
}) => (
  <div>
    <div className='grid grid-cols-2 gap-4 h-[56vh]'>
      <div className='overflow-y-auto pr-4'>
        <div className='flex items-center justify-between mb-5'>
          <div className='flex items-center'>
            <div
              className={`w-12 h-12 flex items-center justify-center rounded-lg transition-colors duration-300 ${bgColours[colour]} ${textColours[colour]}`}
            >
              {renderIcon(colour, icon)}
            </div>
            <div>
              <span className='text-lg font-semibold ml-3'>Send</span>
              <p className='text-sm ml-3 text-gray-600'>
                Triggered when the entity is updated
              </p>
            </div>
          </div>
        </div>
        <h3 className='text-lg font-semibold'>Requests</h3>
        {sendRequests.map((request, index) => (
          <Disclosure key={index}>
            {({ open }) => (
              <>
                <div className='flex items-center mb-2'>
                  <Disclosure.Button
                    className='cursor-pointer text-left mr-2'
                    onClick={() =>
                      handleRequestChange('send', index, 'open', !open)
                    }
                  >
                    <ChevronRight
                      className={open ? 'rotate-90' : ''}
                    ></ChevronRight>
                  </Disclosure.Button>
                  <Input
                    type='text'
                    value={request.name}
                    onChange={e =>
                      handleRequestChange('send', index, 'name', e.target.value)
                    }
                    className='flex-grow'
                  />
                </div>
                <Disclosure.Panel>
                  <div className='mb-4'>
                    <DropdownInput
                      label='Method'
                      value={request.method}
                      onChange={e =>
                        handleRequestChange(
                          'send',
                          index,
                          'method',
                          e.target.value
                        )
                      }
                      options={[
                        { value: 'GET', label: 'GET' },
                        { value: 'POST', label: 'POST' },
                        { value: 'PATCH', label: 'PATCH' },
                        { value: 'DELETE', label: 'DELETE' },
                        { value: 'PUT', label: 'PUT' }
                      ]}
                    />
                  </div>
                  <div className='mb-4'>
                    <Input
                      label='URL'
                      type='text'
                      value={request.url}
                      onChange={e =>
                        handleRequestChange(
                          'send',
                          index,
                          'url',
                          e.target.value
                        )
                      }
                      placeholder='https://api.example.com/endpoint'
                    />
                  </div>
                  <div className='mb-4'>
                    <label className='block text-sm font-medium leading-6 text-gray-900 mb-2'>
                      Headers
                    </label>
                    {request.headers.map((header, headerIndex) => (
                      <div key={headerIndex} className='flex gap-2 mb-2'>
                        <Input
                          type='text'
                          value={header.key}
                          onChange={e =>
                            handleHeaderChange(
                              'send',
                              index,
                              headerIndex,
                              'key',
                              e.target.value
                            )
                          }
                          placeholder='Key'
                        />
                        <Input
                          type='text'
                          value={header.value}
                          onChange={e =>
                            handleHeaderChange(
                              'send',
                              index,
                              headerIndex,
                              'value',
                              e.target.value
                            )
                          }
                          placeholder='Value'
                          className='mr-2'
                        />
                        <SoftButton
                          colour='primary'
                          onClick={() =>
                            removeHeader('send', index, headerIndex)
                          }
                        >
                          <X size={16} />
                        </SoftButton>
                      </div>
                    ))}
                    <SoftButton
                      leftIcon={<Plus size={16} />}
                      onClick={() => addHeader('send', index)}
                      className='mt-2'
                    >
                      Add Header
                    </SoftButton>
                  </div>
                  {request.method !== 'GET' && (
                    <div className='mb-4'>
                      <label className='block text-sm font-medium leading-6 text-gray-900 mb-2'>
                        Body
                      </label>
                      <textarea
                        className='w-full p-2 border border-gray-300 rounded-md'
                        value={request.body}
                        onChange={e =>
                          handleRequestChange(
                            'send',
                            index,
                            'body',
                            e.target.value
                          )
                        }
                        placeholder='Enter request body...'
                        rows={4}
                      />
                    </div>
                  )}
                  <div className='mb-4'>
                    <div className='flex flex-col'>
                      <div className='flex items-center justify-between mb-2'>
                        <SoftButton
                          onClick={() => handleTestConnection('send', index)}
                          leftIcon={<RotateCw></RotateCw>}
                          className='mr-2'
                        >
                          Test Request
                        </SoftButton>
                        {sendResponses[index] ? (
                          <div className='flex items-center'>
                            <span className='mr-2 text-sm'>Status:</span>
                            <div className='flex items-center'>
                              {sendResponses[index].status >= 200 &&
                              sendResponses[index].status < 300 ? (
                                <div className='w-4 h-4 rounded-full bg-green-500 mr-2'></div>
                              ) : (
                                <div className='w-4 h-4 rounded-full bg-red-500 mr-2'></div>
                              )}
                              <span className='mr-1'>
                                {sendResponses[index].status}
                              </span>
                              <span>({sendResponses[index].statusText})</span>
                            </div>
                          </div>
                        ) : (
                          <span className='text-sm'>
                            Click to test your request
                          </span>
                        )}
                      </div>
                      {sendResponses[index] && (
                        <Disclosure>
                          {({ open }) => (
                            <>
                              <div className='flex justify-end'>
                                <Disclosure.Button className='cursor-pointer text-sm flex items-center'>
                                  <span className='mr-1'>
                                    <ChevronRight
                                      className={open ? 'rotate-90' : ''}
                                    />
                                  </span>
                                  Response
                                </Disclosure.Button>
                              </div>

                              <Disclosure.Panel>
                                <div className='mt-2'>
                                  <h5 className='text-sm mb-2'>Headers</h5>
                                  <div className='bg-gray-100 p-4 rounded-md overflow-auto max-h-40'>
                                    <pre className='text-sm mb-4'>
                                      {JSON.stringify(
                                        sendResponses[index].headers,
                                        null,
                                        2
                                      )}
                                    </pre>
                                  </div>
                                  <h5 className='text-sm mt-4 mb-2'>Body</h5>
                                  <div className='bg-gray-100 p-4 rounded-md overflow-auto max-h-40'>
                                    <pre className='text-sm'>
                                      {sendResponses[index].headers[
                                        'content-type'
                                      ] === 'application/json'
                                        ? (() => {
                                            try {
                                              return JSON.stringify(
                                                JSON.parse(
                                                  sendResponses[index].body ||
                                                    '{}'
                                                ),
                                                null,
                                                2
                                              );
                                            } catch (error) {
                                              console.error(
                                                'JSON parse error:',
                                                error
                                              );
                                              return sendResponses[index].body;
                                            }
                                          })()
                                        : sendResponses[index].body}
                                    </pre>
                                  </div>
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )}
                    </div>
                  </div>
                  <SoftButton
                    colour='danger'
                    onClick={() => removeRequest('send', index)}
                    className='mt-2'
                  >
                    Remove Request
                  </SoftButton>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
        <SoftButton
          leftIcon={<Plus size={16} />}
          onClick={() => addRequest('send')}
          className='mt-4'
        >
          Add Send Request
        </SoftButton>
      </div>
      <div className='overflow-y-auto pl-2'>
        <div className='flex items-center justify-between mb-5'>
          <div className='flex items-center'>
            <div
              className={`w-12 h-12 flex items-center justify-center rounded-lg transition-colors duration-300 ${bgColours[colour]} ${textColours[colour]}`}
            >
              <LogOut
                strokeWidth={2.5}
                className={`w-6 h-6 ${textColours[colour]}`}
              />
            </div>
            <div>
              <span className='text-lg font-semibold ml-3'>Exit</span>
              <p className='text-sm ml-3 text-gray-600'>
                Triggered when the flow is removed
              </p>
            </div>
          </div>
        </div>
        <div className='mb-8'>
          {exitVariables[0] && (
            <>
              <h3 className='text-lg font-semibold'>Variables</h3>
              {exitVariables.map((variables, index) => (
                <Disclosure key={index}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className='cursor-pointer mb-2 flex items-center'>
                        <span className='mr-1'>
                          <ChevronRight className={open ? 'rotate-90' : ''} />
                        </span>
                        {sendRequests[index]?.name ||
                          `Send Request ${index + 1}`}{' '}
                        Variables
                      </Disclosure.Button>
                      <Disclosure.Panel>
                        <div className='bg-gray-100 p-4 rounded-md space-y-2 cursor-pointer'>
                          {variables.map(variable => (
                            <div className='space-x-2'>
                              <Badge
                                key={variable.variable}
                                colour='brand'
                                size='sm'
                                onClick={() =>
                                  navigator.clipboard.writeText(
                                    variable.variable
                                  )
                                }
                              >
                                {variable.variable}
                              </Badge>
                              <span className='text-sm'>
                                ({variable.value})
                              </span>
                            </div>
                          ))}
                          {variables.length === 0 && (
                            <span className='text-sm'>
                              No variables available
                            </span>
                          )}
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </>
          )}
        </div>
        <h3 className='text-lg font-semibold'>Requests</h3>
        {exitRequests.map((request, index) => (
          <Disclosure key={index}>
            {({ open }) => (
              <>
                <div className='flex items-center mb-2'>
                  <Disclosure.Button
                    className='cursor-pointer text-center mr-2 flex items-center'
                    onClick={() =>
                      handleRequestChange('exit', index, 'open', !open)
                    }
                  >
                    <span className='mr-1'>
                      <ChevronRight className={open ? 'rotate-90' : ''} />
                    </span>
                  </Disclosure.Button>
                  <Input
                    type='text'
                    value={request.name}
                    onChange={e =>
                      handleRequestChange('exit', index, 'name', e.target.value)
                    }
                    placeholder={`Exit Request ${index + 1}`}
                    className='flex-grow'
                  />
                </div>
                <Disclosure.Panel>
                  <div className='mb-4'>
                    <DropdownInput
                      label='Method'
                      value={request.method}
                      onChange={e =>
                        handleRequestChange(
                          'exit',
                          index,
                          'method',
                          e.target.value
                        )
                      }
                      options={[
                        { value: 'GET', label: 'GET' },
                        { value: 'POST', label: 'POST' },
                        { value: 'PATCH', label: 'PATCH' },
                        { value: 'DELETE', label: 'DELETE' },
                        { value: 'PUT', label: 'PUT' }
                      ]}
                    />
                  </div>
                  <div className='mb-4'>
                    <Input
                      label='URL'
                      type='text'
                      value={request.url}
                      onChange={e =>
                        handleRequestChange(
                          'exit',
                          index,
                          'url',
                          e.target.value
                        )
                      }
                      placeholder='https://api.example.com/endpoint'
                    />
                  </div>
                  <div className='mb-4'>
                    <label className='block text-sm font-medium leading-6 text-gray-900 mb-2'>
                      Headers
                    </label>
                    {request.headers.map((header, headerIndex) => (
                      <div key={headerIndex} className='flex gap-2 mb-2'>
                        <Input
                          type='text'
                          value={header.key}
                          onChange={e =>
                            handleHeaderChange(
                              'exit',
                              index,
                              headerIndex,
                              'key',
                              e.target.value
                            )
                          }
                          placeholder='Key'
                        />
                        <Input
                          type='text'
                          value={header.value}
                          onChange={e =>
                            handleHeaderChange(
                              'exit',
                              index,
                              headerIndex,
                              'value',
                              e.target.value
                            )
                          }
                          placeholder='Value'
                          className='mr-2'
                        />
                        <SoftButton
                          colour='primary'
                          onClick={() =>
                            removeHeader('exit', index, headerIndex)
                          }
                        >
                          <X size={16} />
                        </SoftButton>
                      </div>
                    ))}
                    <SoftButton
                      leftIcon={<Plus size={16} />}
                      onClick={() => addHeader('exit', index)}
                      className='mt-2'
                    >
                      Add Header
                    </SoftButton>
                  </div>
                  {request.method !== 'GET' && (
                    <div className='mb-4'>
                      <label className='block text-sm font-medium leading-6 text-gray-900 mb-2'>
                        Body
                      </label>
                      <textarea
                        className='w-full p-2 border border-gray-300 rounded-md'
                        value={request.body}
                        onChange={e =>
                          handleRequestChange(
                            'exit',
                            index,
                            'body',
                            e.target.value
                          )
                        }
                        placeholder='Enter request body...'
                        rows={4}
                      />
                    </div>
                  )}
                  <div className='mb-4'>
                    <div className='flex flex-col'>
                      <div className='flex items-center justify-between mb-2'>
                        <SoftButton
                          onClick={() => handleTestConnection('exit', index)}
                          leftIcon={<RotateCw></RotateCw>}
                          className='mr-2'
                        >
                          Test Request
                        </SoftButton>
                        {exitResponses[index] ? (
                          <div className='flex items-center'>
                            <span className='mr-2 text-sm'>Status:</span>
                            <div className='flex items-center'>
                              {exitResponses[index].status >= 200 &&
                              exitResponses[index].status < 300 ? (
                                <div className='w-4 h-4 rounded-full bg-green-500 mr-2'></div>
                              ) : (
                                <div className='w-4 h-4 rounded-full bg-red-500 mr-2'></div>
                              )}
                              <span className='mr-1'>
                                {exitResponses[index].status}
                              </span>
                              <span>({exitResponses[index].statusText})</span>
                            </div>
                          </div>
                        ) : (
                          <span className='text-sm'>
                            Click to test your request
                          </span>
                        )}
                      </div>
                      {exitResponses[index] && (
                        <Disclosure>
                          {({ open }) => (
                            <>
                              <div className='flex justify-end'>
                                <Disclosure.Button className='cursor-pointer text-sm text-right flex items-center'>
                                  <span className='mr-1'>
                                    <ChevronRight
                                      className={open ? 'rotate-90' : ''}
                                    />
                                  </span>
                                  Response
                                </Disclosure.Button>
                              </div>
                              <Disclosure.Panel>
                                <div className='mt-2'>
                                  <h5 className='text-sm mb-2'>Headers</h5>
                                  <div className='bg-gray-100 p-4 rounded-md overflow-auto max-h-40'>
                                    <pre className='text-sm mb-4'>
                                      {JSON.stringify(
                                        exitResponses[index].headers,
                                        null,
                                        2
                                      )}
                                    </pre>
                                  </div>
                                  <h5 className='text-sm mt-4 mb-2'>Body</h5>
                                  <div className='bg-gray-100 p-4 rounded-md overflow-auto max-h-40'>
                                    <pre className='text-sm'>
                                      {exitResponses[index].headers[
                                        'content-type'
                                      ] === 'application/json'
                                        ? (() => {
                                            try {
                                              return JSON.stringify(
                                                JSON.parse(
                                                  exitResponses[index].body ||
                                                    '{}'
                                                ),
                                                null,
                                                2
                                              );
                                            } catch (error) {
                                              console.error(
                                                'JSON parse error:',
                                                error
                                              );
                                              return exitResponses[index].body;
                                            }
                                          })()
                                        : exitResponses[index].body}
                                    </pre>
                                  </div>
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )}
                    </div>
                  </div>
                  <SoftButton
                    colour='danger'
                    onClick={() => removeRequest('exit', index)}
                    className='mt-2'
                  >
                    Remove Request
                  </SoftButton>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
        <SoftButton
          leftIcon={<Plus size={16} />}
          onClick={() => addRequest('exit')}
          className='mt-4'
        >
          Add Exit Request
        </SoftButton>
      </div>
    </div>
  </div>
);

export default Page2;
